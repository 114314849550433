import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";
import { ListResponse } from "../../../type/ListResponse";
import { EclassSubSubgroup } from "../../../type/EclassSubSubgroup";

export const getEclassSubSubgroups = async (): Promise<
  ListResponse<EclassSubSubgroup>
> => {
  try {
    const response = await apiClient.get<ListResponse<EclassSubSubgroup>>(
      `${ECLASS_URL}/sub-subgroups`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
