import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, IconButton, Rating, Link } from "@mui/material";
import DetailsCard from "../../components/card/DetailsCard";
import DetailsCardItem from "../../components/card/DetailsCardItem";
import {
  Calculate,
  FileDownload,
  FileUpload,
  ImportExport,
  Info,
  StarBorder,
} from "@mui/icons-material";
import DiamondRating from "../../components/rating/DiamondRating";
import { SupplierDetails } from "../../shared/type/SupplierDetails";
import { mapClassificationEnumToNumber } from "../../shared/helper/map-classification-enum-to-number";
import {
  importRateCardPath,
  supplierRateCardPath,
  supplierRatingPath,
} from "../../shared/const/path.const";
import RateTextBox from "../../components/text-box/RateTextBox";
import {
  BASE_URL,
  EXPORT_RATE_URL,
  SUPPLIER_LOGO_URL,
} from "../../shared/service/api/api.const";
import { ConfirmDialog } from "../../components/dialog/ConfirmDialog";
import EditableImageCard from "../../components/card/EditableImageCard";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SupplierBusinessContacts } from "./SupplierBusinessContacts";
import EditableDetailsCard from "../../components/card/EditableDetailsCard";
import { SupplierAddresses } from "./SupplierAddresses";
import { UpdateSupplierDialog } from "./UpdateSupplierDialog";
import { UpdateSupplierPayload } from "../../shared/service/api/supplier/update-supplier";
import { RateCardSummary } from "../../shared/type/RateCardSummary";
import { capitalizeFirstLetter } from "../../shared/helper/capitalize-first-letter";
import ApiService from "../../shared/service/api/api.service";
import { handleError } from "../../shared/helper/handle-error";
import BenchmarkDataVisibleContext from "../../components/benchmark-switch/BenchmarkDataVisibleContext";
import { SupplierNotes } from "./SupplierNotes";

type SupplierDetailsProps = {
  supplier: SupplierDetails;
  rateCardSummary?: RateCardSummary;
  updateSupplier?: (payload: UpdateSupplierPayload) => void;
  deleteSupplier?: (supplierId: string) => void;
  deleteSupplierAddress: (supplierId: string, addressId: string) => void;
  deleteSupplierBusinessContact: (
    supplierId: string,
    businessContactId: string
  ) => void;
  deleteSupplierNote: (supplierId: string, noteId: string) => void;
  uploadSupplierLogo: (supplierId: string, file: File) => void;
  deleteSupplierLogo: (supplierId: string) => void;
  setSupplierAsABenchmark: (supplierId: string) => void;
  fetchData: () => void;
};

const SupplierDetailsComponent = ({
  supplier,
  rateCardSummary,
  updateSupplier,
  deleteSupplier,
  deleteSupplierAddress,
  deleteSupplierBusinessContact,
  deleteSupplierNote,
  uploadSupplierLogo,
  deleteSupplierLogo,
  setSupplierAsABenchmark,
  fetchData,
}: SupplierDetailsProps) => {
  const navigate = useNavigate();

  const logoUrl = `${BASE_URL}${SUPPLIER_LOGO_URL(supplier.id)}`;

  const [benchmarkSupplier, setBenchmarkSupplier] = useState<string>();
  const [isBenchmarkSupplier, setIsBenchmarkSupplier] =
    useState<boolean>(false);
  const { benchmarkDataVisible } = useContext(BenchmarkDataVisibleContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const benchmark =
          await ApiService.supplier.benchmark.getBenchmarkSupplier();

        if (benchmark) {
          setBenchmarkSupplier(benchmark.supplierId);
          setIsBenchmarkSupplier(benchmark.supplierId === supplier.id);
        }
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [supplier.id]);

  const [isSetAsABenchmarkDialogOpen, setSetAsABenchmarkDialogOpen] =
    useState(false);

  const handleSetSupplierAsABenchmark = () => {
    setSetAsABenchmarkDialogOpen(true);
  };

  const handleConfirmSetAsABenchmark = (supplierId?: string) => {
    if (setSupplierAsABenchmark && supplierId) {
      setSupplierAsABenchmark(supplierId);
    }
    setSetAsABenchmarkDialogOpen(false);
  };

  const handleCancelSetAsABenchmark = () => {
    setSetAsABenchmarkDialogOpen(false);
  };

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleSupplierDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = (supplierId?: string) => {
    if (deleteSupplier && supplierId) {
      deleteSupplier(supplierId);
    }
    setDeleteDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);

  const handleSupplierUpdate = () => {
    setUpdateDialogOpen(true);
  };

  const handleConfirmUpdate = (payload: UpdateSupplierPayload) => {
    if (updateSupplier) {
      updateSupplier(payload);
    }
    setUpdateDialogOpen(false);
  };

  const handleCancelUpdate = () => {
    setUpdateDialogOpen(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} key={1}>
              <EditableDetailsCard
                id={supplier.id}
                cardTitle={supplier.companyName}
                titleSize="h3"
                onClickEdit={updateSupplier ? handleSupplierUpdate : undefined}
                onClickDelete={
                  deleteSupplier ? handleSupplierDelete : undefined
                }
              >
                <DetailsCardItem cardItemTitle="Website">
                  <Link
                    href={supplier.websiteUrl}
                    sx={{ color: "inherit", textDecoration: "none" }}
                  >
                    {supplier.websiteUrl}
                  </Link>
                </DetailsCardItem>
                <DetailsCardItem cardItemTitle="VAT">
                  {supplier.vatNumber}
                </DetailsCardItem>
                <DetailsCardItem cardItemTitle="Steuer Nummer">
                  {supplier.vatNumber}
                </DetailsCardItem>
                <DetailsCardItem cardItemTitle="SAP Nummer">
                  {supplier.sapNumber}
                </DetailsCardItem>
                {/*<SupplierDetailsCardItem cardItemTitle="Leistungen">*/}
                {/*  {supplier.services.join(",")}*/}
                {/*</SupplierDetailsCardItem>*/}
                <DetailsCardItem
                  cardItemTitle="Bemerkungen"
                  showDivider={false}
                >
                  {supplier.note}
                </DetailsCardItem>
                {supplier.updatedBy && (
                  <DetailsCardItem
                    cardItemTitle="Letzte Änderung"
                    showDivider={false}
                  >
                    {`${new Date(supplier.updatedAt).toDateString()} durch ${
                      supplier.updatedBy.firstName
                    } 
                    ${supplier.updatedBy.lastName}`}
                  </DetailsCardItem>
                )}
              </EditableDetailsCard>
            </Grid>
            <Grid item xs={12} key={2}>
              <SupplierAddresses
                addresses={supplier.addresses}
                supplierId={supplier.id}
                deleteSupplierAddress={deleteSupplierAddress}
                fetchData={fetchData}
              />
            </Grid>
            <Grid item xs={12} key={3}>
              <SupplierBusinessContacts
                contacts={supplier.businessContacts}
                supplierId={supplier.id}
                deleteSupplierBusinessContact={deleteSupplierBusinessContact}
                fetchData={fetchData}
              />
            </Grid>
            <Grid item xs={12} key={3}>
              <SupplierNotes
                notes={supplier.notes}
                supplierId={supplier.id}
                deleteSupplierNote={deleteSupplierNote}
                fetchData={fetchData}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} key={4}>
              <EditableImageCard
                id={supplier.id}
                src={logoUrl}
                alt={supplier.companyName}
                deleteImage={deleteSupplierLogo}
                uploadImage={uploadSupplierLogo}
              />
            </Grid>
            <Grid item xs={12} key={5}>
              <DetailsCard cardTitle="Info" cardIcon={<Info />}>
                <DetailsCardItem
                  titleWidth={4}
                  cardItemTitle="Lieferantenstatus"
                >
                  {[
                    ...new Set(
                      supplier.supplierStatusList.map((s) => s.statusName)
                    ),
                  ].join(", ")}
                </DetailsCardItem>
                <DetailsCardItem titleWidth={4} cardItemTitle="Erbringung">
                  {supplier.deliveryModel}
                </DetailsCardItem>
                <DetailsCardItem titleWidth={4} cardItemTitle="Klassifikation">
                  <DiamondRating
                    classificationValue={mapClassificationEnumToNumber(
                      supplier.classification
                    )}
                  ></DiamondRating>
                </DetailsCardItem>
                <DetailsCardItem
                  titleWidth={4}
                  cardItemTitle="Rating"
                  showDivider={false}
                >
                  <RouterLink
                    className="link-reset-styles"
                    to={supplierRatingPath(supplier.id)}
                  >
                    <Rating value={supplier.rating} readOnly />
                  </RouterLink>
                </DetailsCardItem>
              </DetailsCard>
            </Grid>
            <Grid item xs={12} key={6}>
              <DetailsCard
                cardTitle="Ratecards"
                cardTitleUrl={supplierRateCardPath(supplier.id)}
                cardIcon={<StarBorder />}
              >
                <DetailsCardItem titleWidth={4} cardItemTitle="Tagessatz">
                  <RateTextBox
                    value={rateCardSummary?.supplierAvg ?? undefined}
                  ></RateTextBox>
                </DetailsCardItem>
                <DetailsCardItem
                  titleWidth={4}
                  cardItemTitle="Helaba Tagessatz"
                >
                  <RateTextBox
                    value={rateCardSummary?.globalAvg ?? undefined}
                  ></RateTextBox>
                </DetailsCardItem>
                {benchmarkDataVisible && (
                  <DetailsCardItem titleWidth={4} cardItemTitle="Benchmark">
                    <RateTextBox
                      value={rateCardSummary?.benchmarkAvg ?? undefined}
                    ></RateTextBox>
                  </DetailsCardItem>
                )}
                <DetailsCardItem titleWidth={4} cardItemTitle="Standort">
                  <div>
                    {rateCardSummary?.locationCategories
                      .map((category) => capitalizeFirstLetter(category))
                      .join(", ")}
                  </div>
                </DetailsCardItem>
                <DetailsCardItem
                  titleWidth={4}
                  cardItemTitle="Anzahl Ratecards"
                  showDivider={false}
                >
                  <div>{rateCardSummary?.rateCardCount}</div>
                </DetailsCardItem>
              </DetailsCard>
            </Grid>
            {/* <Grid item xs={12} key={7}>
              <DetailsCard cardTitle="Rahmenvertag" cardIcon={<NoteAlt />}>
                <DetailsCardItem titleWidth={4} cardItemTitle="Datum">
                  01.01.2022
                </DetailsCardItem>
                <DetailsCardItem titleWidth={4} cardItemTitle="Vertrag">
                  <PictureAsPdf></PictureAsPdf>
                </DetailsCardItem>
                <DetailsCardItem titleWidth={4} cardItemTitle="Zusammenfassung">
                  Rabatt Staffel bis 5%, Only onsite
                </DetailsCardItem>
                <DetailsCardItem titleWidth={4} cardItemTitle="Historie">
                  112
                </DetailsCardItem>
              </DetailsCard>
            </Grid> */}
            <Grid item xs={12} key={8}>
              <DetailsCard
                cardTitle="Import / Export"
                cardIcon={<ImportExport />}
              >
                <DetailsCardItem
                  titleWidth={4}
                  cardItemTitle="Export Ratecards"
                >
                  <IconButton size="small" href={EXPORT_RATE_URL(supplier.id)}>
                    <FileUpload />
                  </IconButton>
                </DetailsCardItem>
                <DetailsCardItem
                  titleWidth={4}
                  cardItemTitle="Import Ratecards"
                  showDivider={false}
                >
                  <IconButton
                    size="small"
                    onClick={() => navigate(importRateCardPath(supplier.id))}
                  >
                    <FileDownload />
                  </IconButton>
                </DetailsCardItem>
              </DetailsCard>
            </Grid>
            <Grid item xs={12} key={8}>
              <DetailsCard cardTitle="Benchmark" cardIcon={<Calculate />}>
                {isBenchmarkSupplier ? (
                  "Dieser Lieferant gilt als Maßstab. Wählen Sie einen anderen Lieferanten zum Ändern aus."
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleSetSupplierAsABenchmark}
                    size="large"
                  >
                    Als Benchmarker festlegen
                  </Button>
                )}
              </DetailsCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isSetAsABenchmarkDialogOpen && (
        <ConfirmDialog
          isOpen={isSetAsABenchmarkDialogOpen}
          onClose={handleCancelSetAsABenchmark}
          onAction={handleConfirmSetAsABenchmark}
          title="Als Benchmarker festlegen"
          content="Sind Sie sicher, dass Sie diesen Lieferanten als Benchmark festlegen möchten?"
          buttonText="Einstellen"
          id={supplier.id}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleCancelDelete}
          onAction={handleConfirmDelete}
          title="Element löschen"
          content="Sind Sie sicher, dass Sie löschen möchten?"
          buttonText="Löschen"
          id={supplier.id}
        />
      )}
      {isUpdateDialogOpen && (
        <UpdateSupplierDialog
          isOpen={isUpdateDialogOpen}
          onClose={handleCancelUpdate}
          onUpdate={handleConfirmUpdate}
          initialData={supplier}
        />
      )}
    </>
  );
};

export default SupplierDetailsComponent;
