import { Box, Button, Typography } from "@mui/material";
import ForgotPasswordLink from "../../../feature/auth/ForgotPasswordLink";
import AuthContainer from "../../../components/container/AuthContainer";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { EmailInput } from "../../../feature/inputs/EmailInput";
import { PasswordInput } from "../../../feature/inputs/PasswordInput";
import { SUPPLIER_PATH } from "../../../shared/const/path.const";

function LoginPage() {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      const response = await ApiService.loginUser(data);
      if (response) {
        AlertService.success("Successfully sign in!");
        navigate(SUPPLIER_PATH);
      } else {
        AlertService.error("Wrong credentials");
      }
    } catch (error: any) {
      if (typeof error === "string") {
        AlertService.error(error);
      }
    }
  };

  return (
    <AuthContainer onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h1" variant="h6" fontWeight={700} marginBottom={2}>
        Einloggen
      </Typography>

      <EmailInput
        required
        control={control}
        errors={errors}
        autoFocus={true}
        props={{
          variant: "filled",
        }}
      />
      <PasswordInput
        control={control}
        errors={errors}
        props={{
          variant: "filled",
          autofocus: true,
        }}
      />
      <Box>
        <ForgotPasswordLink />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          onClick={() => navigate("/register")}
          variant="outlined"
          size="large"
          sx={{ mt: 3, mb: 2 }}
        >
          Neu registrieren
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          disableElevation
          disabled={isSubmitting}
          sx={{ mt: 3, mb: 2 }}
        >
          Einloggen
        </Button>
      </Box>
    </AuthContainer>
  );
}

export default LoginPage;
