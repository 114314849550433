import { PropsWithChildren, useState } from "react";
import BenchmarkDataVisibleContext from "./BenchmarkDataVisibleContext";

export const BenchmarkDataVisibleProvider = ({
  children,
}: PropsWithChildren<any>) => {
  const [benchmarkDataVisible, setBenchmarkDataVisible] = useState(true);
  const value = { benchmarkDataVisible, setBenchmarkDataVisible };

  return (
    <BenchmarkDataVisibleContext.Provider value={value}>
      {children}
    </BenchmarkDataVisibleContext.Provider>
  );
};
