import { BENCHMARK_URL } from "../api.const";
import apiClient from "../api.client";

export const getBenchmarkSupplier = async (): Promise<{
  supplierId: string;
}> => {
  try {
    const response = await apiClient.get<{ supplierId: string }>(BENCHMARK_URL);
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
