import apiClient from "../api.client";
import { SET_SUPPLIER_AS_A_BENCHMARK } from "../api.const";

export const setSupplierAsABenchmark = async (
  supplierId: string
): Promise<void> => {
  try {
    await apiClient.post(SET_SUPPLIER_AS_A_BENCHMARK(supplierId));
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
