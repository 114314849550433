import React, { PropsWithChildren, useEffect, useState } from "react";
import { Box, Container, CssBaseline } from "@mui/material";

import BrandHeader from "../../components/headers/BrandHeader";
import Footer from "../../components/footer/Footer";
import NavHeader from "../../components/headers/NavHeader";
import SearchHeader from "../../components/headers/SearchHeader";
import FunctionalHeader from "../headers/FunctionalHeader";
import { createSearchParams, useNavigate } from "react-router-dom";

type functionHeaderType = "search-bar" | "none";

interface HomeContainerProps {
  functionHeaderType?: functionHeaderType;
}

function HomeContainer({
  children,
  functionHeaderType,
}: PropsWithChildren<HomeContainerProps>) {
  let [searchText, setSearchText] = useState<string>("");

  const navigate = useNavigate();
  const handleSearchTextChange = (text: string) => {
    setSearchText(text);
  };

  useEffect(() => {
    if (searchText.length >= 3) {
      navigate({
        pathname: "/supplier-search",
        search: `?${createSearchParams({ query: searchText })}`,
      });
    }
  }, [searchText]);

  let functionalHeader;
  switch (functionHeaderType) {
    case "search-bar":
      functionalHeader = (
        <SearchHeader onSearchTextChange={handleSearchTextChange} />
      );
      break;
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <BrandHeader />
        <NavHeader />
        {functionalHeader}
        <Container sx={{ py: 5 }} maxWidth="xl">
          {children}
        </Container>
        <Footer />
      </Box>
    </React.Fragment>
  );
}

export default HomeContainer;
