import DetailsCardItem from "../../components/card/DetailsCardItem";
import { Address } from "../../shared/type/Address";

type SupplierAddressProps = {
  address: Address;
};

export function SupplierAddress({ address }: SupplierAddressProps) {
  return (
    <div>
      <DetailsCardItem cardItemTitle="Standort">{address.city}</DetailsCardItem>
      <DetailsCardItem cardItemTitle="Anschrift">
        {address.line1}
      </DetailsCardItem>
      <DetailsCardItem cardItemTitle="Anschrift 2">
        {address.line2}
      </DetailsCardItem>
      <DetailsCardItem cardItemTitle="PLZ Ort">
        {address.zipCode}
      </DetailsCardItem>
      <DetailsCardItem cardItemTitle="Land" showDivider={false}>
        {address.country}
      </DetailsCardItem>
    </div>
  );
}
