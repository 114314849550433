import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { UpdateUserPayload } from "../../shared/service/api/user/update-user";
import { useForm } from "react-hook-form";
import { EmailInput } from "../inputs/EmailInput";
import { TextInput } from "../inputs/TextInput";

type UpdateFieldDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (payload: UpdateUserPayload) => void;
  rowData: UpdateUserPayload;
};
export function UpdateUserDialog({
  isOpen,
  onClose,
  onUpdate,
  rowData,
}: UpdateFieldDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<UpdateUserPayload>();

  useEffect(() => {
    if (rowData) {
      setValue("firstName", rowData.firstName);
      setValue("lastName", rowData.lastName);
      setValue("email", rowData.email);
    }
  }, [rowData, setValue]);

  const onSubmit = (data: UpdateUserPayload) => {
    if (rowData.userId) {
      onUpdate({ ...data, userId: rowData.userId });
    }
  };

  const handleCancelUpdate = () => {
    reset();
    onClose();
  };
  return (
    <Dialog open={isOpen} onClose={handleCancelUpdate}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Benutzer aktualisieren</DialogTitle>
        <DialogContent>
          <EmailInput control={control} errors={errors} required={false} />
          <TextInput
            control={control}
            errors={errors}
            fieldName="firstName"
            label="Vorname"
            required={false}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="lastName"
            label="Nachname"
            required={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpdate} size="large">
            Abbrechen
          </Button>
          <Button type="submit" size="large">
            Update
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
