import apiClient from "../api.client";
import { SUPPLIER_STATUS_URL } from "../api.const";

export interface UpdateSupplierStatusPayload {
  statusName: string;
}

export const updateSupplierStatus = async (
  supplierStatusId: string,
  updateSupplierStatusPayload: UpdateSupplierStatusPayload
) => {
  try {
    await apiClient.put(
      `${SUPPLIER_STATUS_URL}/${supplierStatusId}`,
      updateSupplierStatusPayload
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
