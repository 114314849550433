import { CardContent, Divider, Grid, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import CardRowTitleTextBox from "../text-box/CardRowTitleTextBox";
interface DetailsCardItemProps {
  cardItemTitle: string;
  titleWidth?: number;
  showDivider?: boolean;
}

const MAX_XS = 12;
const DEFAULT_TITLE_XS = 2;

function DetailsCardItem({
  cardItemTitle,
  titleWidth,
  showDivider = true,
  children,
}: PropsWithChildren<DetailsCardItemProps>) {
  const titleXs = titleWidth || DEFAULT_TITLE_XS;
  const itemXs = MAX_XS - titleXs;
  return (
    <>
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={titleXs}>
            <CardRowTitleTextBox text={cardItemTitle}></CardRowTitleTextBox>
          </Grid>
          <Grid item xs={itemXs}>
            <Typography variant="body2">{children}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      {showDivider && <Divider variant="middle" />}
    </>
  );
}

export default DetailsCardItem;
