import apiClient from "../api.client";
import { USER_URL } from "../api.const";

export const deleteUser = async (userId: string): Promise<void> => {
  try {
    await apiClient.delete<void>(`${USER_URL}/${userId}`);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
