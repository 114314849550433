import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";

export interface CreateEclassMainGroupPayload {
  name: string;
}

export const createEclassMainGroup = async (
  payload: CreateEclassMainGroupPayload
): Promise<void> => {
  try {
    await apiClient.post(`${ECLASS_URL}/main-groups`, payload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
