import apiClient from "../api.client";
import { USER_URL } from "../api.const";

export interface CreateUserPayload {
  email: string;
  firstName: string;
  lastName: string;
}
export const createUser = async (payload: CreateUserPayload): Promise<void> => {
  try {
    await apiClient.post<void>(USER_URL, payload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
