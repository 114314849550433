import { SUPPLIER_BUSINESS_CONTACT_URL } from "../api.const";
import apiClient from "../api.client";
import { BusinessContact } from "../../../type/BusinessContact";

export const createSupplierBusinessContact = async (
  supplierId: string,
  createSupplierBusinessContactPayload: Omit<BusinessContact, "id">
): Promise<void> => {
  try {
    await apiClient.post(
      SUPPLIER_BUSINESS_CONTACT_URL(supplierId),
      createSupplierBusinessContactPayload
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
