import { TextField } from "@mui/material";
import { Controller, Control, FieldErrors } from "react-hook-form";

type EmailInputProps = {
  control: Control<any, any>;
  errors: FieldErrors;
  required?: boolean;
  props?: any;
  autoFocus?: boolean;
};

export const EmailInput: React.FC<EmailInputProps> = ({
  control,
  errors,
  required,
  props,
  autoFocus,
}) => {
  const fieldName = "email";
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: required ? "Email is required" : false,
        pattern: { value: /^\S+@\S+$/i, message: "Invalid email format" },
      }}
      defaultValue={null}
      render={({ field }) => (
        <TextField
          {...field}
          label="E-Mail"
          type="email"
          error={!!errors[fieldName]}
          helperText={errors[fieldName]?.message?.toString() || ""}
          fullWidth
          margin="dense"
          autoFocus={autoFocus}
          {...props}
        />
      )}
    />
  );
};
