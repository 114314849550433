import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_Virtualizer,
} from "material-react-table";
import { MenuItem, Rating } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ConfirmDialog } from "../../components/dialog/ConfirmDialog";
import { RatingResponse } from "../../shared/type/RatingResponse";

const ratingColumns: MRT_ColumnDef<RatingResponse>[] = [
  {
    accessorKey: "quality",
    header: "Qualität",
    size: 150,
    Cell: ({ renderedCellValue }) => (
      <Rating value={renderedCellValue as number} readOnly />
    ),
  },
  {
    accessorKey: "time",
    header: "Time",
    size: 150,
    Cell: ({ renderedCellValue }) => (
      <Rating value={renderedCellValue as number} readOnly />
    ),
  },
  {
    accessorKey: "budget",
    header: "Budget",
    size: 150,
    Cell: ({ renderedCellValue }) => (
      <Rating value={renderedCellValue as number} readOnly />
    ),
  },
  {
    accessorKey: "note",
    header: "Notiz",
    size: 150,
  },
  {
    accessorKey: "issuer.firstName",
    header: "Vorname",
    size: 150,
  },
  {
    accessorKey: "issuer.lastName",
    header: "Nachname",
    size: 150,
  },
  {
    accessorKey: "createdAt",
    header: "Erstellt",
    size: 130,
    Cell: ({ renderedCellValue }) => (
      <div>{new Date(renderedCellValue as string).toDateString()}</div>
    ),
  },
];

type RatingTableProps = {
  ratingList: RatingResponse[];
  deleteRating: (ratingId: string) => void;
};

const RatingTable = ({ ratingList, deleteRating }: RatingTableProps) => {
  const columns = useMemo<MRT_ColumnDef<RatingResponse>[]>(
    () => ratingColumns,
    []
  );

  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [data, setData] = useState<RatingResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [selectedRating, setSelectedRating] = useState<RatingResponse>();

  useEffect(() => {
    setData(ratingList);
    setIsLoading(false);
  }, [ratingList]);

  useEffect(() => {
    //scroll to the top of the supplier-table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  // // DELETE DIALOG
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = (RatingResponse: RatingResponse) => {
    setSelectedRating(RatingResponse);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedRating?.id) {
      deleteRating(selectedRating.id);
    }
    setDeleteDialogOpen(false);
    setSelectedRating(undefined);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedRating(undefined);
  };

  return (
    <div className="customReactMaterialTable">
      <MaterialReactTable
        columns={columns}
        data={data}
        defaultDisplayColumn={{ enableResizing: true }}
        enableBottomToolbar={false}
        enableColumnVirtualization
        enableGlobalFilterModes
        enablePagination={false}
        enableHiding={false}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
        onSortingChange={setSorting}
        state={{ isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        rowVirtualizerProps={{ overscan: 5 }}
        columnVirtualizerProps={{ overscan: 2 }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={1}
            onClick={() => {
              handleDelete(row.original);
            }}
            sx={{ m: 0 }}
          >
            <Delete></Delete>
            Delete
          </MenuItem>,
        ]}
      />
      {selectedRating?.id && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleCancelDelete}
          onAction={handleConfirmDelete}
          title="Delete element"
          content="Are you sure you want to delete?"
          buttonText="Delete"
          id={selectedRating.id}
        />
      )}
    </div>
  );
};

export default RatingTable;
