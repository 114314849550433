import apiClient from "../api.client";
import { RATE_CARD_URL } from "../api.const";
import { CurrencyEnum } from "../../../enum/CurrencyEnum";
import { LocationCategoryEnum } from "../../../enum/LocationCategoryEnum";

export interface UpdateRateCardPayload {
  currency: CurrencyEnum;
  dueDate?: Date;
  startDate: Date;
  locationCategory: LocationCategoryEnum;
  location: string;
  discount?: boolean;
  travelCost?: string | null;
  supplierStatusId?: string;
}

export const updateRateCard = async (
  supplierId: string,
  rateCardId: string,
  updateRateCardPayload: UpdateRateCardPayload
) => {
  try {
    await apiClient.put(
      `${RATE_CARD_URL(supplierId)}/${rateCardId}`,
      updateRateCardPayload
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
