import apiClient from "../api.client";
import { USER_URL } from "../api.const";
import { ListResponse } from "../../../type/ListResponse";
import { UserDetails } from "../../../type/UserDetails";

export const getUsers = async (): Promise<ListResponse<UserDetails>> => {
  try {
    const response = await apiClient.get<ListResponse<UserDetails>>(
      `${USER_URL}`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
