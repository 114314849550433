import { ChevronRight } from "@mui/icons-material";
import { CardContent, Typography, Box, Card, Button } from "@mui/material";
import { Link } from "react-router-dom";

const Tile = ({
  icon,
  title,
  action,
  totalAmount,
  lastChange,
  redirectTo,
}: any) => {
  const IconComponent = icon;

  return (
    <Card>
      <CardContent>
        <Typography component="h1" variant="h5">
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5em" }}
          >
            <IconComponent />
            {title}
          </Box>
        </Typography>
        <Box display="flex" justifyContent="space-between" marginTop={1}>
          <Box display="flex" alignItems="center">
            {totalAmount && (
              <Typography variant="body1">
                Anzahl der Elemente: {totalAmount}
              </Typography>
            )}
            {lastChange && (
              <Typography variant="body1" sx={{ marginLeft: 1 }}>
                Letzter Login: {lastChange}
              </Typography>
            )}
          </Box>
          <Button
            component={Link}
            variant="outlined"
            to={redirectTo}
            endIcon={<ChevronRight />}
          >
            {action}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Tile;
