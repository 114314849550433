import apiClient from "../api.client";
import { USER_URL } from "../api.const";

export interface GenerateUserAuthTokenResponse {
  authToken: string;
}
export const generateUserAuthToken = async (
  userId: string
): Promise<GenerateUserAuthTokenResponse> => {
  try {
    const response = await apiClient.post<GenerateUserAuthTokenResponse>(
      `${USER_URL}/${userId}/generate-reset-password-link`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
