import { Link } from "@mui/material";
import React from "react";

const ForgotPasswordLink: React.FC = () => {
  return (
    <Link
      href="/forgot-password"
      color="inherit"
      underline="none"
      variant="body2"
    >
      Passwort vergessen?
    </Link>
  );
};

export default ForgotPasswordLink;
