import { toast } from "react-toastify";
import { getTranslation } from "../translation/get-translation";

export const info = (
  message: string,
  options?: { closeOnClick: boolean; autoClose: number; draggable: boolean }
): void => {
  toast.info(getTranslation(message), {
    position: "bottom-right",
    autoClose: options?.autoClose ?? 3000,
    hideProgressBar: true,
    closeOnClick: options?.closeOnClick !== false,
    pauseOnHover: true,
    draggable: options?.draggable !== false,
  });
};
