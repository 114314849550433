import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";
import React, { PropsWithChildren, useState } from "react";
import { Link } from "react-router-dom";
import { Variant } from "@mui/material/styles/createTypography";
import { ConfirmDialog } from "../dialog/ConfirmDialog";
interface EditableDetailsCardProps {
  id: string;
  cardTitle: string;
  cardIcon?: React.ReactNode;
  cardTitleUrl?: string;
  onClickCreate?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: (id: string) => void;
  titleSize?: Variant;
}
function EditableDetailsCard({
  id,
  cardTitle,
  cardIcon,
  cardTitleUrl,
  onClickCreate,
  onClickEdit,
  onClickDelete,
  children,
  titleSize,
}: PropsWithChildren<EditableDetailsCardProps>) {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = (id?: string) => {
    if (onClickDelete && id) {
      onClickDelete(id);
    }
    setDeleteDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const titleValue = cardTitleUrl ? (
    <Link className="link-reset-styles" to={cardTitleUrl}>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5em" }}>
        {cardIcon}
        {cardTitle}
      </Box>
    </Link>
  ) : (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5em" }}>
      {cardIcon}
      {cardTitle}
    </Box>
  );

  const createButton = onClickCreate ? (
    <IconButton size="small" onClick={onClickCreate}>
      <Add />
    </IconButton>
  ) : null;

  const editButton = onClickEdit ? (
    <IconButton size="small" onClick={onClickEdit}>
      <Edit />
    </IconButton>
  ) : null;

  const deleteButton = onClickDelete ? (
    <IconButton size="small" onClick={handleDelete}>
      <Delete />
    </IconButton>
  ) : null;

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant={titleSize ?? "h5"} component="div">
              {titleValue}
            </Typography>
          }
          action={
            <Box sx={{ marginLeft: "auto" }}>
              <CardActions style={{ alignSelf: "flex-start" }}>
                {createButton}
                {editButton}
                {deleteButton}
              </CardActions>
            </Box>
          }
        ></CardHeader>

        <CardContent>
          <Typography variant="body1">{children}</Typography>
        </CardContent>
      </Card>
      {isDeleteDialogOpen && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleCancelDelete}
          onAction={handleConfirmDelete}
          title="Delete element"
          content="Are you sure you want to delete?"
          buttonText="Delete"
          id={id}
        />
      )}
    </>
  );
}

export default EditableDetailsCard;
