import { SUPPLIER_URL } from "../api.const";
import apiClient from "../api.client";
import { SupplierTypeEnum } from "../../../enum/SupplierTypeEnum";
import { ClassificationEnum } from "../../../enum/ClassificationEnum";
import { DeliveryModelEnum } from "../../../enum/DeliveryModelEnum";
import { Address } from "../../../type/Address";
import { BusinessContact } from "../../../type/BusinessContact";

export interface CreateSupplierPayload {
  companyName: string;
  websiteUrl?: string;
  vatNumber?: string;
  taxNumber?: string;
  sapNumber?: string;
  type: SupplierTypeEnum;
  classification: ClassificationEnum;
  deliveryModel: DeliveryModelEnum;
  note?: string;
  addresses: Omit<Address, "id">[];
  businessContacts: Omit<BusinessContact, "id">[];
}

export const createSupplier = async (
  createSupplierPayload: CreateSupplierPayload
): Promise<void> => {
  try {
    await apiClient.post(SUPPLIER_URL, createSupplierPayload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
