import { Contacts } from "@mui/icons-material";
import { useState } from "react";
import { BusinessContact } from "../../shared/type/BusinessContact";
import { SupplierBusinessContact } from "./SupplierBusinessContact";
import EditableDetailsCardWithPagination from "../../components/card/EditableDetailsCardWithPagination";
import { CreateSupplierBusinessContactDialog } from "./CreateSupplierBusinessContactDialog";
import { handleError } from "../../shared/helper/handle-error";
import AlertService from "../../shared/service/alert/alert.service";
import ApiService from "../../shared/service/api/api.service";

type SupplierBusinessContactProps = {
  contacts: BusinessContact[];
  supplierId: string;
  deleteSupplierBusinessContact: (
    supplierId: string,
    businessContactId: string
  ) => void;
  fetchData: () => void;
};

export function SupplierBusinessContacts({
  contacts,
  supplierId,
  deleteSupplierBusinessContact,
  fetchData,
}: SupplierBusinessContactProps) {
  // FIXME: can it be improved to avoid extra renders?
  const [currentBusinessContactId, setCurrentBusinessContactId] =
    useState<string>("");
  const [showLatest, setShowLatest] = useState(false);

  const handleDelete = (id: string) => {
    deleteSupplierBusinessContact(supplierId, id);
  };

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  const handleConfirmCreate = async (payload: Omit<BusinessContact, "id">) => {
    try {
      await ApiService.supplier.createSupplierBusinessContact(
        supplierId,
        payload
      );
      AlertService.success("Supplier business contact created successfully!");
      await fetchData();
      setCreateDialogOpen(false);
      setShowLatest(true);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancelCreate = () => {
    setCreateDialogOpen(false);
  };

  return (
    <>
      <EditableDetailsCardWithPagination
        cardTitle="Ansprechpartner"
        cardIcon={<Contacts />}
        onClickCreate={handleCreate}
        onClickDelete={handleDelete}
        rowId={currentBusinessContactId}
        numPages={contacts.length}
        renderRow={(page: number) =>
          contacts.slice(page - 1, page).map((contact) => {
            setCurrentBusinessContactId(contact.id);
            return <SupplierBusinessContact contact={contact} />;
          })
        }
        showLatest={showLatest}
      />
      {isCreateDialogOpen && (
        <CreateSupplierBusinessContactDialog
          isOpen={isCreateDialogOpen}
          onClose={handleCancelCreate}
          onCreate={handleConfirmCreate}
        />
      )}
    </>
  );
}
