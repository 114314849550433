import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./pages/auth/login/LoginPage";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPasswordPage";
import RegisterPage from "./pages/auth/register/RegisterPage";
import ResetPasswordPage from "./pages/auth/reset-password/ResetPasswordPage";
import { ToastContainer } from "react-toastify";
import AdminPage from "./pages/admin/admin/AdminPage";
import SupplierPage from "./pages/supplier/SupplierPage";
import { createTheme, ThemeProvider } from "@mui/material";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import SupplierDetailsPage from "./pages/supplier/SupplierDetailsPage";
import UserManagement from "./pages/admin/user-management/UserManagementPage";
import TagManagementPage from "./pages/admin/tag-management/TagManagementPage";
import SupplierSearchPage from "./pages/home/SupplierSearchPage";
import EclassCreationPage from "./pages/admin/eclass-managment/EclassCreationPage";
import EclassManagementPage from "./pages/admin/eclass-managment/EclassManagementPage";
import SupplierCreationPage from "./pages/supplier/SupplierCreationPage";
import {
  ADD_RATE_CARD_PATH,
  ADMIN_ECLASS_CREATE_PATH,
  ADMIN_ECLASS_PATH,
  ADMIN_PATH,
  ADMIN_SUPPLIER_STATUS_PATH,
  ADMIN_TAG_PATH,
  ADMIN_USER_PATH,
  EDIT_RATE_CARD_PATH,
  FORGOT_PASSWORD_PATH,
  IMPORT_RATE_CARD_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  RESET_PASSWORD_PATH,
  SINGLE_SUPPLIER_PATH,
  SUPPLIER_CREATE_PATH,
  SUPPLIER_PATH,
  SUPPLIER_RATE_CARD_PATH,
  SUPPLIER_RATING_PATH,
  SUPPLIER_SEARCH_PATH,
} from "./shared/const/path.const";
import RateCardManagementPage from "./pages/admin/rate-card-managment/RateCardManagementPage";
import RateCardImportPage from "./pages/admin/rate-card-managment/RateCardImportPage";
import RateCardCreationPage from "./pages/admin/rate-card-managment/RateCardCreationPage";
import AxiosInterceptorNavigate from "./shared/interceptor/AxiosInterceptorNavigate";
import SupplierStatusManagementPage from "./pages/admin/supplier-status-management/SupplierStatusManagementPage";
import RateCardEditingPage from "./pages/admin/rate-card-managment/RateCardEditingPage";
import RatingManagementPage from "./pages/rating/RatingManagementPage";
import { BenchmarkDataVisibleProvider } from "./components/benchmark-switch/BenchmarkDataVisibleProvider";

declare module "@mui/material/styles" {
  interface Palette {
    rateCard: Palette["primary"];
    helabaLight: Palette["primary"];
  }

  interface PaletteOptions {
    rateCard: PaletteOptions["primary"];
    helabaLight: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    helabaLight: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#006fb0",
      dark: "#00456d",
      light: "#009ee3",
    },
    rateCard: {
      main: "#00c65b",
      dark: "#ff6060",
    },
    helabaLight: {
      main: "#009ee3",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <BrowserRouter>
        <AxiosInterceptorNavigate />
        <BenchmarkDataVisibleProvider>
          <Routes>
            {/* Auth routes */}
            <Route path={LOGIN_PATH} element={<LoginPage />} />
            <Route path={REGISTER_PATH} element={<RegisterPage />} />
            <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
            <Route path={RESET_PASSWORD_PATH} element={<ResetPasswordPage />} />

            {/* Home route */}
            {/* FIXME: Uncomment when home charts work */}
            {/* <Route path={HOME_PATH} element={<HomePage />} /> */}

            {/* Admin routes */}
            <Route path={ADMIN_PATH} element={<AdminPage />} />
            <Route path={ADMIN_USER_PATH} element={<UserManagement />} />
            <Route path={ADMIN_TAG_PATH} element={<TagManagementPage />} />
            <Route
              path={ADMIN_SUPPLIER_STATUS_PATH}
              element={<SupplierStatusManagementPage />}
            />
            <Route
              path={ADMIN_ECLASS_PATH}
              element={<EclassManagementPage />}
            />
            <Route
              path={ADMIN_ECLASS_CREATE_PATH}
              element={<EclassCreationPage />}
            />

            {/* Supplier routes */}
            <Route
              path={SUPPLIER_SEARCH_PATH}
              element={<SupplierSearchPage />}
            />
            <Route path={SUPPLIER_PATH} element={<SupplierPage />} />
            <Route
              path={SUPPLIER_CREATE_PATH}
              element={<SupplierCreationPage />}
            />
            <Route
              path={SINGLE_SUPPLIER_PATH}
              element={<SupplierDetailsPage />}
            />
            <Route
              path={SUPPLIER_RATE_CARD_PATH}
              element={<RateCardManagementPage />}
            />
            <Route
              path={IMPORT_RATE_CARD_PATH}
              element={<RateCardImportPage />}
            />
            <Route
              path={ADD_RATE_CARD_PATH}
              element={<RateCardCreationPage />}
            />
            <Route
              path={EDIT_RATE_CARD_PATH}
              element={<RateCardEditingPage />}
            />

            <Route
              path={SUPPLIER_RATING_PATH}
              element={<RatingManagementPage />}
            />

            {/* Redirect to home if route doesn't exist */}
            {/* FIXME: Redirect to home page when home charts work */}
            <Route path="*" element={<Navigate to={SUPPLIER_PATH} replace />} />
          </Routes>
        </BenchmarkDataVisibleProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
