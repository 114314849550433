import React, { useEffect, useState } from "react";
import { Pagination, Stack, Typography } from "@mui/material";
import EditableDetailsCard from "./EditableDetailsCard";

interface EditableDetailsCardWithPaginationProps {
  rowId: string;
  numPages: number;
  cardTitle: string;
  cardIcon?: React.ReactNode;
  onClickCreate?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: (id: string) => void;
  renderRow: (id: number) => void;
  showLatest?: boolean;
}

function EditableDetailsCardWithPagination({
  rowId,
  numPages,
  cardTitle,
  cardIcon,
  onClickCreate,
  onClickEdit,
  onClickDelete,
  renderRow,
  showLatest,
}: EditableDetailsCardWithPaginationProps) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (showLatest) {
      setPage(numPages);
    }
  }, [showLatest, numPages, setPage]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  // FIXME: can it be improved? How to avoid blinking effect on remove last paginated element?
  useEffect(() => {
    if (page > numPages) {
      setPage(numPages);
    }
  }, [numPages, page]);

  return (
    <EditableDetailsCard
      id={rowId}
      cardTitle={cardTitle}
      cardIcon={cardIcon}
      onClickCreate={onClickCreate}
      onClickEdit={numPages ? onClickEdit : undefined}
      onClickDelete={numPages ? onClickDelete : undefined}
    >
      <>
        {renderRow(page)}
        {numPages > 0 && (
          <Stack alignItems="center">
            <Pagination
              count={numPages}
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        )}
        {!numPages && (
          <Typography>Keine Elemente zum Anzeigen vorhanden</Typography>
        )}
      </>
    </EditableDetailsCard>
  );
}

export default EditableDetailsCardWithPagination;
