// Static paths
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset-password";
export const HOME_PATH = "/home";
export const ADMIN_PATH = "/admin";
export const ADMIN_USER_PATH = `${ADMIN_PATH}/user`;
export const ADMIN_TAG_PATH = `${ADMIN_PATH}/tag`;
export const ADMIN_SUPPLIER_STATUS_PATH = `${ADMIN_PATH}/supplier-status`;
export const ADMIN_ECLASS_PATH = `${ADMIN_PATH}/eclass`;
export const ADMIN_ECLASS_CREATE_PATH = `${ADMIN_ECLASS_PATH}/create-form`;
export const RATE_CARD_PATH = "/rate-card";
export const SUPPLIER_PATH = "/supplier";
export const RATING_PATH = "/rating";
export const SUPPLIER_SEARCH_PATH = "/supplier-search";
export const SUPPLIER_CREATE_PATH = `${SUPPLIER_PATH}/create-form`;
export const SINGLE_SUPPLIER_PATH = `${SUPPLIER_PATH}/:supplierId`;
export const SUPPLIER_RATE_CARD_PATH = `${SINGLE_SUPPLIER_PATH}${RATE_CARD_PATH}`;
export const SUPPLIER_RATING_PATH = `${SINGLE_SUPPLIER_PATH}${RATING_PATH}`;
export const IMPORT_RATE_CARD_PATH = `${SINGLE_SUPPLIER_PATH}/import-rate-card`;
export const ADD_RATE_CARD_PATH = `${SINGLE_SUPPLIER_PATH}/add-rate-card`;
export const EDIT_RATE_CARD_PATH = `${SINGLE_SUPPLIER_PATH}${RATE_CARD_PATH}/:rateCardId`;
export const PLACEHOLDER_PATH = "/placeholder.svg";

// Dynamic paths
export const singleSupplierPath = (supplierId: string) =>
  `${SUPPLIER_PATH}/${supplierId}`;
export const supplierRateCardPath = (supplierId: string) =>
  `${singleSupplierPath(supplierId)}${RATE_CARD_PATH}`;
export const supplierRatingPath = (supplierId: string) =>
  `${singleSupplierPath(supplierId)}${RATING_PATH}`;
export const importRateCardPath = (supplierId: string) =>
  `${singleSupplierPath(supplierId)}/import-rate-card`;
export const addRateCardPath = (supplierId: string) =>
  `${singleSupplierPath(supplierId)}/add-rate-card`;
export const editRateCardPath = (supplierId: string, rateCardId: string) =>
  `${supplierRateCardPath(supplierId)}/${rateCardId}`;
export const adminSupplierRateCardPath = (supplierId: string) =>
  `${ADMIN_PATH}${SUPPLIER_RATE_CARD_PATH}`;
