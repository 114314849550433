import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import AlertService from "../../shared/service/alert/alert.service";

type ImageUploadDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
};
export function ImageUploadDialog({
  isOpen,
  onClose,
  onUpload,
}: ImageUploadDialogProps) {
  const [file, setFile] = useState<File>();

  const handleSubmitUpload = async () => {
    if (file) {
      await onUpload(file);
      setFile(undefined);
    }
  };

  const handleCancelUpload = () => {
    onClose();
  };

  const handleFileDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      AlertService.warn("File is required");
    } else {
      const selectedFile = acceptedFiles[0];
      if (!(selectedFile.type as string).startsWith("image/")) {
        AlertService.warn("Only image file are allowed");
      } else {
        setFile(selectedFile);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop: handleFileDrop,
  });

  return (
    <Dialog open={isOpen} onClose={handleCancelUpload}>
      <DialogTitle>Upload</DialogTitle>
      <DialogContent>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            border="2px dashed #999999"
            borderRadius="8px"
            padding="32px"
            textAlign="center"
            marginBottom={3}
          >
            Hier Drag and drop das Bild oder hier klicken um Datei auszuwählen
          </Box>
        </div>
        {file && <div>{file.name}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelUpload} size="large">
          Abbrechen
        </Button>
        <Button onClick={handleSubmitUpload} size="large" disabled={!file}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
