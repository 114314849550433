import { registerUser } from "./auth/register-user";
import { loginUser } from "./auth/login-user";
import { resetPassword } from "./auth/reset-password";
import { forgotPassword } from "./auth/forgot-password";
import { getSupplier } from "./supplier/get-supplier";
import { getSupplierList } from "./supplier/get-supplier-list";
import { createTag } from "./tag/create-tag";
import { updateTag } from "./tag/update-tag";
import { getTagById } from "./tag/get-tag-by-id";
import { getTags } from "./tag/get-tags";
import { deleteTag } from "./tag/delete-tag";
import { getUsers } from "./user/get-users";
import { getEclassDetails } from "./eclass/get-eclass-details";
import { getEclassMainGroups } from "./eclass/get-eclass-main-groups";
import { getEclassSubSubgroups } from "./eclass/get-eclass-sub-subgroups";
import { getEclassSubgroups } from "./eclass/get-eclass-subgroups";
import { createEclassMainGroup } from "./eclass/create-eclass-main-group";
import { createEclassSubSubgroup } from "./eclass/create-eclass-sub-subgroup";
import { createEclassSubgroup } from "./eclass/create-eclass-subgroup";
import { deleteEclassMainGroup } from "./eclass/delete-eclass-main-group";
import { deleteEclassSubSubgroup } from "./eclass/delete-eclass-sub-subgroup";
import { deleteEclassSubgroup } from "./eclass/delete-eclass-subgroup";
import { deleteEclassValue } from "./eclass/delete-eclass-value";
import { createEclassValue } from "./eclass/create-eclass-value";
import { createSupplier } from "./supplier/create-supplier";
import { createUser } from "./user/create-user";
import { updateUser } from "./user/update-user";
import { deleteUser } from "./user/delete-user";
import { generateUserAuthToken } from "./user/generate-user-auth-token";
import { addRateCard } from "./ratecard/add-rate-card";
import { deleteRate } from "./ratecard/delete-rate";
import { deleteRateCard } from "./ratecard/delete-rate-card";
import { updateRate } from "./ratecard/update-rate";
import { addRate } from "./ratecard/add-rate";
import { exportRateCard } from "./ratecard/export-rate-card";
import { importRateCard } from "./ratecard/import-rate-card";
import { getRateCards } from "./ratecard/get-rate-cards";
import { getRateCardById } from "./ratecard/get-rate-card-by-id";
import { getEclassValues } from "./eclass/get-eclass-values";
import { upsertSkillLevels } from "./eclass/upsert-skill-levels";
import { getEclassValue } from "./eclass/get-eclass-value";
import { deleteSupplier } from "./supplier/delete-supplier";
import { deleteSupplierLogo } from "./supplier/delete-supplier-logo";
import { createSupplierLogo } from "./supplier/create-supplier-logo";
import { deleteSupplierAddress } from "./supplier/delete-supplier-address";
import { deleteSupplierBusinessContact } from "./supplier/delete-supplier-business-contact";
import { createSupplierAddress } from "./supplier/create-supplier-address";
import { createSupplierBusinessContact } from "./supplier/create-supplier-business-contact";
import { updateSupplier } from "./supplier/update-supplier";
import { getRateCardSummary } from "./ratecard/get-rate-card-summary";
import { searchSupplier } from "./supplier/search-supplier";
import { setSupplierAsABenchmark } from "./supplier/set-supplier-as-a-benchmark";
import { getBenchmarkSupplier } from "./supplier/get-benchmark-supplier";
import { logoutUser } from "./auth/logout-user";
import { createSupplierStatus } from "./supplier-status/create-supplier-status";
import { deleteSupplierStatus } from "./supplier-status/delete-supplier-status";
import { getSupplierStatusById } from "./supplier-status/get-supplier-status-by-id";
import { getSupplierStatuses } from "./supplier-status/get-supplier-statuses";
import { updateSupplierStatus } from "./supplier-status/update-supplier-status";
import { createRating } from "./rating/create-rating";
import { deleteRating } from "./rating/delete-rating";
import { getRatings } from "./rating/get-ratings";
import { updateRateCard } from "./ratecard/update-rate-card";
import { createSupplierNote } from "./supplier/create-supplier-note";
import { deleteSupplierNote } from "./supplier/delete-supplier-note";

const ApiService = {
  registerUser,
  loginUser,
  logoutUser,
  resetPassword,
  forgotPassword,
  supplier: {
    getSupplier,
    getSupplierList,
    createSupplier,
    createSupplierAddress,
    createSupplierBusinessContact,
    createSupplierLogo,
    createSupplierNote,
    deleteSupplier,
    deleteSupplierAddress,
    deleteSupplierBusinessContact,
    deleteSupplierLogo,
    deleteSupplierNote,
    updateSupplier,
    searchSupplier,
    benchmark: {
      setSupplierAsABenchmark,
      getBenchmarkSupplier,
    },
  },
  rating: {
    createRating,
    deleteRating,
    getRatings,
  },
  rateCard: {
    addRateCard,
    updateRateCard,
    deleteRateCard,
    exportRateCard,
    importRateCard,
    getRateCards,
    getRateCardById,
    getRateCardSummary,
    rate: {
      addRate,
      deleteRate,
      updateRate,
    },
  },
  tag: {
    createTag,
    updateTag,
    getTagById,
    getTags,
    deleteTag,
  },
  supplierStatus: {
    createSupplierStatus,
    updateSupplierStatus,
    getSupplierStatusById,
    getSupplierStatuses,
    deleteSupplierStatus,
  },
  user: {
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    generateUserAuthToken,
  },
  eclass: {
    getEclassValues,
    getEclassDetails,
    getEclassMainGroups,
    getEclassSubSubgroups,
    getEclassSubgroups,
    createEclassMainGroup,
    createEclassSubSubgroup,
    createEclassSubgroup,
    createEclassValue,
    deleteEclassMainGroup,
    deleteEclassSubSubgroup,
    deleteEclassSubgroup,
    deleteEclassValue,
    upsertSkillLevels,
    getEclassValue,
  },
};

export default ApiService;
