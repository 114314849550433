import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { RatingResponse } from "../../shared/type/RatingResponse";
import HomeContainer from "../../components/container/HomeContainer";
import { handleError } from "../../shared/helper/handle-error";
import AlertService from "../../shared/service/alert/alert.service";
import ApiService from "../../shared/service/api/api.service";
import { CreateRatingPayload } from "../../shared/service/api/rating/create-rating";
import RatingTable from "../../feature/rating/RatingTable";
import { useParams } from "react-router-dom";
import { CreateRatingDialog } from "../../feature/rating/CreateRatingDialog";

function RatingManagementPage() {
  const { supplierId } = useParams();
  const [ratings, setRatings] = useState<RatingResponse[]>([]);

  const fetchData = async () => {
    try {
      const ratingList = await ApiService.rating.getRatings(supplierId || "");
      if (ratingList) {
        setRatings(ratingList.data);
      }
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [supplierId]);

  const onDeleteRating = async (ratingId: string): Promise<void> => {
    try {
      await ApiService.rating.deleteRating(ratingId);
      AlertService.success("Rating deleted successfully!");
      await fetchData();
    } catch (error) {
      handleError(error);
    }
  };

  // CREATE DIALOG
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreateRating = () => {
    setCreateDialogOpen(true);
  };

  const handleConfirmCreate = async (payload: CreateRatingPayload) => {
    try {
      await ApiService.rating.createRating(supplierId || "", payload);
      AlertService.success("Rating created successfully!");
      await fetchData();
      setCreateDialogOpen(false);
    } catch (error) {
      // FIXME: handle rating duplication error
      handleError(error);
    }
  };

  const handleCancelCreate = () => {
    setCreateDialogOpen(false);
  };

  return (
    <HomeContainer>
      <Box display="flex" alignItems="center" marginBottom={3}>
        <Typography sx={{ flexGrow: 1 }} component="h1" variant="h4">
          Lieferanten Bewertung
        </Typography>
        <Button variant="outlined" onClick={handleCreateRating}>
          Rate
        </Button>
      </Box>
      <RatingTable
        deleteRating={onDeleteRating}
        ratingList={ratings}
      ></RatingTable>
      <CreateRatingDialog
        isOpen={isCreateDialogOpen}
        onClose={handleCancelCreate}
        onCreate={handleConfirmCreate}
      />
    </HomeContainer>
  );
}

export default RatingManagementPage;
