import apiClient from "../api.client";
import { AUTH_URL } from "../api.const";

export interface ForgotPasswordPayload {
  email: string;
}

export const forgotPassword = async (
  forgotPasswordPayload: ForgotPasswordPayload
) => {
  try {
    await apiClient.post(`${AUTH_URL}/forgot-password`, forgotPasswordPayload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
