import React from "react";
import AuthContainer from "../../../components/container/AuthContainer";
import { useForm } from "react-hook-form";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { RepeatedPasswordInput } from "../../../feature/inputs/RepeatedPasswordInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import { PasswordInput } from "../../../feature/inputs/PasswordInput";
import { handleError } from "../../../shared/helper/handle-error";
function ResetPasswordPage() {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const onSubmit = async (data: any) => {
    if (!token) {
      AlertService.error("Invalid token");
    } else {
      const resetPasswordPayload = {
        token,
        password: data.password,
      };

      try {
        await ApiService.resetPassword(resetPasswordPayload);
        AlertService.info("Successfully reset password. Now you can sign in.");
        navigate("/home");
      } catch (error: any) {
        handleError(error);
      }
    }
  };

  return (
    <AuthContainer onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h1" variant="h6" fontWeight={700} marginBottom={2}>
        Ein neues Passwort festlegen
      </Typography>
      <PasswordInput
        control={control}
        errors={errors}
        props={{
          variant: "filled",
        }}
      />
      <RepeatedPasswordInput
        control={control}
        errors={errors}
        getValues={getValues}
        props={{
          variant: "filled",
        }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button
          onClick={() => navigate("/login")}
          variant="outlined"
          size="large"
          sx={{ mt: 3, mb: 2 }}
        >
          Einloggen
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          disableElevation
          disabled={isSubmitting}
          sx={{ mt: 3, mb: 2 }}
        >
          Passwort ändern
        </Button>
      </Box>
    </AuthContainer>
  );
}

export default ResetPasswordPage;
