import apiClient from "../api.client";
import { SUPPLIER_RATING_URL } from "../api.const";

export interface CreateRatingPayload {
  quality: number;
  time: number;
  budget: number;
  note?: string;
}
export const createRating = async (
  supplierId: string,
  payload: CreateRatingPayload
): Promise<void> => {
  try {
    await apiClient.post<void>(SUPPLIER_RATING_URL(supplierId), payload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
