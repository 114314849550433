import React from "react";
import AuthContainer from "../../../components/container/AuthContainer";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { Box, Button, Typography } from "@mui/material";
import { EmailInput } from "../../../feature/inputs/EmailInput";

function ForgotPasswordPage() {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      await ApiService.forgotPassword(data);
      AlertService.info("Check your email for reset link.");
      navigate("/home");
    } catch (error: any) {
      if (typeof error === "string") {
        AlertService.error(error);
      }
    }
  };

  return (
    <AuthContainer onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h1" variant="h6" fontWeight={700} marginBottom={2}>
        Posswort zurucksetzen
      </Typography>

      <EmailInput
        required
        control={control}
        errors={errors}
        props={{
          variant: "filled",
        }}
      />
      <Box display="flex" justifyContent="end">
        <Button
          type="submit"
          variant="contained"
          size="large"
          disableElevation
          disabled={isSubmitting}
          sx={{ mt: 3, mb: 2 }}
        >
          Passwort jetzt zurucksetzen
        </Button>
      </Box>
    </AuthContainer>
  );
}
export default ForgotPasswordPage;
