import { error } from "./error";
import { info } from "./info";
import { success } from "./success";
import { warn } from "./warn";
import { delay } from "./delay";

const AlertService = {
  success,
  error,
  info,
  warn,
  delay,
};
export default AlertService;
