import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import _ from "lodash";
import { Controller, Control, FieldErrors } from "react-hook-form";

type SelectInputProps = {
  control: Control<any, any>;
  errors: FieldErrors;
  fieldName: string;
  label: string;
  required?: boolean;
  items: { id: string; name: string }[];
  shrink?: boolean;
};

export const SelectInput: React.FC<SelectInputProps & FormControlProps> = ({
  control,
  errors,
  fieldName,
  label,
  required,
  items,
  shrink,
  ...rest
}) => {
  return (
    <FormControl
      fullWidth
      margin="dense"
      {...rest}
      error={!!_.get(errors, fieldName)}
    >
      <InputLabel shrink={shrink}>{label}</InputLabel>
      <Controller
        control={control}
        name={fieldName}
        rules={required ? { required: `${label} is required` } : {}}
        defaultValue={null}
        render={({ field }) => (
          <>
            <Select {...field} label={label}>
              {items.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
            {!!_.get(errors, fieldName) && (
              <FormHelperText>
                {_.get(errors, fieldName)?.message?.toString() || ""}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};
