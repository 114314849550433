import { TextField } from "@mui/material";
import { Controller, Control, FieldErrors } from "react-hook-form";

type PasswordInputProps = {
  control: Control<any, any>;
  errors: FieldErrors;
  props?: any;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  control,
  errors,
  props,
}) => {
  const fieldName = "password";
  return (
    <Controller
      control={control}
      name="password"
      rules={{
        required: "Password is required",
        minLength: {
          value: 6,
          message: "Password must be at least 6 characters long",
        },
      }}
      defaultValue={null}
      render={({ field }) => (
        <TextField
          {...field}
          label="Password"
          type="password"
          error={!!errors[fieldName]}
          helperText={errors[fieldName]?.message?.toString() || ""}
          fullWidth
          margin="dense"
          {...props}
        />
      )}
    />
  );
};
