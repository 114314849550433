import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_Virtualizer,
} from "material-react-table";
import { MenuItem } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { EclassDetails } from "../../shared/type/EclassDetails";
import { ConfirmDialog } from "../../components/dialog/ConfirmDialog";
import { UpsertSkillLevelsPayload } from "../../shared/service/api/eclass/upsert-skill-levels";
import { UpdateSkillLevelDialog } from "./UpdateSkillLevelDialog";

const eclassColumns: MRT_ColumnDef<EclassDetails>[] = [
  {
    accessorKey: "mainGroup.name",
    header: "Level 1",
    size: 130,
  },
  {
    accessorKey: "subGroup.name",
    header: "Level 2",
    size: 130,
  },
  {
    accessorKey: "subSubGroup.name",
    header: "Level 3",
    size: 130,
  },

  {
    accessorKey: "value.name",
    header: "Level 4",
    size: 130,
  },
];
type EclassTableProps = {
  eclassList: EclassDetails[];
  upsertSkillLevel: (payload: UpsertSkillLevelsPayload) => void;
  deleteEclassValue: (eclassValueId: string) => void;
};

const EclassTable = ({
  eclassList,
  upsertSkillLevel,
  deleteEclassValue,
}: EclassTableProps) => {
  const columns = useMemo<MRT_ColumnDef<EclassDetails>[]>(
    () => eclassColumns,
    []
  );

  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [data, setData] = useState<EclassDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [selectedTag, setSelectedTag] = useState<EclassDetails | null>(null);

  useEffect(() => {
    setData(eclassList);
    setIsLoading(false);
  }, [eclassList]);

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  // DELETE DIALOG
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleEclassValueDelete = (eclassDetails: EclassDetails) => {
    setSelectedTag(eclassDetails);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    const eclassValueId = selectedTag?.value?.id;
    if (eclassValueId) {
      deleteEclassValue(eclassValueId);
    }
    setDeleteDialogOpen(false);
    setSelectedTag(null);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedTag(null);
  };

  // UPDATE DIALOG
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);

  const handleSkillLevelUpdate = (eclassDetails: EclassDetails) => {
    setSelectedTag(eclassDetails);
    setUpdateDialogOpen(true);
  };

  const handleConfirmUpdate = (payload: UpsertSkillLevelsPayload) => {
    upsertSkillLevel(payload);
    setUpdateDialogOpen(false);
    setSelectedTag(null);
  };

  const handleCancelUpdate = () => {
    setUpdateDialogOpen(false);
    setSelectedTag(null);
  };

  return (
    <div className="customReactMaterialTable">
      <MaterialReactTable
        columns={columns}
        data={data}
        defaultDisplayColumn={{ enableResizing: true }}
        enableBottomToolbar={false}
        enableColumnVirtualization
        enableGlobalFilterModes
        enablePagination={false}
        enableHiding={false}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
        onSortingChange={setSorting}
        state={{ isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        rowVirtualizerProps={{ overscan: 5 }}
        columnVirtualizerProps={{ overscan: 2 }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          // <MenuItem
          //   key={0}
          //   onClick={() => {
          //     handleEclassUpdate(row.original);
          //     closeMenu();
          //   }}
          //   sx={{ m: 0 }}
          // >
          //   <Edit></Edit>
          //   Edit eclass
          // </MenuItem>,
          <MenuItem
            key={0}
            onClick={() => {
              handleSkillLevelUpdate(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <Edit></Edit>
            Edit Eclass
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleEclassValueDelete(row.original);
            }}
            sx={{ m: 0 }}
          >
            <Delete></Delete>
            Delete
          </MenuItem>,
        ]}
      />
      {selectedTag?.value?.id && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleCancelDelete}
          onAction={handleConfirmDelete}
          title="Delete element"
          content="Are you sure you want to delete?"
          buttonText="Delete"
          id={selectedTag.value.id}
        />
      )}

      {selectedTag?.value?.id && (
        <UpdateSkillLevelDialog
          isOpen={isUpdateDialogOpen}
          onClose={handleCancelUpdate}
          onUpdate={handleConfirmUpdate}
          eclassValueId={selectedTag.value.id}
        />
      )}
    </div>
  );
};

export default EclassTable;
