import { useEffect, useState } from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import { Box, Button, Typography } from "@mui/material";
import TagTable from "../../../feature/tag/TagTable";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { Tag } from "../../../shared/type/Tag";
import { CreateTagDialog } from "../../../feature/tag/CreateTagDialog";
import { handleError } from "../../../shared/helper/handle-error";

function TagManagementPage() {
  const [tags, setTags] = useState<Tag[]>([]);

  const fetchData = async () => {
    try {
      const tagList = await ApiService.tag.getTags();
      if (tagList) {
        setTags(tagList.data);
      }
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const updateTag = async (updatedTag: Tag) => {
    try {
      await ApiService.tag.updateTag(updatedTag.id, {
        name: updatedTag.name,
      });
      AlertService.success("Tag updated successfully!");
      await fetchData();
    } catch (error) {
      AlertService.error("Error updating tag");
    }
  };

  const deleteTag = async (tagId: string) => {
    try {
      await ApiService.tag.deleteTag(tagId);
      AlertService.success("Tag deleted successfully!");
      await fetchData();
    } catch (error) {
      AlertService.error("Error deleting tag");
    }
  };
  // CREATE DIALOG
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreateTag = () => {
    setCreateDialogOpen(true);
  };

  const handleConfirmCreate = async (name: string) => {
    try {
      if (name.trim() !== "") {
        await ApiService.tag.createTag({ name });
        AlertService.success("Tag created successfully!");
        await fetchData();
      }
      setCreateDialogOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancelCreate = () => {
    setCreateDialogOpen(false);
  };

  return (
    <HomeContainer>
      <Box display="flex" alignItems="center" marginBottom={3}>
        <Typography component="h1" variant="h4" sx={{ flexGrow: 1 }}>
          Tags Management
        </Typography>
        <Button variant="outlined" onClick={handleCreateTag}>
          Create tag
        </Button>
      </Box>
      <CreateTagDialog
        isOpen={isCreateDialogOpen}
        onClose={handleCancelCreate}
        onCreate={handleConfirmCreate}
      />
      <TagTable
        tagList={tags}
        updateTag={updateTag}
        deleteTag={deleteTag}
      ></TagTable>
    </HomeContainer>
  );
}

export default TagManagementPage;
