import apiClient from "../api.client";
import { SUPPLIER_STATUS_URL } from "../api.const";
import { SupplierStatus } from "../../../type/SupplierStatus";

export const getSupplierStatusById = async (supplierStatusId: string) => {
  try {
    const response = await apiClient.get<SupplierStatus>(
      `${SUPPLIER_STATUS_URL}/${supplierStatusId}`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
