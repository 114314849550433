import React from "react";
import HomeContainer from "../../components/container/HomeContainer";
import SearchResultPage from "./SearchResultPage";

function SupplierSearchPage() {
  return (
    <HomeContainer functionHeaderType="search-bar">
      <SearchResultPage></SearchResultPage>
    </HomeContainer>
  );
}

export default SupplierSearchPage;
