import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";
import { ListResponse } from "../../../type/ListResponse";
import { EclassDetails } from "../../../type/EclassDetails";

export const getEclassDetails = async (): Promise<
  ListResponse<EclassDetails>
> => {
  try {
    const response = await apiClient.get<ListResponse<EclassDetails>>(
      `${ECLASS_URL}/details`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
