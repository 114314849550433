import { Card, CardActions, IconButton, CardMedia } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import React, { useState } from "react";

import { PLACEHOLDER_PATH } from "../../shared/const/path.const";
import { ConfirmDialog } from "../dialog/ConfirmDialog";
import { ImageUploadDialog } from "../dialog/ImageUploadDialog";

interface EditableImageCardProps {
  id: string;
  src: string;
  alt: string;
  uploadImage?: (id: string, file: File) => void;
  deleteImage?: (id: string) => void;
}

function EditableImageCard({
  id,
  src,
  alt,
  uploadImage,
  deleteImage,
}: EditableImageCardProps) {
  const [showImage, setShowImage] = useState(true);
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());

  const handleImageError = () => {
    setShowImage(false);
  };

  const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);

  const handleUploadImage = () => {
    setUploadDialogOpen(true);
  };

  const handleConfirmUploadImage = async (file: File) => {
    if (uploadImage) {
      await uploadImage(id, file);
      await setShowImage(true);
      setImageTimestamp(Date.now());
    }
    setUploadDialogOpen(false);
  };

  const handleCancelUploadImage = () => {
    setUploadDialogOpen(false);
  };

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleImageDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (deleteImage) {
      deleteImage(id);
    }
    setDeleteDialogOpen(false);
    setShowImage(false);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const uploadButton = uploadImage ? (
    <IconButton size="small" onClick={handleUploadImage}>
      <Edit />
    </IconButton>
  ) : null;

  const deleteButton =
    deleteImage && showImage ? (
      <IconButton size="small" onClick={handleImageDelete}>
        <Delete />
      </IconButton>
    ) : null;

  return (
    <>
      <Card>
        <CardMedia
          component="img"
          // reload image on change
          key={imageTimestamp}
          image={showImage ? src : PLACEHOLDER_PATH}
          alt={alt}
          onError={handleImageError}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            p: "10%",
          }}
        />

        <CardActions sx={{ justifyContent: "flex-end" }}>
          {uploadButton}
          {deleteButton}
        </CardActions>
      </Card>
      {isUploadDialogOpen && (
        <ImageUploadDialog
          isOpen={isUploadDialogOpen}
          onClose={handleCancelUploadImage}
          onUpload={handleConfirmUploadImage}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleCancelDelete}
          onAction={handleConfirmDelete}
          title="Delete element"
          content="Are you sure you want to delete?"
          buttonText="Delete"
          id={src}
        />
      )}
    </>
  );
}

export default EditableImageCard;
