import { SINGLE_SUPPLIER_URL } from "../api.const";
import apiClient from "../api.client";

export const deleteSupplier = async (supplierId: string): Promise<void> => {
  try {
    await apiClient.delete(SINGLE_SUPPLIER_URL(supplierId));
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
