import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_Virtualizer,
} from "material-react-table";
import { MenuItem } from "@mui/material";
import { Delete, Edit, Token } from "@mui/icons-material";
import { ConfirmDialog } from "../../components/dialog/ConfirmDialog";
import { UpdateUserPayload } from "../../shared/service/api/user/update-user";
import { UserDetails } from "../../shared/type/UserDetails";
import { UpdateUserDialog } from "./UpdateUserDialog";

const userColumns: MRT_ColumnDef<UserDetails>[] = [
  {
    accessorKey: "email",
    header: "Email",
    size: 150,
  },
  {
    accessorKey: "firstName",
    header: "Vorname",
    size: 150,
  },
  {
    accessorKey: "lastName",
    header: "Nachname",
    size: 150,
  },
  {
    accessorKey: "role",
    header: "Rolle",
    size: 100,
  },
  {
    accessorKey: "createdAt",
    header: "Erstellt",
    size: 130,
    Cell: ({ renderedCellValue }) => (
      <div>{new Date(renderedCellValue as string).toDateString()}</div>
    ),
  },
  {
    accessorKey: "updatedAt",
    header: "Aktualisiert",
    size: 130,
    Cell: ({ renderedCellValue }) => (
      <div>{new Date(renderedCellValue as string).toDateString()}</div>
    ),
  },
];

type UserTableProps = {
  userList: UserDetails[];
  updateUser: (payload: UpdateUserPayload) => void;
  deleteUser: (userId: string) => void;
  onGenerateUserAuthToken: (userId: string) => void;
};

const UserTable = ({
  userList,
  updateUser,
  deleteUser,
  onGenerateUserAuthToken,
}: UserTableProps) => {
  const columns = useMemo<MRT_ColumnDef<UserDetails>[]>(() => userColumns, []);

  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [data, setData] = useState<UserDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [selectedUser, setSelectedUser] = useState<UserDetails | null>(null);

  useEffect(() => {
    setData(userList);
    setIsLoading(false);
  }, [userList]);

  useEffect(() => {
    //scroll to the top of the supplier-table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  const handleGenerateUserAuthToken = (userId: string) => {
    onGenerateUserAuthToken(userId);
  };

  // DELETE DIALOG
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = (UserDto: UserDetails) => {
    setSelectedUser(UserDto);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedUser?.id) {
      deleteUser(selectedUser.id);
    }
    setDeleteDialogOpen(false);
    setSelectedUser(null);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedUser(null);
  };

  // UPDATE DIALOG
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);

  const handleUpdate = (UserDto: UserDetails) => {
    setSelectedUser(UserDto);
    setUpdateDialogOpen(true);
  };

  const handleConfirmUpdate = (payload: UpdateUserPayload) => {
    updateUser(payload);
    setUpdateDialogOpen(false);
    setSelectedUser(null);
  };

  const handleCancelUpdate = () => {
    setUpdateDialogOpen(false);
    setSelectedUser(null);
  };

  return (
    <div className="customReactMaterialTable">
      <MaterialReactTable
        columns={columns}
        data={data}
        defaultDisplayColumn={{ enableResizing: true }}
        enableBottomToolbar={false}
        enableColumnVirtualization
        enableGlobalFilterModes
        enablePagination={false}
        enableHiding={false}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
        onSortingChange={setSorting}
        state={{ isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        rowVirtualizerProps={{ overscan: 5 }}
        columnVirtualizerProps={{ overscan: 2 }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              handleGenerateUserAuthToken(row.original.id);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <Token></Token>
            Get auth token
          </MenuItem>,
          <MenuItem
            key={0}
            onClick={() => {
              handleUpdate(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <Edit></Edit>
            Edit
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleDelete(row.original);
            }}
            sx={{ m: 0 }}
          >
            <Delete></Delete>
            Delete
          </MenuItem>,
        ]}
      />
      {selectedUser?.id && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleCancelDelete}
          onAction={handleConfirmDelete}
          title="Delete element"
          content="Are you sure you want to delete?"
          buttonText="Delete"
          id={selectedUser.id}
        />
      )}
      {selectedUser?.id && (
        <UpdateUserDialog
          isOpen={isUpdateDialogOpen}
          onClose={handleCancelUpdate}
          onUpdate={handleConfirmUpdate}
          rowData={{
            userId: selectedUser.id,
            firstName: selectedUser?.firstName,
            lastName: selectedUser?.lastName,
            email: selectedUser?.email,
          }}
        />
      )}
    </div>
  );
};

export default UserTable;
