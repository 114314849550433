import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";
import { EclassValueDetails } from "../../../type/EclassValueDetails";

export const getEclassValue = async (
  eclassValueId: string
): Promise<EclassValueDetails> => {
  try {
    const response = await apiClient.get<EclassValueDetails>(
      `${ECLASS_URL}/values/${eclassValueId}`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
