import { SINGLE_SUPPLIER_URL } from "../api.const";
import apiClient from "../api.client";
import { SupplierTypeEnum } from "../../../enum/SupplierTypeEnum";
import { ClassificationEnum } from "../../../enum/ClassificationEnum";
import { DeliveryModelEnum } from "../../../enum/DeliveryModelEnum";

export interface UpdateSupplierPayload {
  id: string;
  companyName: string;
  websiteUrl?: string;
  vatNumber?: string;
  taxNumber?: string;
  sapNumber?: string;
  type: SupplierTypeEnum;
  classification: ClassificationEnum;
  deliveryModel: DeliveryModelEnum;
  note?: string;
}

export const updateSupplier = async (
  updateSupplierPayload: UpdateSupplierPayload
): Promise<void> => {
  try {
    await apiClient.put(
      SINGLE_SUPPLIER_URL(updateSupplierPayload.id),
      updateSupplierPayload
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
