import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

type UpdateFieldDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onAction: (id?: string) => void;
  id?: string;
  title: string;
  content: string;
  buttonText: string;
};
export function ConfirmDialog({
  isOpen,
  onClose,
  onAction,
  id,
  title,
  content,
  buttonText,
}: UpdateFieldDialogProps) {
  const handleConfirm = () => {
    onAction(id);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{content}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleConfirm} color="error">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
