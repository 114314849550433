export const getCurrentHost = () => {
  const currentHost = window.location.hostname;
  const currentProtocol = window.location.protocol;

  if (currentHost === "localhost") {
    const currentPort =
      window.location.port || (currentProtocol === "https:" ? "443" : "80");

    return `${currentProtocol}//${currentHost}:${currentPort}`;
  } else {
    return `${currentProtocol}//${currentHost}`;
  }
};
