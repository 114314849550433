import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";
import { SkillLevel } from "../../../type/SkillLevel";

export interface UpsertSkillLevelsPayload {
  skillLevels: SkillLevel[];
  eclassValueId: string;
}

export const upsertSkillLevels = async (
  payload: UpsertSkillLevelsPayload
): Promise<void> => {
  try {
    await apiClient.put(
      `${ECLASS_URL}/values/${payload.eclassValueId}/skill-level`,
      payload
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
