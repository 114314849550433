import apiClient from "../api.client";
import { RATE_CARD_URL } from "../api.const";
import { RateCardSummary } from "../../../type/RateCardSummary";

export const getRateCardSummary = async (
  supplierId: string
): Promise<RateCardSummary> => {
  try {
    const response = await apiClient.get<RateCardSummary>(
      `${RATE_CARD_URL(supplierId)}/summary`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
