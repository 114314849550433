import apiClient from "../api.client";
import { TAG_URL } from "../api.const";
import { Tag } from "../../../type/Tag";
import { ListResponse } from "../../../type/ListResponse";

export const getTags = async () => {
  try {
    const response = await apiClient.get<ListResponse<Tag>>(TAG_URL);
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
