import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { CreateRatingPayload } from "../../shared/service/api/rating/create-rating";
import { RatingInput } from "../inputs/RatingInput";
import { TextInput } from "../inputs/TextInput";

type CreateFieldDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (payload: CreateRatingPayload) => void;
};
export function CreateRatingDialog({
  isOpen,
  onClose,
  onCreate,
}: CreateFieldDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateRatingPayload>();

  const onSubmit = async (data: CreateRatingPayload) => {
    // FIXME: get numbers from rating component directly
    data = {
      quality: Number(data.quality),
      time: Number(data.time),
      budget: Number(data.budget),
      note: data.note,
    };
    await onCreate(data);
    reset();
  };

  const handleCancelCreate = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelCreate}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Liferanten bewerten</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={5}>
            <Box>
              <RatingInput
                control={control}
                errors={errors}
                fieldName="quality"
                label="Qualität"
                required={true}
              />
            </Box>
            <Box>
              <RatingInput
                control={control}
                errors={errors}
                fieldName="time"
                label="Time"
                required={true}
              />
            </Box>
            <Box>
              <RatingInput
                control={control}
                errors={errors}
                fieldName="budget"
                label="Budget"
                required={true}
              />
            </Box>
          </Stack>
          <Box>
            <TextInput
              control={control}
              errors={errors}
              fieldName="note"
              label="Note"
              multiline
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCreate} size="large">
            Abbrechen
          </Button>
          <Button type="submit" size="large">
            Bewerten
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
