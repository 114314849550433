import React from "react";
import "./CircleLoader.scss";

const CircleLoader: React.FC = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <div className="circle-loader"></div>
    </div>
  );
};

export default CircleLoader;
