import apiClient from "../api.client";
import { TAG_URL } from "../api.const";

export interface CreateTagPayload {
  name: string;
}

export const createTag = async (createTagPayload: CreateTagPayload) => {
  try {
    await apiClient.post(TAG_URL, createTagPayload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
