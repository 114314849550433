import { Rating, Typography } from "@mui/material";
import _ from "lodash";
import { Controller, Control, FieldErrors } from "react-hook-form";

type RatingInputProps = {
  control: Control<any, any>;
  errors: FieldErrors;
  fieldName: string;
  label: string;
  required?: boolean;
};

export const RatingInput: React.FC<RatingInputProps> = ({
  control,
  errors,
  fieldName,
  label,
  required,
}) => {
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={required ? { required: `${label} is required` } : {}}
      defaultValue={null}
      render={({ field }) => (
        <>
          <Typography component="legend">{label}</Typography>
          <Rating {...field} />
        </>
      )}
    />
  );
};
