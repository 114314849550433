import React, { useEffect, useRef } from "react";
import apiClient from "../service/api/api.client";
import { LOGIN_PATH } from "../const/path.const";
import { useNavigate } from "react-router-dom";
import AlertService from "../service/alert/alert.service";

function AxiosInterceptorNavigate() {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const interceptorRef = useRef<number>(0);

  const setInterceptor = (navigate: any) => {
    return apiClient.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        switch (error.response.status) {
          case 401:
            navigate(LOGIN_PATH);
            break;
          case 403:
            navigate(-1);
            AlertService.error("Access forbidden");
            break;
          default:
            return Promise.reject(error);
        }
      }
    );
  };

  useEffect(() => {
    interceptorRef.current = setInterceptor(navigateRef.current);
    return () => {
      apiClient.interceptors.response.eject(interceptorRef.current);
    };
  }, []);

  return <></>;
}

export default AxiosInterceptorNavigate;
