import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { TextInput } from "../inputs/TextInput";
import { BusinessContact } from "../../shared/type/BusinessContact";
import { EmailInput } from "../inputs/EmailInput";

type CreateSupplierBusinessContactDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (payload: Omit<BusinessContact, "id">) => void;
};
export function CreateSupplierBusinessContactDialog({
  isOpen,
  onClose,
  onCreate,
}: CreateSupplierBusinessContactDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Omit<BusinessContact, "id">>();

  const onSubmit = async (data: Omit<BusinessContact, "id">) => {
    await onCreate(data);
    reset();
  };

  const handleCancelCreate = () => {
    onClose();
    reset();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelCreate}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Ansprechpartner</DialogTitle>
        <DialogContent>
          <TextInput
            control={control}
            errors={errors}
            fieldName="firstName"
            label="Vorname"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="lastName"
            label="Nachname"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="phone"
            label="Telefon"
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="mobilePhone"
            label="Mobil Telefon"
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="fax"
            label="Fax"
          />
          <EmailInput control={control} errors={errors} required={false} />
          <TextInput
            control={control}
            errors={errors}
            fieldName="note"
            label="Notiz"
            multiline
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="street"
            label="Straße"
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="state"
            label="Staat"
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="city"
            label="Stadt"
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="country"
            label="Land"
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="zipCode"
            label="PLZ"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCreate} size="large">
            Abbrechen
          </Button>
          <Button type="submit" size="large">
            Bewerten
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
