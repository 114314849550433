import { useState } from "react";
import EditableDetailsCardWithPagination from "../../components/card/EditableDetailsCardWithPagination";
import { Address } from "../../shared/type/Address";
import { Place } from "@mui/icons-material";
import { SupplierAddress } from "./SupplierAddress";
import { CreateSupplierAddressDialog } from "./CreateSupplierAddressDialog";
import ApiService from "../../shared/service/api/api.service";
import AlertService from "../../shared/service/alert/alert.service";
import { handleError } from "../../shared/helper/handle-error";

type SupplierAddressesProps = {
  addresses: Address[];
  supplierId: string;
  deleteSupplierAddress: (supplierId: string, addressId: string) => void;
  fetchData: () => void;
};

export function SupplierAddresses({
  addresses,
  supplierId,
  deleteSupplierAddress,
  fetchData,
}: SupplierAddressesProps) {
  // FIXME: can it be improved to avoid extra renders?
  const [currentAddressId, setCurrentAddressId] = useState<string>("");
  const [showLatest, setShowLatest] = useState(false);

  const handleDelete = (id: string) => {
    deleteSupplierAddress(supplierId, id);
  };

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  const handleConfirmCreate = async (payload: Omit<Address, "id">) => {
    try {
      await ApiService.supplier.createSupplierAddress(supplierId, payload);
      AlertService.success("Supplier address created successfully!");
      await fetchData();
      setCreateDialogOpen(false);
      setShowLatest(true);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancelCreate = () => {
    setCreateDialogOpen(false);
  };

  return (
    <>
      <EditableDetailsCardWithPagination
        cardTitle="Standorte"
        cardIcon={<Place />}
        onClickCreate={handleCreate}
        onClickDelete={handleDelete}
        rowId={currentAddressId}
        numPages={addresses.length}
        renderRow={(page: number) =>
          addresses.slice(page - 1, page).map((address) => {
            setCurrentAddressId(address.id);
            return <SupplierAddress address={address} />;
          })
        }
        showLatest={showLatest}
      />
      {isCreateDialogOpen && (
        <CreateSupplierAddressDialog
          isOpen={isCreateDialogOpen}
          onClose={handleCancelCreate}
          onCreate={handleConfirmCreate}
        />
      )}
    </>
  );
}
