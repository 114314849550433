import apiClient from "../api.client";
import { SUPPLIER_RATING_URL } from "../api.const";
import { ListResponse } from "../../../type/ListResponse";
import { RatingResponse } from "../../../type/RatingResponse";

export const getRatings = async (
  supplierId: string
): Promise<ListResponse<RatingResponse>> => {
  try {
    const response = await apiClient.get<ListResponse<RatingResponse>>(
      SUPPLIER_RATING_URL(supplierId)
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
