import React, { useEffect, useState } from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import { Box, Button, Typography } from "@mui/material";
import UserTable from "../../../feature/user/UserTable";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { UserDetails } from "../../../shared/type/UserDetails";
import { CreateUserPayload } from "../../../shared/service/api/user/create-user";
import { UpdateUserPayload } from "../../../shared/service/api/user/update-user";
import { CreateUserDialog } from "../../../feature/user/CreateUserDialog";
import { handleError } from "../../../shared/helper/handle-error";
import { getCurrentHost } from "../../../shared/helper/get-current-host";

function UserManagementPage() {
  const [users, setUsers] = useState<UserDetails[]>([]);

  const fetchData = async () => {
    try {
      const userList = await ApiService.user.getUsers();
      if (userList) {
        setUsers(userList.data);
      }
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onUpdateUser = async (payload: UpdateUserPayload): Promise<void> => {
    try {
      await ApiService.user.updateUser(payload);
      AlertService.success("User updated successfully!");
      await fetchData();
    } catch (error) {
      handleError(error);
    }
  };

  const onDeleteUser = async (userId: string): Promise<void> => {
    try {
      await ApiService.user.deleteUser(userId);
      AlertService.success("User deleted successfully!");
      await fetchData();
    } catch (error) {
      handleError(error);
    }
  };

  const onGenerateUserAuthToken = async (userId: string): Promise<void> => {
    try {
      const response = await ApiService.user.generateUserAuthToken(userId);
      if (response) {
        const resetPasswordLink = `${getCurrentHost()}/reset-password?token=${
          response.authToken
        }`;
        AlertService.info("Reset password link is below");
        AlertService.info(resetPasswordLink, {
          autoClose: 12000,
          closeOnClick: false,
          draggable: false,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  // CREATE DIALOG
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreateUser = () => {
    setCreateDialogOpen(true);
  };

  const handleConfirmCreate = async (payload: CreateUserPayload) => {
    try {
      await ApiService.user.createUser(payload);
      AlertService.success("User created successfully!");
      await fetchData();
      setCreateDialogOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancelCreate = () => {
    setCreateDialogOpen(false);
  };

  return (
    <HomeContainer>
      <Box display="flex" alignItems="center" marginBottom={3}>
        <Typography sx={{ flexGrow: 1 }} component="h1" variant="h4">
          Users Management
        </Typography>
        <Button variant="outlined" onClick={handleCreateUser}>
          Create user
        </Button>
      </Box>
      <UserTable
        deleteUser={onDeleteUser}
        updateUser={onUpdateUser}
        onGenerateUserAuthToken={onGenerateUserAuthToken}
        userList={users}
      ></UserTable>
      <CreateUserDialog
        isOpen={isCreateDialogOpen}
        onClose={handleCancelCreate}
        onCreate={handleConfirmCreate}
      />
    </HomeContainer>
  );
}

export default UserManagementPage;
