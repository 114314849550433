import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";

type UpdateFieldDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (id: string, statusName: string) => void;
  initialData: { id?: string; value?: string };
};
export function UpdateSupplierStatusDialog({
  isOpen,
  onClose,
  onUpdate,
  initialData,
}: UpdateFieldDialogProps) {
  const [statusName, setName] = useState("");

  useEffect(() => {
    if (isOpen && initialData?.value) {
      setName(initialData.value);
    }
  }, [isOpen, initialData]);
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleConfirmUpdate = () => {
    if (initialData.id) {
      onUpdate(initialData.id, statusName);
    }
    onClose();
    setName("");
  };

  const handleCancelUpdate = () => {
    onClose();
    setName("");
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelUpdate}>
      <DialogTitle>Update supplier status</DialogTitle>
      <Box>
        <DialogContent>
          <TextField
            label="Status name"
            value={statusName}
            onChange={handleNameChange}
            fullWidth
          />
        </DialogContent>
      </Box>
      <DialogActions>
        <Button onClick={handleCancelUpdate} size="large">
          Abbrechen
        </Button>
        <Button onClick={handleConfirmUpdate} size="large">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
