import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HomeContainer from "../../components/container/HomeContainer";
import ApiService from "../../shared/service/api/api.service";
import AlertService from "../../shared/service/alert/alert.service";
import CircleLoader from "../../components/loader/CircleLoader";
import { SupplierDetails } from "../../shared/type/SupplierDetails";
import SupplierDetailsComponent from "../../feature/supplier/SupplierDetailsComponent";
import { SUPPLIER_PATH } from "../../shared/const/path.const";
import { UpdateSupplierPayload } from "../../shared/service/api/supplier/update-supplier";
import { RateCardSummary } from "../../shared/type/RateCardSummary";
import { handleError } from "../../shared/helper/handle-error";

function SupplierPage() {
  const [supplier, setSupplier] = useState<SupplierDetails>();
  const [rateCardSummary, setRateCardSummary] = useState<RateCardSummary>();
  const { supplierId } = useParams();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    if (supplierId) {
      const supplier = await ApiService.supplier.getSupplier(supplierId);
      if (supplier) {
        setSupplier(supplier);
      }
    } else {
      AlertService.error("Wrong supplierId");
    }
  }, [supplierId]);

  const fetchRateCardSummary = useCallback(async () => {
    if (supplierId) {
      const rateCardSummary = await ApiService.rateCard.getRateCardSummary(
        supplierId
      );
      if (rateCardSummary) {
        setRateCardSummary(rateCardSummary);
      }
    } else {
      AlertService.error("Wrong supplierId");
    }
  }, [supplierId]);

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      handleError(error);
    }
  }, [fetchData]);

  useEffect(() => {
    try {
      fetchRateCardSummary();
    } catch (error) {
      handleError(error);
    }
  }, [fetchRateCardSummary]);

  const deleteSupplier = async (supplierId: string) => {
    try {
      await ApiService.supplier.deleteSupplier(supplierId);
      AlertService.success("Successfully deleted supplier");
      await navigate(SUPPLIER_PATH);
    } catch (error) {
      AlertService.error("Error deleting supplier");
    }
  };

  const updateSupplier = async (payload: UpdateSupplierPayload) => {
    try {
      await ApiService.supplier.updateSupplier(payload);
      AlertService.success("Successfully updated supplier");
      fetchData();
    } catch (error) {
      AlertService.error("Error deleting supplier");
    }
  };

  const deleteSupplierAddress = async (
    supplierId: string,
    addressId: string
  ) => {
    try {
      await ApiService.supplier.deleteSupplierAddress(supplierId, addressId);
      AlertService.success("Successfully deleted address");
      fetchData();
    } catch (error) {
      AlertService.error("Error deleting address");
    }
  };

  const deleteSupplierBusinessContact = async (
    supplierId: string,
    businessContactId: string
  ) => {
    try {
      await ApiService.supplier.deleteSupplierBusinessContact(
        supplierId,
        businessContactId
      );
      AlertService.success("Successfully deleted contact");
      fetchData();
    } catch (error) {
      AlertService.error("Error deleting contact");
    }
  };

  const deleteSupplierNote = async (supplierId: string, noteId: string) => {
    try {
      await ApiService.supplier.deleteSupplierNote(supplierId, noteId);
      AlertService.success("Successfully deleted note");
      fetchData();
    } catch (error) {
      AlertService.error("Error deleting note");
    }
  };

  const uploadSupplierLogo = async (supplierId: string, file: File) => {
    try {
      await ApiService.supplier.createSupplierLogo(supplierId, file);
      AlertService.success("Successfully uploaded supplier logo");
    } catch (error) {
      AlertService.error("Error uploading supplier logo");
    }
  };

  const deleteSupplierLogo = async (supplierId: string) => {
    try {
      await ApiService.supplier.deleteSupplierLogo(supplierId);
      AlertService.success("Successfully deleted supplier logo");
    } catch (error) {
      AlertService.error("Error deleting supplier logo");
    }
  };

  const setSupplierAsABenchmark = async (supplierId: string) => {
    try {
      await ApiService.supplier.benchmark.setSupplierAsABenchmark(supplierId);
      AlertService.success("Successfully set supplier as a benchmark");
    } catch (error) {
      AlertService.error("Error setting supplier as a benchmark");
    }
  };

  return (
    <HomeContainer functionHeaderType="search-bar">
      {supplier && !IsObjectEmpty(supplier) ? (
        <SupplierDetailsComponent
          supplier={supplier}
          updateSupplier={updateSupplier}
          rateCardSummary={rateCardSummary}
          deleteSupplier={deleteSupplier}
          deleteSupplierAddress={deleteSupplierAddress}
          deleteSupplierBusinessContact={deleteSupplierBusinessContact}
          deleteSupplierNote={deleteSupplierNote}
          uploadSupplierLogo={uploadSupplierLogo}
          deleteSupplierLogo={deleteSupplierLogo}
          setSupplierAsABenchmark={setSupplierAsABenchmark}
          fetchData={fetchData}
        ></SupplierDetailsComponent>
      ) : (
        <CircleLoader></CircleLoader>
      )}
    </HomeContainer>
  );
}

export default SupplierPage;

function IsObjectEmpty(obj: object): boolean {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
