import React from "react";
import { AppBar, Toolbar, Box, Container, Link, useTheme } from "@mui/material";

function BrandHeader() {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.palette.background.default }}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box component="span" className="me-3">
            <Link href="/" underline="none">
              <img src="/srm-tool-logo.png" height="25" alt="SRM Tool logo" />
            </Link>
          </Box>
          <Box flexGrow={1}></Box>
          <Box component="span">
            <Link href="/" underline="none">
              <img src="/helaba-logo.png" height="20" alt="Helaba logo" />
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default BrandHeader;
