import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";
import { ListResponse } from "../../../type/ListResponse";
import { EclassSubgroup } from "../../../type/EclassSubgroup";

export const getEclassSubgroups = async (): Promise<
  ListResponse<EclassSubgroup>
> => {
  try {
    const response = await apiClient.get<ListResponse<EclassSubgroup>>(
      `${ECLASS_URL}/subgroups`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
