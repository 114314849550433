import apiClient from "../api.client";
import { Skill } from "../../../type/Skill";
import { RATE_URL } from "../api.const";

export interface UpdateRatePayload {
  tags: string[];
  eclassValueId: string;
  skills: Skill[];
}

export const updateRate = async (
  supplierId: string,
  rateCardId: string,
  rateId: string,
  updateRatePayload: UpdateRatePayload
) => {
  try {
    await apiClient.put(
      `${RATE_URL(supplierId, rateCardId)}/${rateId}`,
      updateRatePayload
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
