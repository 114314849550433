import { ClassificationEnum } from "../enum/ClassificationEnum";

export const mapClassificationEnumToNumber = (
  classification: ClassificationEnum
): number => {
  switch (classification) {
    case ClassificationEnum.BRONZE:
      return 1;
    case ClassificationEnum.SILVER:
      return 2;
    case ClassificationEnum.GOLD:
      return 3;
  }
};
