import apiClient from "../api.client";
import { AUTH_URL } from "../api.const";

export interface ResetPasswordPayload {
  password: string;
  token: string;
}

export const resetPassword = async (
  resetPasswordPayload: ResetPasswordPayload
) => {
  try {
    await apiClient.post(`${AUTH_URL}/reset-password`, resetPasswordPayload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
