import { Rating } from "@mui/material";
import React from "react";
import { Diamond, DiamondOutlined } from "@mui/icons-material";

type ClassificationProps = {
  classificationValue?: number;
};

const MIN_VALUE = 1;
const MAX_VALUE = 3;
const DEFAULT_VALUE = 1;
function DiamondRating({ classificationValue }: ClassificationProps) {
  const value: any = classificationValue && Number(classificationValue);
  const intValue: number =
    value && value >= MIN_VALUE && value <= MAX_VALUE
      ? parseInt(value)
      : DEFAULT_VALUE;
  return (
    <Rating
      name="diamond-rating"
      icon={<Diamond />}
      value={intValue}
      readOnly
      max={3}
      emptyIcon={<DiamondOutlined />}
    />
  );
}

export default DiamondRating;
