import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { CurrencyEnum } from "../../shared/enum/CurrencyEnum";
import { Tooltip } from "@mui/material";
import BenchmarkDataVisibleContext from "../benchmark-switch/BenchmarkDataVisibleContext";

type RateTextBoxProps = {
  value?: string | number;
  currency?: CurrencyEnum;
  leftPercentChange?: number;
  rightPercentChange?: number;
  tooltip?: string | null;
  benchmarkSupplierName?: string | null;
};

function RateTextBox({
  value,
  currency = CurrencyEnum.EUR,
  leftPercentChange,
  rightPercentChange,
  tooltip,
  benchmarkSupplierName = null,
}: RateTextBoxProps) {
  const { benchmarkDataVisible } = useContext(BenchmarkDataVisibleContext);

  return (
    <Typography variant="body1" component="div">
      <Tooltip title={tooltip}>
        <Typography>
          {value || value === 0
            ? `${Number(value).toFixed(2)} ${currency}`
            : "n/a"}
        </Typography>
      </Tooltip>

      {benchmarkDataVisible && (
        <Stack direction="row" spacing={2}>
          {(leftPercentChange || leftPercentChange === 0) && (
            <Tooltip title="Tagessatz vs. Helaba Durchschnitstagessatz">
              <Typography
                color={
                  leftPercentChange >= 0 ? "rateCard.dark" : "rateCard.main"
                }
              >
                {leftPercentChange}%
              </Typography>
            </Tooltip>
          )}
          {(rightPercentChange || rightPercentChange === 0) && (
            <Tooltip
              title={`Tagessatz vs. Benchmark Tagessatz (${benchmarkSupplierName})`}
            >
              <Typography
                color={
                  rightPercentChange >= 0 ? "rateCard.dark" : "rateCard.main"
                }
              >
                {rightPercentChange}%
              </Typography>
            </Tooltip>
          )}
        </Stack>
      )}
    </Typography>
  );
}

export default RateTextBox;
