import { useEffect, useState } from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import { Box, Button, Typography } from "@mui/material";
import SupplierStatusTable from "../../../feature/supplier-status/SupplierStatusTable";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { SupplierStatus } from "../../../shared/type/SupplierStatus";
import { CreateSupplierStatusDialog } from "../../../feature/supplier-status/CreateSupplierStatusDialog";
import { handleError } from "../../../shared/helper/handle-error";

function SupplierStatusManagementPage() {
  const [supplierStatuses, setSupplierStatuses] = useState<SupplierStatus[]>(
    []
  );

  const fetchData = async () => {
    try {
      const supplierStatusList =
        await ApiService.supplierStatus.getSupplierStatuses();
      if (supplierStatusList) {
        setSupplierStatuses(supplierStatusList.data);
      }
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const updateSupplierStatus = async (
    updatedSupplierStatus: SupplierStatus
  ) => {
    try {
      await ApiService.supplierStatus.updateSupplierStatus(
        updatedSupplierStatus.id,
        {
          statusName: updatedSupplierStatus.statusName,
        }
      );
      AlertService.success("Supplier status updated successfully!");
      await fetchData();
    } catch (error) {
      AlertService.error("Error updating supplier status");
    }
  };

  const deleteSupplierStatus = async (supplierStatusId: string) => {
    try {
      await ApiService.supplierStatus.deleteSupplierStatus(supplierStatusId);
      AlertService.success("Supplier status deleted successfully!");
      await fetchData();
    } catch (error) {
      AlertService.error("Error deleting supplier status");
    }
  };
  // CREATE DIALOG
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreateSupplierStatus = () => {
    setCreateDialogOpen(true);
  };

  const handleConfirmCreate = async (statusName: string) => {
    try {
      if (statusName.trim() !== "") {
        await ApiService.supplierStatus.createSupplierStatus({ statusName });
        AlertService.success("Supplier status created successfully!");
        await fetchData();
      }
      setCreateDialogOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancelCreate = () => {
    setCreateDialogOpen(false);
  };

  return (
    <HomeContainer>
      <Box display="flex" alignItems="center" marginBottom={3}>
        <Typography component="h1" variant="h4" sx={{ flexGrow: 1 }}>
          Supplier Statuses Management
        </Typography>
        <Button variant="outlined" onClick={handleCreateSupplierStatus}>
          Create supplier status
        </Button>
      </Box>
      <CreateSupplierStatusDialog
        isOpen={isCreateDialogOpen}
        onClose={handleCancelCreate}
        onCreate={handleConfirmCreate}
      />
      <SupplierStatusTable
        supplierStatusList={supplierStatuses}
        updateSupplierStatus={updateSupplierStatus}
        deleteSupplierStatus={deleteSupplierStatus}
      ></SupplierStatusTable>
    </HomeContainer>
  );
}

export default SupplierStatusManagementPage;
