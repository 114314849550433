import apiClient from "../api.client";
import { AUTH_URL } from "../api.const";

export interface LoginUserPayload {
  email: string;
  password: string;
}

export interface LoginUserResponse {
  userId: string;
}

export const loginUser = async (loginUserPayload: LoginUserPayload) => {
  try {
    const response = await apiClient.post<LoginUserResponse>(
      `${AUTH_URL}/login`,
      loginUserPayload
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message || "Unknown error";
  }
};
