import { SUPPLIER_NOTE_URL } from "../api.const";
import apiClient from "../api.client";
import { Note } from "../../../type/Note";

export const createSupplierNote = async (
  supplierId: string,
  createSupplierNotePayload: Omit<Note, "id">
): Promise<void> => {
  try {
    await apiClient.post(
      SUPPLIER_NOTE_URL(supplierId),
      createSupplierNotePayload
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
