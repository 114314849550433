import React, { useCallback, useEffect, useState } from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../../shared/service/api/api.service";
import { handleError } from "../../../shared/helper/handle-error";
import {
  RateCardForm,
  RateCardFormValues,
} from "../../../feature/rate-card/RateCardForm";
import { format } from "date-fns";
import { UpdateRateCardPayload } from "../../../shared/service/api/ratecard/update-rate-card";
import { supplierRateCardPath } from "../../../shared/const/path.const";
import { CurrencyEnum } from "../../../shared/enum/CurrencyEnum";
import AlertService from "../../../shared/service/alert/alert.service";
import { FieldNamesMarkedBoolean } from "react-hook-form";
import { Skill } from "../../../shared/type/Skill";
import { Rate } from "../../../shared/type/Rate";
import { convertHashtag } from "./convert-hashtag";

const RateCardEditingPage: React.FC = () => {
  const navigate = useNavigate();
  const { supplierId, rateCardId } = useParams();
  const [rateCard, setRateCard] = useState<RateCardFormValues>();

  const fetchData = useCallback(async () => {
    try {
      const rateCard = await ApiService.rateCard.getRateCardById(
        supplierId || "",
        rateCardId || ""
      );

      if (rateCard) {
        const rateCardFormValues: RateCardFormValues = {
          eclassSubSubgroup: rateCard.eclassSubSubgroup.id,
          supplierStatusId: rateCard.supplierStatus?.id,
          location: rateCard.locationCategory,
          locationTag: rateCard.location,
          startDate: format(new Date(rateCard.startDate), "yyyy-MM-dd"),
          endDate: rateCard.dueDate
            ? format(new Date(rateCard.dueDate), "yyyy-MM-dd")
            : undefined,
          discount: rateCard.discount ?? false,
          travelCost: rateCard.travelCost,
          services: rateCard.rates.map((r) => ({
            rateId: r.id,
            service: r.eclassValue.id,
            tag: r.tags.map((t) => `#${t.name}`).join(" "),
            skillLevels: Array.from(Array(6).keys()).map(
              (index) =>
                r.skills.find((s) => s.level === index + 1)?.price.toString() ??
                ""
            ),
          })),
        };
        setRateCard(rateCardFormValues);
      }
    } catch (error) {
      console.log(error);
      handleError(error);
    }
  }, [supplierId, rateCardId]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditRateCard = useCallback(
    async (
      data: RateCardFormValues,
      dirtyFields?: FieldNamesMarkedBoolean<RateCardFormValues>
    ) => {
      // TODO missing rate editing
      const payload: UpdateRateCardPayload = {
        currency: CurrencyEnum.EUR,
        dueDate: data.endDate ? new Date(data.endDate) : undefined,
        startDate: new Date(data.startDate),
        locationCategory: data.location,
        location: data.locationTag,
        discount: data.discount,
        travelCost: data.travelCost === "" ? null : data.travelCost,
        supplierStatusId: data.supplierStatusId,
      };
      try {
        if (!supplierId) {
          return AlertService.error("Lieferanten-ID ist erforderlich");
        }
        if (!rateCardId) {
          return AlertService.error("RateCard-ID ist erforderlich");
        }
        await ApiService.rateCard.updateRateCard(
          supplierId,
          rateCardId,
          payload
        );

        if (dirtyFields?.services?.length) {
          await Promise.all(
            dirtyFields.services.map((s, index) => {
              const item = data.services[index];

              const skills = item.skillLevels
                .map((price, index) => {
                  const skill: Skill = {
                    level: index + 1,
                    price: Number(price),
                  };
                  return skill;
                })
                .filter((s) => s.price);

              const rate: Rate = {
                skills,
                tags: convertHashtag(item.tag),
                eclassValueId: item.service,
              };

              if (item.rateId) {
                return ApiService.rateCard.rate.updateRate(
                  supplierId,
                  rateCardId,
                  item.rateId,
                  rate
                );
              } else {
                return ApiService.rateCard.rate.addRate(
                  supplierId,
                  rateCardId,
                  rate
                );
              }
            })
          );
        }

        const serviceIds = data.services
          .filter((s) => s.rateId)
          .map((s) => s.rateId);

        const removedServices =
          rateCard?.services
            .map((s) => s.rateId)
            .filter((rateId) => !serviceIds.includes(rateId)) ?? [];

        await Promise.all(
          removedServices.map(
            (rateId) =>
              rateId &&
              ApiService.rateCard.rate.deleteRate(
                supplierId,
                rateCardId,
                rateId
              )
          )
        );

        AlertService.success("Ratecard erfolgreich erstellt!");
        navigate(supplierRateCardPath(supplierId));
      } catch (error) {
        handleError(error);
      }
    },
    [supplierId, rateCardId, rateCard?.services, navigate]
  );

  return (
    <HomeContainer>
      <RateCardForm
        values={rateCard}
        supplierId={supplierId || ""}
        handleSubmitForm={handleEditRateCard}
        editMode
      />
    </HomeContainer>
  );
};

export default RateCardEditingPage;
