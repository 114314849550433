import apiClient from "../api.client";
import { SUPPLIER_STATUS_URL } from "../api.const";
import { SupplierStatus } from "../../../type/SupplierStatus";
import { ListResponse } from "../../../type/ListResponse";

export const getSupplierStatuses = async () => {
  try {
    const response = await apiClient.get<ListResponse<SupplierStatus>>(
      SUPPLIER_STATUS_URL
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
