import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";

export interface CreateEclassSubgroupPayload {
  name: string;
  mainGroupName: string;
}

export const createEclassSubgroup = async (
  payload: CreateEclassSubgroupPayload
): Promise<void> => {
  try {
    await apiClient.post(`${ECLASS_URL}/subgroups`, payload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
