import { SUPPLIER_SEARCH_URL } from "../api.const";
import apiClient from "../api.client";
import { SupplierSearchResultResponse } from "../../../type/SupplierSearchResultResponse";
import { DeliveryModelEnum } from "../../../enum/DeliveryModelEnum";
import { SupplierTypeEnum } from "../../../enum/SupplierTypeEnum";
import { LocationCategoryEnum } from "../../../enum/LocationCategoryEnum";
import { ClassificationEnum } from "../../../enum/ClassificationEnum";

export interface SearchSupplierPayload {
  query: string;
  rating?: number;
  skillLevels?: number[];
  supplierType?: SupplierTypeEnum;
  supplierDeliveryModel?: DeliveryModelEnum;
  supplierClassification?: ClassificationEnum;
  locationCategories?: LocationCategoryEnum[];
  supplierStatusId?: string;
}

export const searchSupplier = async (
  payload: SearchSupplierPayload
): Promise<SupplierSearchResultResponse> => {
  try {
    const response = await apiClient.get<SupplierSearchResultResponse>(
      SUPPLIER_SEARCH_URL,
      {
        params: {
          text: payload.query,
          rating: payload.rating,
          skillLevels: payload.skillLevels,
          supplierType: payload.supplierType,
          supplierDeliveryModel: payload.supplierDeliveryModel,
          supplierClassification: payload.supplierClassification,
          locationCategories: payload.locationCategories,
          supplierStatusId: payload.supplierStatusId,
        },
      }
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
