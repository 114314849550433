import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { TextInput } from "../inputs/TextInput";
import { Address } from "../../shared/type/Address";

type CreateSupplierAddressDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (payload: Omit<Address, "id">) => void;
};
export function CreateSupplierAddressDialog({
  isOpen,
  onClose,
  onCreate,
}: CreateSupplierAddressDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Omit<Address, "id">>();

  const onSubmit = async (data: Omit<Address, "id">) => {
    await onCreate(data);
    reset();
  };

  const handleCancelCreate = () => {
    onClose();
    reset();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelCreate}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Standort</DialogTitle>
        <DialogContent>
          <TextInput
            control={control}
            errors={errors}
            fieldName="line1"
            label="Adresse 1"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="line2"
            label="Adresse 2"
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="zipCode"
            label="PLZ"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="city"
            label="Stadt"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="state"
            label="Staat"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="country"
            label="Land"
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCreate} size="large">
            Abbrechen
          </Button>
          <Button type="submit" size="large">
            Bewerten
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
