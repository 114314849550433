import RateTextBox from "../../components/text-box/RateTextBox";
import React from "react";
import { calcPercentage } from "../../shared/helper/calc-percentage";

type SkillLevelProps = {
  price: string;
  benchmarkAvg?: string;
  allAvg?: string;
  tooltip?: string | null;
  benchmarkSupplierName?: string | null;
};

export function SkillLevelBox({
  price,
  benchmarkAvg,
  allAvg,
  tooltip,
  benchmarkSupplierName,
}: SkillLevelProps) {
  let benchmarkPercentageChange;
  let allPercentageChange;
  if (benchmarkAvg) {
    benchmarkPercentageChange = Number(
      calcPercentage(Number(price), Number(benchmarkAvg))
    );
  }
  if (allAvg) {
    allPercentageChange = Number(calcPercentage(Number(price), Number(allAvg)));
  }

  return (
    <div>
      <RateTextBox
        value={price}
        leftPercentChange={allPercentageChange}
        rightPercentChange={benchmarkPercentageChange}
        tooltip={tooltip}
        benchmarkSupplierName={benchmarkSupplierName}
      />
    </div>
  );
}
