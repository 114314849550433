import apiClient from "../api.client";
import { SUPPLIER_URL } from "../api.const";
import { Supplier } from "../../../type/Supplier";
import { ListResponse } from "../../../type/ListResponse";

export const getSupplierList = async () => {
  try {
    const response = await apiClient.get<ListResponse<Supplier>>(SUPPLIER_URL);
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
