import React from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ApiService from "../../shared/service/api/api.service";
import {
  ADMIN_PATH,
  LOGIN_PATH,
  SUPPLIER_PATH,
} from "../../shared/const/path.const";
import AlertService from "../../shared/service/alert/alert.service";
import { Settings } from "@mui/icons-material";
import BenchmarkDataVisibleSwitch from "../benchmark-switch/BenchmarkDataVisibleSwitch";

function NavHeader() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navItems = [
    // FIXME: Uncomment when home charts work
    // { name: "Home", to: "home" },
    { name: "Lieferant", to: SUPPLIER_PATH },
    { name: "Admin", to: ADMIN_PATH },
    {
      name: "Logout",
      to: LOGIN_PATH,
      onClick: async () => {
        await ApiService.logoutUser();
        AlertService.success("Successfully signed out!");
      },
    },
  ];

  return (
    <AppBar position="static" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }} />
          {navItems.map((item) => (
            <Button
              key={item.name}
              component={RouterLink}
              to={item.to ?? ""}
              onClick={item.onClick}
              color="inherit"
              sx={{
                textTransform: "uppercase",
                mx: 1,
              }}
            >
              {item.name}
            </Button>
          ))}
          <IconButton size="large" color="inherit" onClick={handleClick}>
            <Settings />
          </IconButton>
          <Menu
            id="settings-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem>
              <BenchmarkDataVisibleSwitch />
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavHeader;
