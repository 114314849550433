import apiClient from "../api.client";
import { RATE_CARD_URL } from "../api.const";
import { ListResponse } from "../../../type/ListResponse";
import { RateCardResponse } from "../../../type/RateCardResponse";
import { LocationCategoryEnum } from "../../../enum/LocationCategoryEnum";

export interface GetRateCardsQuery {
  locationCategories?: LocationCategoryEnum[];
}

export const getRateCards = async (
  supplierId: string,
  locationCategories: LocationCategoryEnum[]
) => {
  const params: GetRateCardsQuery = {};

  if (locationCategories.length > 0) {
    params.locationCategories = locationCategories;
  }

  try {
    const response = await apiClient.get<ListResponse<RateCardResponse>>(
      `${RATE_CARD_URL(supplierId)}`,
      { params }
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
