import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { useNavigate, useParams } from "react-router-dom";
import { RateCardResponse } from "../../../shared/type/RateCardResponse";
import { handleError } from "../../../shared/helper/handle-error";
import { AddBox, FileDownload, FileUpload, Stars } from "@mui/icons-material";
import DetailsCard from "../../../components/card/DetailsCard";
import DetailsCardItemWithTwoColumn from "../../../components/card/DetailsCardItemWithTwoColumn";
import RateTextBox from "../../../components/text-box/RateTextBox";
import { RateCardAccordion } from "../../../feature/rate-card/RateCardAccordion";
import { LocationCategoryEnum } from "../../../shared/enum/LocationCategoryEnum";
import {
  addRateCardPath,
  importRateCardPath,
} from "../../../shared/const/path.const";
import { RateCardSummary } from "../../../shared/type/RateCardSummary";
import { capitalizeFirstLetter } from "../../../shared/helper/capitalize-first-letter";
import TuneIcon from "@mui/icons-material/Tune";
import CircleLoader from "../../../components/loader/CircleLoader";
import { SupplierDetails } from "../../../shared/type/SupplierDetails";
import BenchmarkDataVisibleContext from "../../../components/benchmark-switch/BenchmarkDataVisibleContext";

function RateCardManagementPage() {
  const [rateCards, setRateCards] = useState<RateCardResponse[] | undefined>();
  const [rateCardSummary, setRateCardSummary] = useState<RateCardSummary>();
  const [supplier, setSupplier] = useState<SupplierDetails>();
  const { benchmarkDataVisible } = useContext(BenchmarkDataVisibleContext);

  const { supplierId } = useParams();
  const navigate = useNavigate();
  const [checkboxState, setCheckboxState] = useState({
    ONSITE: false,
    OFFSITE: false,
    NEARSHORE: false,
    OFFSHORE: false,
  });

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setRateCards(undefined);
    setCheckboxState((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      };
    });
  };

  const fetchSupplier = useCallback(async () => {
    if (supplierId) {
      const supplier = await ApiService.supplier.getSupplier(supplierId);
      if (supplier) {
        setSupplier(supplier);
      }
    } else {
      AlertService.error("Wrong rateCardId or supplierId");
    }
  }, [supplierId]);

  const fetchRateCardSummary = useCallback(async () => {
    if (supplierId) {
      const rateCardSummary = await ApiService.rateCard.getRateCardSummary(
        supplierId
      );
      if (rateCardSummary) {
        setRateCardSummary(rateCardSummary);
      }
    } else {
      AlertService.error("Wrong rateCardId or supplierId");
    }
  }, [supplierId]);

  const fetchRateCardResponse = useCallback(
    async (currentCheckboxState: {
      ONSITE: boolean;
      OFFSITE: boolean;
      NEARSHORE: boolean;
      OFFSHORE: boolean;
    }) => {
      if (supplierId) {
        const locationCategories = mapLocationCategories(currentCheckboxState);
        const rateCardResponse = await ApiService.rateCard.getRateCards(
          supplierId,
          locationCategories
        );
        if (rateCardResponse) {
          setRateCards(rateCardResponse.data);
        }
      } else {
        AlertService.error("Wrong rateCardId or supplierId");
      }
    },
    [supplierId]
  );

  useEffect(() => {
    try {
      fetchRateCardResponse(checkboxState);
    } catch (error) {
      handleError(error);
    }
  }, [fetchRateCardResponse, checkboxState]);

  useEffect(() => {
    try {
      fetchRateCardSummary();
      fetchSupplier();
    } catch (error) {
      handleError(error);
    }
  }, [supplierId]);

  const mapLocationCategories = (categories: {
    [key in LocationCategoryEnum]: boolean;
  }): LocationCategoryEnum[] => {
    return Object.entries(categories)
      .filter(([category, flag]) => flag)
      .map(([category]) => category as LocationCategoryEnum);
  };

  const handleExportRateCard = async () => {
    try {
      if (supplierId) {
        await ApiService.rateCard.exportRateCard(supplierId);
        await fetchRateCardResponse(checkboxState);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <HomeContainer>
      <Box display="flex" alignItems="center" marginBottom={3}>
        {supplierId && supplier && (
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Typography variant="h4" sx={{ marginRight: "15px" }}>
              {supplier.companyName}
            </Typography>
            <Typography variant="body1">
              <Link href={supplier.websiteUrl} sx={{ color: "inherit" }}>
                {supplier.websiteUrl}
              </Link>
            </Typography>
          </Box>
        )}

        {supplierId && (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={() => navigate(addRateCardPath(supplierId))}
            >
              <AddBox style={{ marginRight: "12px" }}></AddBox> Ratecard
              hinzufügen
            </Button>
            <Button variant="outlined" onClick={handleExportRateCard}>
              <FileDownload style={{ marginRight: "5px" }}></FileDownload>
              Ratecard exportieren
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate(importRateCardPath(supplierId))}
            >
              <FileUpload style={{ marginRight: "5px" }}></FileUpload> Ratecard
              importieren
            </Button>
          </Stack>
        )}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DetailsCard cardTitle="Ratecard" cardIcon={<Stars></Stars>}>
            <DetailsCardItemWithTwoColumn
              firstCardItemTitle="Tagessatz"
              secondCardItemTitle="Anzahl Ratecards"
            >
              <RateTextBox
                value={rateCardSummary?.supplierAvg ?? undefined}
              ></RateTextBox>
              <div>{rateCardSummary?.rateCardCount}</div>
            </DetailsCardItemWithTwoColumn>
            <DetailsCardItemWithTwoColumn
              firstCardItemTitle="Haleba Tagessatz"
              secondCardItemTitle="Standort"
              showDivider={benchmarkDataVisible}
            >
              <RateTextBox
                value={rateCardSummary?.globalAvg ?? undefined}
              ></RateTextBox>
              <div>
                {rateCardSummary?.locationCategories
                  .map((category) => capitalizeFirstLetter(category))
                  .join(", ")}
              </div>
            </DetailsCardItemWithTwoColumn>
            {benchmarkDataVisible && (
              <DetailsCardItemWithTwoColumn
                firstCardItemTitle="Benchmark"
                showDivider={false}
              >
                <RateTextBox
                  value={rateCardSummary?.benchmarkAvg ?? undefined}
                ></RateTextBox>
              </DetailsCardItemWithTwoColumn>
            )}
          </DetailsCard>
        </Grid>
        {/*<Grid item xs={12} md={4}>*/}
        {/*  <DetailsCard*/}
        {/*    cardTitle="Info"*/}
        {/*    cardIcon={*/}
        {/*      <IconButton>*/}
        {/*        <Info></Info>*/}
        {/*      </IconButton>*/}
        {/*    }*/}
        {/*  >*/}
        {/*    <DetailsCardItem titleWidth={4} cardItemTitle="Leiferantenstatus">*/}
        {/*      Leiferantenstatus*/}
        {/*    </DetailsCardItem>*/}
        {/*    <DetailsCardItem titleWidth={4} cardItemTitle="Leiferantentype">*/}
        {/*      Leiferantenstatus*/}
        {/*    </DetailsCardItem>*/}
        {/*    <DetailsCardItem titleWidth={4} cardItemTitle="Standort">*/}
        {/*      Leiferantenstatus*/}
        {/*    </DetailsCardItem>*/}
        {/*    /!* <DetailsCardItem titleWidth={4} cardItemTitle="Rating">*/}
        {/*      <Rating value={3}></Rating>*/}
        {/*    </DetailsCardItem> *!/*/}
        {/*  </DetailsCard>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <Card>
            <CardContent
              sx={{
                "&:last-child": { pb: 2 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <TuneIcon sx={{ mr: 3 }} />
              {Object.values(LocationCategoryEnum).map((category) => (
                <FormControlLabel
                  key={category}
                  control={
                    <Checkbox
                      checked={checkboxState[category]}
                      onChange={handleCheckboxChange}
                      name={category}
                    />
                  }
                  label={
                    <Typography>{capitalizeFirstLetter(category)}</Typography>
                  }
                />
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {Array.isArray(rateCards) ? (
            rateCards.map((rateCard, key) => {
              return (
                <RateCardAccordion
                  rateCard={rateCard}
                  key={key}
                  supplierId={supplierId || ""}
                  benchmarkSupplierName={rateCardSummary?.benchmarkSupplierName}
                ></RateCardAccordion>
              );
            })
          ) : (
            <CircleLoader />
          )}
        </Grid>
        {rateCards && rateCards.length === 0 && (
          <Grid item xs={12}>
            <Typography align="center">
              Keine Elemente zum Anzeigen vorhanden
            </Typography>
          </Grid>
        )}
      </Grid>
    </HomeContainer>
  );
}

export default RateCardManagementPage;
