import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";
import { ListResponse } from "../../../type/ListResponse";
import { Eclass } from "../../../type/Eclass";

export const getEclassValues = async (
  subSubgroupId?: string
): Promise<ListResponse<Eclass>> => {
  try {
    const params = new URLSearchParams();
    if (subSubgroupId) {
      params.append("subSubgroupId", subSubgroupId);
    }
    const response = await apiClient.get<ListResponse<Eclass>>(
      `${ECLASS_URL}/values?${params.toString()}`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
