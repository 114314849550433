import apiClient from "../api.client";
import { Skill } from "../../../type/Skill";
import { RATE_URL } from "../api.const";

export interface AddRatePayload {
  tags: string[];
  eclassValueId: string;
  skills: Skill[];
}

export const addRate = async (
  supplierId: string,
  rateCardId: string,
  addRatePayload: AddRatePayload
) => {
  try {
    await apiClient.post(RATE_URL(supplierId, rateCardId), addRatePayload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
