import {
  CssBaseline,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import Copyright from "../footer/Copyright";
function AuthContainer(props: any) {
  const { onSubmit, children } = props;

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Card sx={{ width: "100%", px: 5 }}>
          <CardMedia
            sx={{ height: 60, my: 3, backgroundSize: "contain" }}
            image="/srm-tool-logo.png"
            title="SRM Tool"
          />
          <CardContent>
            <Box component="form" onSubmit={onSubmit} noValidate>
              {children}
            </Box>
          </CardContent>
        </Card>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Box>
    </Container>
  );
}

export default AuthContainer;
