import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { TextInput } from "../inputs/TextInput";
import { UpsertSkillLevelsPayload } from "../../shared/service/api/eclass/upsert-skill-levels";
import ApiService from "../../shared/service/api/api.service";
import { handleError } from "../../shared/helper/handle-error";
import AlertService from "../../shared/service/alert/alert.service";

type CreateFieldDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (payload: UpsertSkillLevelsPayload) => void;
  eclassValueId: string;
};

type FormData = {
  descSkillLevel1?: string;
  descSkillLevel2?: string;
  descSkillLevel3?: string;
  descSkillLevel4?: string;
  descSkillLevel5?: string;
  descSkillLevel6?: string;
};
export function UpdateSkillLevelDialog({
  isOpen,
  onClose,
  onUpdate,
  eclassValueId,
}: CreateFieldDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const fetchData = async () => {
    try {
      if (eclassValueId) {
        const eclassValueDetails = await ApiService.eclass.getEclassValue(
          eclassValueId
        );
        if (eclassValueDetails && eclassValueDetails.skillLevels.length > 0) {
          const formData: FormData = {};
          eclassValueDetails.skillLevels.forEach((i) => {
            if (i.level === 1) {
              formData.descSkillLevel1 = i.description;
            }
            if (i.level === 2) {
              formData.descSkillLevel2 = i.description;
            }
            if (i.level === 3) {
              formData.descSkillLevel3 = i.description;
            }
            if (i.level === 4) {
              formData.descSkillLevel4 = i.description;
            }
            if (i.level === 5) {
              formData.descSkillLevel5 = i.description;
            }
            if (i.level === 6) {
              formData.descSkillLevel6 = i.description;
            }
          });
          reset(formData);
        }
      } else {
        AlertService.error("Wrong ID");
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [eclassValueId]);

  const onSubmit = (data: FormData) => {
    const payload: UpsertSkillLevelsPayload = {
      eclassValueId,
      skillLevels: [
        {
          level: 1,
          description: data.descSkillLevel1,
        },
        {
          level: 2,
          description: data.descSkillLevel2,
        },
        {
          level: 3,
          description: data.descSkillLevel3,
        },
        {
          level: 4,
          description: data.descSkillLevel4,
        },
        {
          level: 5,
          description: data.descSkillLevel5,
        },
        {
          level: 6,
          description: data.descSkillLevel6,
        },
      ],
    };
    onUpdate(payload);
  };

  const handleCancelUpdate = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelUpdate}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Upsert skill levels</DialogTitle>
        <DialogContent>
          <TextInput
            control={control}
            errors={errors}
            fieldName="descSkillLevel1"
            label="Description for Skill Level 1"
            required={false}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="descSkillLevel2"
            label="Description for Skill Level 2"
            required={false}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="descSkillLevel3"
            label="Description for Skill Level 3"
            required={false}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="descSkillLevel4"
            label="Description for Skill Level 4"
            required={false}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="descSkillLevel5"
            label="Description for Skill Level 5"
            required={false}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="descSkillLevel6"
            label="Description for Skill Level 6"
            required={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpdate} color="primary">
            Abbrechen
          </Button>
          <Button type="submit" color="primary">
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
