export const getApiUrl = () => {
  const { hostname, protocol } = window.location;
  let apiDomain: string;
  if (hostname.includes("srm-tool.")) {
    apiDomain = hostname.replace("tool.", "api.");
  } else {
    apiDomain = "localhost:8080";
  }

  return `${protocol}//${apiDomain}/api`;
};

export const BASE_URL = getApiUrl();
export const AUTH_URL = `/auth`;
export const BENCHMARK_URL = `/benchmark`;
export const TAG_URL = `/tag`;
export const SUPPLIER_STATUS_URL = `/supplier-status`;
export const USER_URL = `/user`;
export const ECLASS_URL = `/eclass`;
export const SUPPLIER_URL = `/supplier`;
export const SUPPLIER_SEARCH_URL = `${SUPPLIER_URL}/search`;
export const RATING_URL = `/rating`;

export const SINGLE_SUPPLIER_URL = (supplierId: string) =>
  `${SUPPLIER_URL}/${supplierId}`;
export const SUPPLIER_LOGO_URL = (supplierId: string) =>
  `${SINGLE_SUPPLIER_URL(supplierId)}/logo`;
export const SUPPLIER_RATING_URL = (supplierId: string) =>
  `${SUPPLIER_URL}/${supplierId}${RATING_URL}`;
export const SINGLE_RATING_URL = (ratingId: string) =>
  `${RATING_URL}/${ratingId}`;
export const RATE_CARD_URL = (supplierId: string) =>
  `${SINGLE_SUPPLIER_URL(supplierId)}/rate-card`;
export const RATE_URL = (supplierId: string, rateCardId: string) =>
  `${RATE_CARD_URL(supplierId)}/${rateCardId}/rate`;
export const EXPORT_RATE_URL = (supplierId: string) =>
  `${RATE_CARD_URL(supplierId)}/export`;
export const SUPPLIER_ADDRESS_URL = (supplierId: string, addressId = "") =>
  `${SINGLE_SUPPLIER_URL(supplierId)}/address/${addressId}`;
export const SUPPLIER_BUSINESS_CONTACT_URL = (
  supplierId: string,
  businessContactId = ""
) => `${SINGLE_SUPPLIER_URL(supplierId)}/business-contact/${businessContactId}`;
export const SUPPLIER_NOTE_URL = (supplierId: string, noteId = "") =>
  `${SINGLE_SUPPLIER_URL(supplierId)}/note/${noteId}`;
export const SET_SUPPLIER_AS_A_BENCHMARK = (supplierId: string) =>
  `${SINGLE_SUPPLIER_URL(supplierId)}/benchmark`;
