import React, { useEffect, useMemo, useState } from "react";
import FilterBar, {
  Filter,
  FilterOption,
} from "../../components/search/FilterBar";
import ApiService from "../../shared/service/api/api.service";
import { SupplierSearchResult } from "../../shared/type/SupplierSearchResult";
import { useSearchParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { Box, Rating } from "@mui/material";
import CircleLoader from "../../components/loader/CircleLoader";
import { SupplierSearchResults } from "../../feature/supplier/SupplierSearchResults";
import { DeliveryModelEnum } from "../../shared/enum/DeliveryModelEnum";
import { ClassificationEnum } from "../../shared/enum/ClassificationEnum";
import { LocationCategoryEnum } from "../../shared/enum/LocationCategoryEnum";
import { SearchSupplierPayload } from "../../shared/service/api/supplier/search-supplier";
import { handleError } from "../../shared/helper/handle-error";

const filters: Filter[] = [
  {
    filterName: "skillLevels",
    filterLabel: "Skill level",
    multiSelect: true,
    filterOptions: [
      { key: 1, label: "Skill Level 1" },
      { key: 2, label: "Skill Level 2" },
      { key: 3, label: "Skill Level 3" },
      { key: 4, label: "Skill Level 4" },
      { key: 5, label: "Skill Level 5" },
      { key: 6, label: "Skill Level 6" },
    ],
  },
  {
    filterName: "supplierDeliveryModel",
    filterLabel: "Erbringung",
    multiSelect: false,
    filterOptions: [
      {
        key: DeliveryModelEnum.T_M,
        label: "T&M",
      },
      {
        key: DeliveryModelEnum.ANU,
        label: "ANÜ",
      },
      {
        key: DeliveryModelEnum.T_M_ANU,
        label: "T&M/ANÜ",
      },
    ],
  },
  {
    filterName: "supplierClassification",
    filterLabel: "Classification",
    multiSelect: false,
    filterOptions: [
      {
        key: ClassificationEnum.BRONZE,
        label: "Bronze",
      },
      {
        key: ClassificationEnum.SILVER,
        label: "Silver",
      },
      {
        key: ClassificationEnum.GOLD,
        label: "Gold",
      },
    ],
  },
  {
    filterName: "locationCategories",
    filterLabel: "Standort",
    multiSelect: true,
    filterOptions: [
      {
        key: LocationCategoryEnum.NEARSHORE,
        label: "Nearshore",
      },
      {
        key: LocationCategoryEnum.OFFSHORE,
        label: "Offshore",
      },
      {
        key: LocationCategoryEnum.OFFSITE,
        label: "Offsite",
      },
      {
        key: LocationCategoryEnum.ONSITE,
        label: "Onsite",
      },
    ],
  },
  {
    filterName: "rating",
    filterLabel: "Rating",
    multiSelect: false,
    filterOptions: [
      { key: 1, label: <Rating name="read-only" value={1} readOnly /> },
      { key: 2, label: <Rating name="read-only" value={2} readOnly /> },
      { key: 3, label: <Rating name="read-only" value={3} readOnly /> },
      { key: 4, label: <Rating name="read-only" value={4} readOnly /> },
      { key: 5, label: <Rating name="read-only" value={5} readOnly /> },
    ],
  },
  {
    filterName: "supplierCompanyName",
    filterLabel: "Lieferant",
    multiSelect: true,
    filterOptions: [],
  },
];

function SearchResultPage() {
  let [searchParams] = useSearchParams();
  const searchText = searchParams.get("query") || "";

  const [filterValue, setFilterValue] = useState({});
  const [visibleSuppliers, setVisibleSuppliers] = useState<string[]>([]);
  const [payload, setPayload] = useState<SearchSupplierPayload>();
  const [results, setResults] = useState<SupplierSearchResult[]>();
  const [filteredResults, setFilteredResults] =
    useState<SupplierSearchResult[]>();

  const supplierCompanyFilter = filters.find(
    (f) => f.filterName === "supplierCompanyName"
  );

  const fetchSupplierStatuses = async () => {
    try {
      const supplierStatusList =
        await ApiService.supplierStatus.getSupplierStatuses();

      if (supplierStatusList) {
        const filterOptions = supplierStatusList.data.map((item) => {
          const filterOption: FilterOption = {
            key: item.id,
            label: item.statusName,
          };
          return filterOption;
        });
        const filterLabel = "Lieferantenstatus";
        const supplierStatusFilter: Filter = {
          filterName: "supplierStatusId",
          filterLabel,
          filterOptions,
          multiSelect: false,
        };
        if (!filters.find((item) => item.filterLabel === filterLabel)) {
          filters.push(supplierStatusFilter);
          setFilterValue(filters);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchSupplierStatuses();
  }, []);

  useEffect(() => {
    if (!supplierCompanyFilter || !results) {
      return;
    }

    supplierCompanyFilter.filterOptions = results.map((r) => ({
      key: r.id,
      label: r.companyName,
    }));
  }, [results, supplierCompanyFilter]);

  const handleFilterChange = (filters: Record<string, string[]>) => {
    setFilterValue(filters);
  };

  const onTextOrFilterChange = async (data: any) => {
    const newPayload: SearchSupplierPayload = {
      query: data.searchText,
      rating: data.filterValue.rating,
      skillLevels: data.filterValue.skillLevels,
      supplierType: data.filterValue.supplierType,
      supplierDeliveryModel: data.filterValue.supplierDeliveryModel,
      supplierClassification: data.filterValue.supplierClassification,
      locationCategories: data.filterValue.locationCategories,
      supplierStatusId: data.filterValue.supplierStatusId,
    };

    setVisibleSuppliers(data.filterValue.supplierCompanyName);
    setPayload(newPayload);
  };

  useEffect(() => {
    setResults(undefined);

    async function fetchData() {
      if (payload) {
        const results = await ApiService.supplier.searchSupplier(payload);
        if (results) {
          setResults(results.data);
        }
      }
    }
    fetchData();
  }, [payload]);

  const debouncedResults = useMemo(() => {
    return debounce(onTextOrFilterChange, 300);
  }, []);

  useEffect(() => {
    debouncedResults({ searchText, filterValue });
  }, [searchText, filterValue]);

  useEffect(() => {
    setFilteredResults(results);
  }, [results]);

  useEffect(() => {
    if (!visibleSuppliers || !visibleSuppliers?.length) {
      setFilteredResults(results);
      return;
    }
    setFilteredResults(results?.filter((r) => visibleSuppliers.includes(r.id)));
  }, [visibleSuppliers, results]);

  return (
    <>
      <Box mb={5}>
        <FilterBar
          filters={filters}
          onFilterChange={handleFilterChange}
        ></FilterBar>
      </Box>
      {Array.isArray(filteredResults) ? (
        <SupplierSearchResults searchResult={filteredResults} />
      ) : (
        <CircleLoader />
      )}
    </>
  );
}

export default SearchResultPage;
