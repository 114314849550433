import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { RateCardResponse } from "../../shared/type/RateCardResponse";
import "./RateCardAccordion.scss";
import { SkillLevelBox } from "./SkillLevelBox";
import { Create } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { editRateCardPath } from "../../shared/const/path.const";

type RateCardAccordionProps = {
  rateCard: RateCardResponse;
  supplierId: string;
  defaultExpandedAll?: boolean;
  benchmarkSupplierName?: string | null;
};

export function RateCardAccordion({
  rateCard,
  supplierId,
  defaultExpandedAll = false,
  benchmarkSupplierName,
}: RateCardAccordionProps) {
  const navigate = useNavigate();

  const locationChipText =
    rateCard.locationCategory +
    (rateCard.location && ` (#${rateCard.location})`);

  let dateChipText = new Date(rateCard.startDate).toLocaleDateString();
  if (rateCard.dueDate) {
    dateChipText += " - " + new Date(rateCard.dueDate).toLocaleDateString();
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={defaultExpandedAll}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography sx={{ flexGrow: 1 }} component="h2" variant="h5">
          {rateCard.eclassSubSubgroup.name}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Chip label={dateChipText} variant="outlined" />
          {rateCard.supplierStatus && (
            <Chip label={rateCard.supplierStatus.statusName} />
          )}
          <Chip label={locationChipText} />
          <IconButton
            onClick={() => navigate(editRateCardPath(supplierId, rateCard.id))}
          >
            <Create></Create>
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <table className="accordion-table">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Leistung</th>
              <th style={{ width: "10%" }}>Skill Level 1</th>
              <th style={{ width: "10%" }}>Skill Level 2</th>
              <th style={{ width: "10%" }}>Skill Level 3</th>
              <th style={{ width: "10%" }}>Skill Level 4</th>
              <th style={{ width: "10%" }}>Skill Level 5</th>
              <th style={{ width: "10%" }}>Skill Level 6</th>
              <th style={{ width: "10%" }}>Reisekosten</th>
            </tr>
          </thead>
          <tbody>
            {rateCard.rates.map((rate, key) => {
              const skillLevel1 = rate.skills.find(
                (skill) => skill.level === 1
              );
              const skillLevel2 = rate.skills.find(
                (skill) => skill.level === 2
              );
              const skillLevel3 = rate.skills.find(
                (skill) => skill.level === 3
              );
              const skillLevel4 = rate.skills.find(
                (skill) => skill.level === 4
              );
              const skillLevel5 = rate.skills.find(
                (skill) => skill.level === 5
              );
              const skillLevel6 = rate.skills.find(
                (skill) => skill.level === 6
              );
              return (
                <tr key={key}>
                  <td>
                    <Typography>{rate.eclassValue.name}</Typography>
                    <Typography variant="body2">
                      {rate.tags
                        .reduce((acc, tag) => acc + `#${tag.name} `, "")
                        .trim()}
                    </Typography>
                  </td>
                  <td>
                    {skillLevel1 ? (
                      <SkillLevelBox
                        price={skillLevel1.price}
                        allAvg={skillLevel1.stats?.avg}
                        benchmarkAvg={skillLevel1.benchmark?.avg}
                        tooltip={skillLevel1.description}
                        benchmarkSupplierName={benchmarkSupplierName}
                      ></SkillLevelBox>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {skillLevel2 ? (
                      <SkillLevelBox
                        price={skillLevel2.price}
                        allAvg={skillLevel2.stats?.avg}
                        benchmarkAvg={skillLevel2.benchmark?.avg}
                        tooltip={skillLevel2.description}
                        benchmarkSupplierName={benchmarkSupplierName}
                      ></SkillLevelBox>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {skillLevel3 ? (
                      <SkillLevelBox
                        price={skillLevel3.price}
                        allAvg={skillLevel3.stats?.avg}
                        benchmarkAvg={skillLevel3.benchmark?.avg}
                        tooltip={skillLevel3.description}
                        benchmarkSupplierName={benchmarkSupplierName}
                      ></SkillLevelBox>
                    ) : (
                      "-"
                    )}{" "}
                  </td>
                  <td>
                    {skillLevel4 ? (
                      <SkillLevelBox
                        price={skillLevel4.price}
                        allAvg={skillLevel4.stats?.avg}
                        benchmarkAvg={skillLevel4.benchmark?.avg}
                        tooltip={skillLevel4.description}
                        benchmarkSupplierName={benchmarkSupplierName}
                      ></SkillLevelBox>
                    ) : (
                      "-"
                    )}{" "}
                  </td>
                  <td>
                    {skillLevel5 ? (
                      <SkillLevelBox
                        price={skillLevel5.price}
                        allAvg={skillLevel5.stats?.avg}
                        benchmarkAvg={skillLevel5.benchmark?.avg}
                        tooltip={skillLevel5.description}
                        benchmarkSupplierName={benchmarkSupplierName}
                      ></SkillLevelBox>
                    ) : (
                      "-"
                    )}{" "}
                  </td>
                  <td>
                    {skillLevel6 ? (
                      <SkillLevelBox
                        price={skillLevel6.price}
                        allAvg={skillLevel6.stats?.avg}
                        benchmarkAvg={skillLevel6.benchmark?.avg}
                        tooltip={skillLevel6.description}
                        benchmarkSupplierName={benchmarkSupplierName}
                      ></SkillLevelBox>
                    ) : (
                      "-"
                    )}{" "}
                  </td>
                  <td>{rateCard.travelCost}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  );
}
