import apiClient from "../api.client";
import { TAG_URL } from "../api.const";

export interface UpdateTagPayload {
  name: string;
}

export const updateTag = async (
  tagId: string,
  updateTagPayload: UpdateTagPayload
) => {
  try {
    await apiClient.put(`${TAG_URL}/${tagId}`, updateTagPayload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
