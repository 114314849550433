import { Note as NoteIcon } from "@mui/icons-material";
import { useState } from "react";
import EditableDetailsCardWithPagination from "../../components/card/EditableDetailsCardWithPagination";
import { CreateSupplierNoteDialog } from "./CreateSupplierNoteDialog";
import { handleError } from "../../shared/helper/handle-error";
import AlertService from "../../shared/service/alert/alert.service";
import ApiService from "../../shared/service/api/api.service";
import { Note } from "../../shared/type/Note";
import { SupplierNote } from "./SupplierNote";

type SupplierNoteProps = {
  notes: Note[];
  supplierId: string;
  deleteSupplierNote: (supplierId: string, noteId: string) => void;
  fetchData: () => void;
};

export function SupplierNotes({
  notes,
  supplierId,
  deleteSupplierNote,
  fetchData,
}: SupplierNoteProps) {
  // FIXME: can it be improved to avoid extra renders?
  const [currentNoteId, setCurrentNoteId] = useState<string>("");
  const [showLatest, setShowLatest] = useState(false);

  const handleDelete = (id: string) => {
    deleteSupplierNote(supplierId, id);
  };

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  const handleConfirmCreate = async (payload: Omit<Note, "id">) => {
    try {
      await ApiService.supplier.createSupplierNote(supplierId, payload);
      AlertService.success("Supplier note created successfully!");
      await fetchData();
      setCreateDialogOpen(false);
      setShowLatest(true);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancelCreate = () => {
    setCreateDialogOpen(false);
  };

  return (
    <>
      <EditableDetailsCardWithPagination
        cardTitle="Notiz"
        cardIcon={<NoteIcon />}
        onClickCreate={handleCreate}
        onClickDelete={handleDelete}
        rowId={currentNoteId}
        numPages={notes.length}
        renderRow={(page: number) =>
          notes.slice(page - 1, page).map((note) => {
            setCurrentNoteId(note.id);
            return <SupplierNote note={note} />;
          })
        }
        showLatest={showLatest}
      />
      {isCreateDialogOpen && (
        <CreateSupplierNoteDialog
          isOpen={isCreateDialogOpen}
          onClose={handleCancelCreate}
          onCreate={handleConfirmCreate}
        />
      )}
    </>
  );
}
