import React, { useEffect, useState } from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import { Box, Button, Typography } from "@mui/material";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { EclassDetails } from "../../../shared/type/EclassDetails";
import EclassTable from "../../../feature/eclass/EclassTable";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../shared/helper/handle-error";
import { ADMIN_ECLASS_CREATE_PATH } from "../../../shared/const/path.const";
import { UpsertSkillLevelsPayload } from "../../../shared/service/api/eclass/upsert-skill-levels";

function EclassManagementPage() {
  const [eclasses, setEclasses] = useState<EclassDetails[]>([]);
  const navigate = useNavigate();

  const handleCreateService = () => {
    navigate(ADMIN_ECLASS_CREATE_PATH);
  };
  const fetchData = async () => {
    try {
      const eclassList = await ApiService.eclass.getEclassDetails();
      if (eclassList) {
        setEclasses(eclassList.data);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const upsertSkillLevel = async (payload: UpsertSkillLevelsPayload) => {
    try {
      await ApiService.eclass.upsertSkillLevels(payload);
      AlertService.success("Skill levels updated successfully!");
    } catch (error) {
      AlertService.error("Error updating skill levels");
    }
  };

  const deleteEclassValue = async (eclassValueId: string) => {
    try {
      await ApiService.eclass.deleteEclassValue(eclassValueId);
      AlertService.success("Eclass deleted successfully!");
      await fetchData();
    } catch (error) {
      AlertService.error("Error deleting eclass value");
    }
  };

  return (
    <HomeContainer>
      <Box display="flex" alignItems="center" marginBottom={3}>
        <Typography sx={{ flexGrow: 1 }} component="h1" variant="h4">
          Eclass Management
        </Typography>
        <Button variant="outlined" onClick={handleCreateService}>
          Create Eclass
        </Button>
      </Box>
      <EclassTable
        eclassList={eclasses}
        deleteEclassValue={deleteEclassValue}
        upsertSkillLevel={upsertSkillLevel}
      ></EclassTable>
    </HomeContainer>
  );
}

export default EclassManagementPage;
