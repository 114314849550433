import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_Virtualizer,
} from "material-react-table";
import { MenuItem } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { SupplierStatus } from "../../shared/type/SupplierStatus";
import { UpdateSupplierStatusDialog } from "./UpdateSupplierStatusDialog";
import { ConfirmDialog } from "../../components/dialog/ConfirmDialog";

const supplierStatusColumns: MRT_ColumnDef<SupplierStatus>[] = [
  {
    accessorKey: "statusName",
    header: "SupplierStatusname",
    size: 250,
  },
];

type SupplierStatusTableProps = {
  supplierStatusList: SupplierStatus[];
  updateSupplierStatus: (supplierStatus: SupplierStatus) => void;
  deleteSupplierStatus: (supplierStatusId: string) => void;
};
const SupplierStatusTable = ({
  supplierStatusList,
  updateSupplierStatus,
  deleteSupplierStatus,
}: SupplierStatusTableProps) => {
  const columns = useMemo<MRT_ColumnDef<SupplierStatus>[]>(
    () => supplierStatusColumns,
    []
  );
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [data, setData] = useState<SupplierStatus[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [selectedSupplierStatus, setSelectedSupplierStatus] =
    useState<SupplierStatus | null>(null);

  useEffect(() => {
    setData(supplierStatusList);
    setIsLoading(false);
  }, [supplierStatusList]);

  useEffect(() => {
    //scroll to the top of the supplier-table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  // DELETE DIALOG
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = (supplierStatusDto: SupplierStatus) => {
    setSelectedSupplierStatus(supplierStatusDto);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedSupplierStatus?.id) {
      deleteSupplierStatus(selectedSupplierStatus.id);
    }
    setDeleteDialogOpen(false);
    setSelectedSupplierStatus(null);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedSupplierStatus(null);
  };

  // UPDATE DIALOG
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);

  const handleUpdate = (supplierStatusDto: SupplierStatus) => {
    setSelectedSupplierStatus(supplierStatusDto);
    setUpdateDialogOpen(true);
  };

  const handleConfirmUpdate = (id: string, statusName: string) => {
    updateSupplierStatus({ id, statusName });
    setUpdateDialogOpen(false);
    setSelectedSupplierStatus(null);
  };

  const handleCancelUpdate = () => {
    setUpdateDialogOpen(false);
    setSelectedSupplierStatus(null);
  };

  return (
    <div className="customReactMaterialTable">
      <MaterialReactTable
        columns={columns}
        data={data}
        defaultDisplayColumn={{ enableResizing: true }}
        enableBottomToolbar={false}
        enableColumnVirtualization
        enableGlobalFilterModes
        enablePagination={false}
        enableHiding={false}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
        onSortingChange={setSorting}
        state={{ isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer
        enableRowActions
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              handleUpdate(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <Edit></Edit>
            Edit
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleDelete(row.original);
            }}
            sx={{ m: 0 }}
          >
            <Delete></Delete>
            Delete
          </MenuItem>,
        ]}
      />
      {selectedSupplierStatus?.id && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleCancelDelete}
          onAction={handleConfirmDelete}
          title="Delete element"
          content="Are you sure you want to delete?"
          buttonText="Delete"
          id={selectedSupplierStatus.id}
        />
      )}

      <UpdateSupplierStatusDialog
        isOpen={isUpdateDialogOpen}
        onClose={handleCancelUpdate}
        onUpdate={handleConfirmUpdate}
        initialData={{
          id: selectedSupplierStatus?.id,
          value: selectedSupplierStatus?.statusName,
        }}
      />
    </div>
  );
};

export default SupplierStatusTable;
