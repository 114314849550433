import React, { PropsWithChildren } from "react";
import { AppBar, Toolbar } from "@mui/material";

function FunctionalHeader({ children }: PropsWithChildren<any>) {
  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{
        py: 2,
      }}
    >
      <Toolbar disableGutters>{children}</Toolbar>
    </AppBar>
  );
}

export default FunctionalHeader;
