import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_Virtualizer,
} from "material-react-table";
import { CsvRateCard } from "../../../shared/type/CsvRateCard";

const tagColumns: MRT_ColumnDef<CsvRateCard>[] = [
  {
    accessorKey: "serviceName",
    header: "Service Name",
    size: 80,
  },
  {
    accessorKey: "dueDate",
    header: "Due Date",
    size: 80,
  },
  {
    accessorKey: "startDate",
    header: "Start Date",
    size: 80,
  },
  {
    accessorKey: "discount",
    header: "Discount",
    size: 80,
  },
  {
    accessorKey: "travelCost",
    header: "Travel Cost",
    size: 80,
  },
  {
    accessorKey: "location",
    header: "Location",
    size: 80,
  },
  {
    accessorKey: "locationCategory",
    header: "Location Category",
    size: 80,
  },
  {
    accessorKey: "currency",
    header: "Currency",
    size: 80,
  },
  {
    accessorKey: "serviceTag",
    header: "Service Tag",
    size: 80,
  },
  {
    accessorKey: "skillLevel1",
    header: "Skill Level 1",
    size: 50,
  },
  {
    accessorKey: "skillLevel2",
    header: "Skill Level 2",
    size: 50,
  },
  {
    accessorKey: "skillLevel3",
    header: "Skill Level 3",
    size: 50,
  },
  {
    accessorKey: "skillLevel4",
    header: "Skill Level 4",
    size: 50,
  },
  {
    accessorKey: "skillLevel5",
    header: "Skill Level 5",
    size: 50,
  },
  {
    accessorKey: "skillLevel6",
    header: "Skill Level 6",
    size: 50,
  },
];

type TagTableProps = {
  csvData: CsvRateCard[];
};
export const ImportPreviewTable = ({ csvData }: TagTableProps) => {
  const columns = useMemo<MRT_ColumnDef<CsvRateCard>[]>(() => tagColumns, []);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [data, setData] = useState<CsvRateCard[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setData(csvData);
    setIsLoading(false);
  }, [csvData]);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
        state={{ isLoading }}
      />
    </>
  );
};
