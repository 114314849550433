import React, { useCallback } from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import { useNavigate, useParams } from "react-router-dom";
import { AddRateCardPayload } from "../../../shared/service/api/ratecard/add-rate-card";
import { CurrencyEnum } from "../../../shared/enum/CurrencyEnum";
import { Rate } from "../../../shared/type/Rate";
import { Skill } from "../../../shared/type/Skill";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { handleError } from "../../../shared/helper/handle-error";
import { convertHashtag } from "./convert-hashtag";
import { supplierRateCardPath } from "../../../shared/const/path.const";
import {
  RateCardForm,
  RateCardFormValues,
} from "../../../feature/rate-card/RateCardForm";

const RateCardCreationPage: React.FC = () => {
  const navigate = useNavigate();
  const { supplierId } = useParams();

  const handleCreateRateCard = useCallback(
    async (data: RateCardFormValues) => {
      const payload: AddRateCardPayload = {
        currency: CurrencyEnum.EUR,
        dueDate: data.endDate ? new Date(data.endDate) : undefined,
        startDate: new Date(data.startDate),
        locationCategory: data.location,
        location: data.locationTag,
        eclassSubSubgroupId: data.eclassSubSubgroup,
        rates: data.services.map((item) => {
          const skills = item.skillLevels
            .map((price, index) => {
              const skill: Skill = {
                level: index + 1,
                price: Number(price),
              };
              return skill;
            })
            .filter((s) => s.price);

          const rate: Rate = {
            skills,
            tags: convertHashtag(item.tag),
            eclassValueId: item.service,
          };
          return rate;
        }),
        discount: data.discount,
        travelCost: data.travelCost,
        supplierStatusId: data.supplierStatusId,
      };
      try {
        if (!supplierId) {
          return AlertService.error("Lieferanten-ID ist erforderlich");
        }
        await ApiService.rateCard.addRateCard(supplierId, payload);
        AlertService.success("Ratecard erfolgreich erstellt!");
        navigate(supplierRateCardPath(supplierId));
      } catch (error) {
        handleError(error);
      }
    },
    [supplierId, navigate]
  );

  return (
    <HomeContainer>
      <RateCardForm
        supplierId={supplierId || ""}
        handleSubmitForm={handleCreateRateCard}
      />
    </HomeContainer>
  );
};

export default RateCardCreationPage;
