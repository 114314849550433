import { Typography } from "@mui/material";
import SearchResult from "../../components/search/SearchResult";
import { SupplierSearchResult } from "../../shared/type/SupplierSearchResult";

interface SupplierSearchResultsProps {
  searchResult?: SupplierSearchResult[];
}

export function SupplierSearchResults({
  searchResult,
}: SupplierSearchResultsProps) {
  return searchResult?.length ? (
    <>
      {searchResult.map((item) => (
        <SearchResult key={item.id} searchResult={item} />
      ))}
    </>
  ) : (
    <Typography
      component="h1"
      variant="h6"
      marginBottom={2}
      justifyContent="center"
      display="flex"
    >
      No matching results found
    </Typography>
  );
}
