import React from "react";
import {
  Grid,
  Rating,
  Button,
  Card,
  CardContent,
  Link,
  Typography,
  Box,
} from "@mui/material";
import { SupplierSearchResult } from "../../shared/type/SupplierSearchResult";
import {
  singleSupplierPath,
  supplierRateCardPath,
} from "../../shared/const/path.const";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import DiamondRating from "../rating/DiamondRating";
import { mapClassificationEnumToNumber } from "../../shared/helper/map-classification-enum-to-number";
import { RateCardAccordion } from "../../feature/rate-card/RateCardAccordion";

interface SearchResultProps {
  searchResult: SupplierSearchResult;
}

const SearchResult: React.FC<SearchResultProps> = ({ searchResult }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mb: 4 }}>
      <Card>
        <CardContent>
          <Grid container justifyContent="space-between" alignItems="start">
            <Grid item xs={8} container direction="column">
              <Grid
                item
                container
                spacing={1}
                marginBottom={2}
                alignItems="baseline"
              >
                <Grid item>
                  <Typography component="h1" variant="h4" fontWeight={700}>
                    {searchResult.companyName}
                  </Typography>
                </Grid>
                <Grid item>
                  {searchResult.websiteUrl && (
                    <Link
                      href={searchResult.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {searchResult.websiteUrl}
                    </Link>
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Rating value={searchResult.rating} readOnly />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      navigate(supplierRateCardPath(searchResult.id))
                    }
                    variant="outlined"
                    endIcon={<ChevronRight />}
                    size="large"
                  >
                    Ratecard
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      navigate(singleSupplierPath(searchResult.id))
                    }
                    variant="outlined"
                    endIcon={<ChevronRight />}
                    size="large"
                  >
                    Details
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} container direction="column">
              <Grid item>
                <Typography variant="body1">
                  <Box fontWeight="700" display="inline">
                    Leistungen:
                  </Box>{" "}
                  {searchResult.rateCards
                    .map((rc) =>
                      rc.rates.map((r) => r.eclassValue.name).join(",")
                    )
                    .join(",")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Box fontWeight="700" display="inline">
                    Lieferantenstatus:
                  </Box>{" "}
                  {[
                    ...new Set(
                      searchResult.supplierStatusList.map((s) => s.statusName)
                    ),
                  ].join(", ")}
                </Typography>
              </Grid>
              <Grid item display="flex" alignItems="center">
                <Typography variant="body1" fontWeight="700">
                  Klassifikation:
                </Typography>
                <DiamondRating
                  classificationValue={mapClassificationEnumToNumber(
                    searchResult.classification
                  )}
                ></DiamondRating>
              </Grid>
            </Grid>
          </Grid>
          {searchResult.rateCards.map((rateCard, key) => {
            return (
              <RateCardAccordion
                key={key}
                rateCard={rateCard}
                supplierId={searchResult.id}
                defaultExpandedAll={true}
              />
            );
          })}
        </CardContent>
      </Card>
    </Box>
  );
};

export default SearchResult;
