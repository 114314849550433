import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { TextInput } from "../inputs/TextInput";
import { Note } from "../../shared/type/Note";
import {
  RichTextEditorRef,
  RichTextEditor,
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
} from "mui-tiptap";
import StarterKit from "@tiptap/starter-kit";

type CreateSupplierNoteDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (payload: Omit<Note, "id">) => void;
};
export function CreateSupplierNoteDialog({
  isOpen,
  onClose,
  onCreate,
}: CreateSupplierNoteDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Omit<Note, "id">>();

  const rteRef = useRef<RichTextEditorRef>(null);

  const onSubmit = async (data: Omit<Note, "id">) => {
    data.date = new Date(data.date);
    data.text = `${rteRef.current?.editor?.getHTML()}`;
    await onCreate(data);
    reset();
  };

  const handleCancelCreate = () => {
    onClose();
    reset();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelCreate}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Note</DialogTitle>
        <DialogContent>
          <TextInput
            type="date"
            control={control}
            errors={errors}
            fieldName="date"
            label="Datum"
            required={true}
            InputLabelProps={{ shrink: true }}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="place"
            label="Ort"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="participants"
            label="Teilnehmer"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="type"
            label="Typ"
            required={true}
          />
          <RichTextEditor
            ref={rteRef}
            extensions={[StarterKit]}
            renderControls={() => (
              <MenuControlsContainer>
                <MenuSelectHeading />
                <MenuDivider />
                <MenuButtonBold />
                <MenuButtonItalic />
                <MenuButtonBulletedList />
                <MenuButtonOrderedList />
              </MenuControlsContainer>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCreate} size="large">
            Abbrechen
          </Button>
          <Button type="submit" size="large">
            Bewerten
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
