import React, { useCallback, useEffect, useState } from "react";
import { FieldNamesMarkedBoolean, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { LocationCategoryEnum } from "../../shared/enum/LocationCategoryEnum";
import ApiService from "../../shared/service/api/api.service";
import { handleError } from "../../shared/helper/handle-error";
import { supplierRateCardPath } from "../../shared/const/path.const";
import { TextInput } from "../../feature/inputs/TextInput";
import { EclassSubSubgroup } from "../../shared/type/EclassSubSubgroup";
import { SelectInput } from "../../feature/inputs/SelectInput";
import { Eclass } from "../../shared/type/Eclass";
import { SupplierStatus } from "../../shared/type/SupplierStatus";

export type RateCardFormValues = {
  eclassSubSubgroup: string;
  supplierStatusId?: string;
  location: LocationCategoryEnum;
  locationTag: string;
  startDate: string;
  endDate?: string;
  discount: boolean;
  travelCost?: string;
  services: ServiceRow[];
};

export type ServiceRow = {
  rateId?: string;
  service: string;
  tag: string;
  skillLevels: string[];
};

type RateCardFormProps = {
  values?: RateCardFormValues;
  supplierId: string;
  handleSubmitForm: (
    data: RateCardFormValues,
    dirtyFields?: FieldNamesMarkedBoolean<RateCardFormValues>
  ) => void;
  editMode?: boolean;
};

export function RateCardForm({
  values,
  supplierId,
  handleSubmitForm,
  editMode,
}: RateCardFormProps) {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    watch,
    setValue,
    getValues,
  } = useForm<RateCardFormValues>({
    defaultValues: {
      eclassSubSubgroup: undefined,
      supplierStatusId: undefined,
      location: undefined,
      locationTag: undefined,
      startDate: undefined,
      endDate: undefined,
      discount: false,
      travelCost: undefined,
      services: [
        {
          service: undefined,
          tag: undefined,
          skillLevels: [],
        },
      ],
    },
    values: values,
  });

  const eClassSubSubgroup = watch("eclassSubSubgroup");
  const [supplierStatuses, setSupplierStatuses] = useState<SupplierStatus[]>(
    []
  );
  const [subSubgroups, setSubSubgroups] = useState<EclassSubSubgroup[]>([]);
  const [rowCount, setRowCount] = useState(1);
  const [eclassValues, setEclassValues] = useState<Eclass[]>([]);

  const fetchData = useCallback(async () => {
    try {
      const [subSubgroupList, supplierStatuses] = await Promise.all([
        await ApiService.eclass.getEclassSubSubgroups(),
        await ApiService.supplierStatus.getSupplierStatuses(),
      ]);
      if (subSubgroupList) {
        setSubSubgroups(subSubgroupList.data);
      }
      if (supplierStatuses) {
        setSupplierStatuses(supplierStatuses.data);
      }
    } catch (error) {
      handleError(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (values?.services) {
      setRowCount(values.services.length);
    }
  }, [values]);

  const fetchEClassValues = useCallback(async () => {
    try {
      if (eClassSubSubgroup) {
        const eclassValues = await ApiService.eclass.getEclassValues(
          eClassSubSubgroup
        );
        if (eclassValues) {
          setEclassValues(eclassValues.data);
        }
      }
    } catch (error) {
      handleError(error);
    }
  }, [eClassSubSubgroup]);

  useEffect(() => {
    fetchEClassValues();
  }, [eClassSubSubgroup]);

  const addRow = () => {
    setRowCount(rowCount + 1);
  };

  const removeRow = (index: number) => {
    const services = getValues("services");
    services.splice(index, 1);
    setValue(`services`, services);
    setRowCount(rowCount - 1);
  };

  const removeLastRow = () => {
    setRowCount(rowCount - 1);
  };

  const onSubmit = (data: RateCardFormValues) => {
    handleSubmitForm(data, dirtyFields);
  };

  return (
    <Card sx={{ width: "100%", p: 5 }}>
      <CardContent component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h5" component="h2" marginBottom={3}>
          {editMode ? "Ratecard speichern" : "Ratecard hinzufügen"}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectInput
              control={control}
              errors={errors}
              fieldName="eclassSubSubgroup"
              label="Leistung"
              items={subSubgroups}
              required
              fullWidth
              variant="filled"
              shrink={editMode}
              disabled={editMode}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectInput
              control={control}
              errors={errors}
              fieldName="supplierStatusId"
              label="Lieferantenstatus"
              items={supplierStatuses.map((s) => ({
                id: s.id,
                name: s.statusName,
              }))}
              fullWidth
              variant="filled"
              shrink={editMode}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectInput
              control={control}
              errors={errors}
              fieldName="location"
              label="Standort"
              items={Object.values(LocationCategoryEnum).map((v) => ({
                id: v,
                name: v.toLowerCase().split("_").join(" "),
              }))}
              required
              fullWidth
              variant="filled"
              shrink={editMode}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              control={control}
              errors={errors}
              fieldName="locationTag"
              label="Standort Tag"
              required
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: editMode }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              type="date"
              control={control}
              errors={errors}
              fieldName="startDate"
              label="Start Datum"
              required
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              type="date"
              control={control}
              errors={errors}
              fieldName="endDate"
              label="End Datum"
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              control={control}
              errors={errors}
              fieldName="travelCost"
              label="Reisekosten"
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: editMode }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              Services EClass Level 4
            </Typography>
          </Grid>
          {Array.from(Array(rowCount).keys()).map((index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <SelectInput
                      control={control}
                      errors={errors}
                      fieldName={`services.${index}.service`}
                      label="Service"
                      items={eclassValues}
                      required
                      fullWidth
                      variant="filled"
                      disabled={!eclassValues.length}
                      shrink={editMode}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextInput
                      control={control}
                      errors={errors}
                      fieldName={`services.${index}.tag`}
                      label="Tag"
                      required
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: editMode }}
                    />
                  </Grid>
                  {Array.from(Array(6).keys()).map((level) => (
                    <Grid item xs={1} key={level}>
                      <TextInput
                        control={control}
                        errors={errors}
                        fieldName={`services.${index}.skillLevels.${level}`}
                        label={`Skill Level ${level + 1}`}
                        fullWidth
                        variant="filled"
                        InputLabelProps={{ shrink: editMode }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={1}
                    alignItems="center"
                    justifyContent="center"
                    justifyItems="center"
                    container
                  >
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => removeRow(index)}
                      disabled={rowCount === 1}
                    >
                      <Remove />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              {!editMode && (
                <Button
                  variant="outlined"
                  size="large"
                  onClick={removeLastRow}
                  disabled={rowCount === 1}
                >
                  <Remove />
                </Button>
              )}
              <Button variant="contained" disableElevation onClick={addRow}>
                <Add />
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2} justifyContent="flex-end" mt={5}>
          <Button
            variant="outlined"
            size="large"
            onClick={() => navigate(supplierRateCardPath(supplierId))}
          >
            Abbrechen
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disableElevation
            disabled={isSubmitting}
          >
            {editMode ? "Ratecard speichern" : "Ratecard hinzufügen"}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
