import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TuneIcon from "@mui/icons-material/Tune";
import _ from "lodash";

export interface Filter {
  filterName: string;
  filterLabel: string;
  filterOptions: FilterOption[];
  multiSelect: boolean;
}

export interface FilterOption {
  key: any;
  label: string | ReactElement;
}

interface FilterBarProps {
  filters: Filter[];
  onFilterChange: (args: Record<string, string[] | any>) => void;
}

const filterBarStyles: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
};
const FilterBar: React.FC<FilterBarProps> = ({ filters, onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState<Record<number, HTMLElement | null>>(
    {}
  );
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, string[] | any>
  >({});
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    onFilterChange(selectedFilters);
    setIsFiltered(!_.isEmpty(selectedFilters));
  }, [selectedFilters, onFilterChange]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setAnchorEl((prevState) => ({
      ...prevState,
      [index]: event.currentTarget,
    }));
  };

  const handleClose = (index: number) => {
    setAnchorEl((prevState) => ({ ...prevState, [index]: null }));
  };

  const handleFilterChange = (
    filterName: string,
    multiSelect: boolean,
    option: string
  ) => {
    setSelectedFilters((prevState) => {
      const newState = { ...prevState };
      if (multiSelect) {
        newState[filterName] = newState[filterName] || [];
        if (newState[filterName].includes(option)) {
          newState[filterName] = newState[filterName].filter(
            (opt: any) => opt !== option
          );
        } else {
          newState[filterName].push(option);
        }
      } else {
        if (newState[filterName] === option) {
          newState[filterName] = undefined;
        } else {
          newState[filterName] = option;
        }
      }
      return newState;
    });
  };

  const handleClearFilters = () => setSelectedFilters({});

  return (
    <Card>
      <CardContent sx={{ "&:last-child": { pb: 2 } }}>
        <div style={filterBarStyles}>
          <TuneIcon sx={{ mr: 3 }} />
          {filters.map((filter, index) => (
            <div key={index}>
              <Button
                variant="text"
                disableElevation
                onClick={(event) => handleClick(event, index)}
                endIcon={<ArrowDropDownIcon />}
                size="large"
              >
                {filter.filterLabel}
              </Button>
              <Menu
                anchorEl={anchorEl[index]}
                open={Boolean(anchorEl[index])}
                onClose={() => handleClose(index)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                {filter.filterOptions.map((option) => (
                  <MenuItem
                    key={option.key}
                    onClick={() =>
                      handleFilterChange(
                        filter.filterName,
                        filter.multiSelect,
                        option.key
                      )
                    }
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          Array.isArray(selectedFilters[filter.filterName])
                            ? selectedFilters[filter.filterName].includes(
                                option.key
                              )
                            : selectedFilters[filter.filterName] === option.key
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.label}>test</ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ))}
          {isFiltered && (
            <Button variant="outlined" onClick={handleClearFilters}>
              Clear filters
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default FilterBar;
