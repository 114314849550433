import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";

export const deleteEclassValue = async (
  eclassValueId: string
): Promise<void> => {
  try {
    await apiClient.delete(`${ECLASS_URL}/values/${eclassValueId}`);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
