import { SUPPLIER_LOGO_URL } from "../api.const";
import apiClient from "../api.client";

export const createSupplierLogo = async (supplierId: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.post(
      `${SUPPLIER_LOGO_URL(supplierId)}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
