import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_Virtualizer,
} from "material-react-table";
import { MenuItem } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { Tag } from "../../shared/type/Tag";
import { UpdateTagDialog } from "./UpdateTagDialog";
import { ConfirmDialog } from "../../components/dialog/ConfirmDialog";

const tagColumns: MRT_ColumnDef<Tag>[] = [
  {
    accessorKey: "name",
    header: "Tagname",
    size: 250,
  },
];

type TagTableProps = {
  tagList: Tag[];
  updateTag: (tag: Tag) => void;
  deleteTag: (tagId: string) => void;
};
const TagTable = ({ tagList, updateTag, deleteTag }: TagTableProps) => {
  const columns = useMemo<MRT_ColumnDef<Tag>[]>(() => tagColumns, []);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [data, setData] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

  useEffect(() => {
    setData(tagList);
    setIsLoading(false);
  }, [tagList]);

  useEffect(() => {
    //scroll to the top of the supplier-table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  // DELETE DIALOG
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = (tagDto: Tag) => {
    setSelectedTag(tagDto);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedTag?.id) {
      deleteTag(selectedTag.id);
    }
    setDeleteDialogOpen(false);
    setSelectedTag(null);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedTag(null);
  };

  // UPDATE DIALOG
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);

  const handleUpdate = (tagDto: Tag) => {
    setSelectedTag(tagDto);
    setUpdateDialogOpen(true);
  };

  const handleConfirmUpdate = (id: string, name: string) => {
    updateTag({ id, name });
    setUpdateDialogOpen(false);
    setSelectedTag(null);
  };

  const handleCancelUpdate = () => {
    setUpdateDialogOpen(false);
    setSelectedTag(null);
  };

  return (
    <div className="customReactMaterialTable">
      <MaterialReactTable
        columns={columns}
        data={data}
        defaultDisplayColumn={{ enableResizing: true }}
        enableBottomToolbar={false}
        enableColumnVirtualization
        enableGlobalFilterModes
        enablePagination={false}
        enableHiding={false}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
        onSortingChange={setSorting}
        state={{ isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer
        enableRowActions
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              handleUpdate(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <Edit></Edit>
            Edit
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleDelete(row.original);
            }}
            sx={{ m: 0 }}
          >
            <Delete></Delete>
            Delete
          </MenuItem>,
        ]}
      />
      {selectedTag?.id && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onClose={handleCancelDelete}
          onAction={handleConfirmDelete}
          title="Delete element"
          content="Are you sure you want to delete?"
          buttonText="Delete"
          id={selectedTag.id}
        />
      )}

      <UpdateTagDialog
        isOpen={isUpdateDialogOpen}
        onClose={handleCancelUpdate}
        onUpdate={handleConfirmUpdate}
        initialData={{ id: selectedTag?.id, value: selectedTag?.name }}
      />
    </div>
  );
};

export default TagTable;
