import DetailsCardItem from "../../components/card/DetailsCardItem";
import DetailsCardItemWithTwoColumn from "../../components/card/DetailsCardItemWithTwoColumn";
import { BusinessContact } from "../../shared/type/BusinessContact";

type SupplierBusinessContactProps = {
  contact: BusinessContact;
};

export function SupplierBusinessContact({
  contact,
}: SupplierBusinessContactProps) {
  return (
    <div>
      <DetailsCardItemWithTwoColumn
        firstCardItemTitle="Anrede"
        secondCardItemTitle="Position"
      >
        <div>{contact.firstName}</div>
      </DetailsCardItemWithTwoColumn>
      <DetailsCardItemWithTwoColumn
        firstCardItemTitle="Vorname"
        secondCardItemTitle="Name"
      >
        <div>{contact.lastName}</div>
      </DetailsCardItemWithTwoColumn>
      <DetailsCardItemWithTwoColumn
        firstCardItemTitle="Straße"
        secondCardItemTitle="PLZ Ort"
      >
        <div>{contact.street}</div>
        <div>{contact.zipCode}</div>
      </DetailsCardItemWithTwoColumn>
      <DetailsCardItemWithTwoColumn
        firstCardItemTitle="Email"
        secondCardItemTitle="Telefon"
      >
        <div>{contact.email}</div>
        <div>{contact.phone}</div>
      </DetailsCardItemWithTwoColumn>
      <DetailsCardItemWithTwoColumn
        firstCardItemTitle="Mobitelefon"
        secondCardItemTitle="Telefax"
      >
        <div>{contact.mobilePhone}</div>
        <div>{contact.fax}</div>
      </DetailsCardItemWithTwoColumn>
      <DetailsCardItem cardItemTitle="Notiz" showDivider={false}>
        {contact.note}
      </DetailsCardItem>
    </div>
  );
}
