import { TextField, TextFieldProps } from "@mui/material";
import _ from "lodash";
import { Controller, Control, FieldErrors } from "react-hook-form";

type TextInputProps = {
  control: Control<any, any>;
  errors: FieldErrors;
  fieldName: string;
  label: string;
  required?: boolean;
  multiline?: boolean;
};

export const TextInput: React.FC<TextInputProps & TextFieldProps> = ({
  control,
  errors,
  fieldName,
  label,
  required,
  multiline,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={required ? { required: `${label} is required` } : {}}
      defaultValue={null}
      render={({ field }) => (
        <TextField
          {...field}
          multiline={multiline}
          label={label}
          error={!!_.get(errors, fieldName)}
          helperText={_.get(errors, fieldName)?.message?.toString() || ""}
          fullWidth
          margin="dense"
          {...rest}
        />
      )}
    />
  );
};
