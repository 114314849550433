import React, { useEffect, useMemo, useRef, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_Virtualizer,
} from "material-react-table";
import DiamondRating from "../../components/rating/DiamondRating";
import { useNavigate } from "react-router-dom";
import { Supplier } from "../../shared/type/Supplier";
import { singleSupplierPath } from "../../shared/const/path.const";
import { ClassificationEnum } from "../../shared/enum/ClassificationEnum";
import { mapClassificationEnumToNumber } from "../../shared/helper/map-classification-enum-to-number";
import { SupplierStatus } from "../../shared/type/SupplierStatus";
import { Rating } from "@mui/material";
import { DeliveryModelEnum } from "../../shared/enum/DeliveryModelEnum";

type SupplierTableProps = {
  supplierList: Supplier[];
  supplierStatuses: SupplierStatus[];
};

const SupplierTable = ({
  supplierList,
  supplierStatuses,
}: SupplierTableProps) => {
  const columns = useMemo<MRT_ColumnDef<Supplier>[]>(
    () => [
      {
        accessorKey: "companyName",
        header: "Firmenname",
        size: 190,
      },
      {
        accessorKey: "classification",
        header: "Klassifikation",
        size: 90,
        Cell: ({ renderedCellValue }) => (
          <DiamondRating
            classificationValue={mapClassificationEnumToNumber(
              renderedCellValue as ClassificationEnum
            )}
          ></DiamondRating>
        ),
        filterVariant: "multi-select",
        filterSelectOptions: Object.values(ClassificationEnum),
      },
      {
        accessorKey: "supplierStatusList",
        header: "Status",
        size: 180,
        Cell: ({ renderedCellValue }) =>
          (renderedCellValue as SupplierStatus[])
            ?.map((s) => s.statusName)
            .join(", "),
        filterVariant: "multi-select",
        filterSelectOptions: supplierStatuses.map((s) => s.statusName),
        filterFn: (row, id, filterValue) =>
          !filterValue.length ||
          row
            .getValue<SupplierStatus[]>(id)
            .some((s) => filterValue.includes(s.statusName)),
      },
      {
        accessorKey: "deliveryModel",
        header: "Erbringung",
        size: 180,
        filterVariant: "multi-select",
        filterSelectOptions: Object.values(DeliveryModelEnum),
      },
      {
        accessorFn: (row) => row.rating ?? "",
        header: "Rating",
        size: 140,
        Cell: ({ cell }) => <Rating value={cell.getValue<number>()} readOnly />,
        filterVariant: "range-slider",
        filterFn: "betweenInclusive", // default (or between)
        muiTableHeadCellFilterSliderProps: {
          marks: true,
          max: 5,
          min: 1,
          step: 1,
        },
      },
    ],
    [supplierStatuses]
  );

  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [data, setData] = useState<Supplier[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setData(supplierList);
    setIsLoading(false);
  }, [supplierList]);

  useEffect(() => {
    //scroll to the top of the supplier-table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  return (
    <div className="customReactMaterialTable">
      <MaterialReactTable
        columns={columns}
        data={data}
        defaultDisplayColumn={{ enableResizing: true }}
        enableBottomToolbar={false}
        enableColumnVirtualization
        enableGlobalFilterModes
        enablePagination={false}
        enableRowNumbers
        enableHiding={false}
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: "600px" } }}
        onSortingChange={setSorting}
        state={{ isLoading, sorting }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
        columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            navigate(singleSupplierPath(row.original.id));
          },
          sx: { cursor: "pointer" },
        })}
      />
    </div>
  );
};

export default SupplierTable;
