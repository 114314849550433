import apiClient from "../api.client";
import { RATE_CARD_URL } from "../api.const";
import { CurrencyEnum } from "../../../enum/CurrencyEnum";
import { LocationCategoryEnum } from "../../../enum/LocationCategoryEnum";
import { Rate } from "../../../type/Rate";

export interface AddRateCardPayload {
  currency: CurrencyEnum;
  dueDate?: Date;
  startDate: Date;
  locationCategory: LocationCategoryEnum;
  location: string;
  eclassSubSubgroupId: string;
  rates: Rate[];
  discount?: boolean;
  travelCost?: string;
  supplierStatusId?: string;
}

export const addRateCard = async (
  supplierId: string,
  addRateCardPayload: AddRateCardPayload
) => {
  try {
    await apiClient.post(RATE_CARD_URL(supplierId), addRateCardPayload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
