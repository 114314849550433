import { toast } from "react-toastify";
import { getTranslation } from "../translation/get-translation";

export const error = (message: string): void => {
  toast.error(getTranslation(message), {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
