import React from "react";
import { Control, UseFormGetValues } from "react-hook-form/dist/types/form";
import { Controller, FieldErrors } from "react-hook-form";
import { TextField } from "@mui/material";

type RepeatedPasswordProps = {
  control: Control<any, any>;
  getValues: UseFormGetValues<any>;
  errors: FieldErrors;
  props?: any;
};

export const RepeatedPasswordInput: React.FC<RepeatedPasswordProps> = ({
  control,
  getValues,
  errors,
  props,
}) => {
  const fieldName = "repeatedPassword";
  return (
    <Controller
      control={control}
      name="repeatedPassword"
      rules={{
        required: "Repeated password is required",
        minLength: {
          value: 6,
          message: "Password must be at least 6 characters long",
        },
        validate: (value: any) =>
          value === getValues("password") || "Passwords do not match",
      }}
      defaultValue={null}
      render={({ field }) => (
        <>
          <TextField
            {...field}
            label="Repeat Password"
            type="password"
            error={!!errors[fieldName]}
            helperText={errors[fieldName]?.message?.toString() || ""}
            fullWidth
            margin="dense"
            {...props}
          />
        </>
      )}
    />
  );
};
