import apiClient from "../api.client";
import { USER_URL } from "../api.const";

export interface UpdateUserPayload {
  userId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}
export const updateUser = async (payload: UpdateUserPayload): Promise<void> => {
  try {
    await apiClient.put<void>(`${USER_URL}/${payload.userId}`, payload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
