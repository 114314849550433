import { RATE_CARD_URL } from "../api.const";
import apiClient from "../api.client";

export const exportRateCard = async (supplierId: string) => {
  try {
    const response = await apiClient.get<Blob>(
      `${RATE_CARD_URL(supplierId)}/export`,
      {
        responseType: "blob",
      }
    );

    const blob = new Blob([response.data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "rate_card.csv";
    link.click();

    return true;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
