import apiClient from "../api.client";
import { RATE_URL } from "../api.const";

export const deleteRate = async (
  supplierId: string,
  rateCardId: string,
  rateId: string
) => {
  try {
    await apiClient.delete(`${RATE_URL(supplierId, rateCardId)}/${rateId}`);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
