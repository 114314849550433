import apiClient from "../api.client";
import { SUPPLIER_STATUS_URL } from "../api.const";

export interface CreateSupplierStatusPayload {
  statusName: string;
}

export const createSupplierStatus = async (
  createSupplierStatusPayload: CreateSupplierStatusPayload
) => {
  try {
    await apiClient.post(SUPPLIER_STATUS_URL, createSupplierStatusPayload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
