import apiClient from "../api.client";
import { AUTH_URL } from "../api.const";

export const logoutUser = async (): Promise<void> => {
  try {
    await apiClient.post(`${AUTH_URL}/logout`);
  } catch (error: any) {
    throw error?.response?.data?.message || "Unknown error";
  }
};
