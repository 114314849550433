import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  IconButton,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FunctionalHeader from "./FunctionalHeader";
import { useSearchParams } from "react-router-dom";

function SearchHeader({ onSearchTextChange }: any) {
  const theme = useTheme();
  let [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get("query") ?? "");

  useEffect(() => {
    onSearchTextChange(searchText);
  }, [searchText, onSearchTextChange]);

  const handleSearchTextChange = (event: any) => {
    setSearchText(event.target.value);
  };

  return (
    <FunctionalHeader>
      <Container maxWidth="xl">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              onChange={handleSearchTextChange}
              value={searchText}
              fullWidth
              autoFocus={true}
              placeholder="Suchtext hier eingeb ..."
              sx={{ backgroundColor: theme.palette.background.default }}
              InputProps={{
                endAdornment: (
                  <IconButton edge="end">
                    <SearchIcon sx={{ fill: theme.palette.primary.dark }} />
                  </IconButton>
                ),
              }}
              type="search"
            />
          </Grid>
        </Grid>
      </Container>
    </FunctionalHeader>
  );
}

export default SearchHeader;
