import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import HomeContainer from "../../components/container/HomeContainer";
import { ClassificationEnum } from "../../shared/enum/ClassificationEnum";
import { SupplierTypeEnum } from "../../shared/enum/SupplierTypeEnum";
import { DeliveryModelEnum } from "../../shared/enum/DeliveryModelEnum";
import ApiService from "../../shared/service/api/api.service";
import AlertService from "../../shared/service/alert/alert.service";
import { CreateSupplierPayload } from "../../shared/service/api/supplier/create-supplier";
import { SUPPLIER_PATH } from "../../shared/const/path.const";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TextInput } from "../../feature/inputs/TextInput";
import { SelectInput } from "../../feature/inputs/SelectInput";
import { handleError } from "../../shared/helper/handle-error";

const SupplierCreationPage = () => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateSupplierPayload>({
    defaultValues: {
      companyName: "",
      vatNumber: "",
      taxNumber: "",
      sapNumber: "",
      websiteUrl: "",
      note: "",
      classification: ClassificationEnum.BRONZE,
      type: SupplierTypeEnum.SUPPLIER,
      deliveryModel: DeliveryModelEnum.T_M,
    },
  });

  const handleCreateSupplier = async (data: CreateSupplierPayload) => {
    try {
      await ApiService.supplier.createSupplier(data);
      AlertService.success("Neuer Lieferant erstellt");
      navigate(SUPPLIER_PATH);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <HomeContainer>
      <Card sx={{ width: "100%", p: 5 }}>
        <CardContent
          component="form"
          onSubmit={handleSubmit(handleCreateSupplier)}
        >
          <Typography variant="h5" component="h2" marginBottom={3}>
            Lieferant erstellen
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <TextInput
                control={control}
                errors={errors}
                fieldName="companyName"
                label="Firmenname"
                required
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                control={control}
                errors={errors}
                fieldName="websiteUrl"
                label="Website URL"
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                control={control}
                errors={errors}
                fieldName="vatNumber"
                label="VAT Nummer"
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                control={control}
                errors={errors}
                fieldName="taxNumber"
                label="Steuer Nummer"
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                control={control}
                errors={errors}
                fieldName="sapNumber"
                label="SAP Nummer"
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectInput
                control={control}
                errors={errors}
                fieldName="classification"
                label="Klassifikation"
                items={Object.values(ClassificationEnum).map((v) => ({
                  id: v,
                  name: v.toLowerCase().split("_").join(" "),
                }))}
                required
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectInput
                control={control}
                errors={errors}
                fieldName="deliveryModel"
                label="Erbringung"
                items={Object.values(DeliveryModelEnum).map((v) => ({
                  id: v,
                  name: v.toLowerCase().split("_").join(" "),
                }))}
                required
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                control={control}
                errors={errors}
                fieldName="note"
                label="Notizen"
                fullWidth
                multiline
                rows={4}
                variant="filled"
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={5}>
            <Button
              variant="outlined"
              size="large"
              onClick={() => navigate(SUPPLIER_PATH)}
            >
              Abbrechen
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disableElevation
              disabled={isSubmitting}
            >
              Lieferant erstellen
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </HomeContainer>
  );
};

export default SupplierCreationPage;
