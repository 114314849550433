import { RATE_CARD_URL } from "../api.const";
import apiClient from "../api.client";

export const importRateCard = async (supplierId: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append("supplierId", supplierId);
    formData.append("file", file);

    const response = await apiClient.post(
      `${RATE_CARD_URL(supplierId)}/import`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
