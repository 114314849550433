import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";

export const deleteEclassSubgroup = async (
  subgroupId: string
): Promise<void> => {
  try {
    await apiClient.delete(`${ECLASS_URL}/subgroups/${subgroupId}`);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
