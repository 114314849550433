import apiClient from "../api.client";
import { RATE_CARD_URL } from "../api.const";
import { RateCardResponse } from "../../../type/RateCardResponse";

export const getRateCardById = async (
  supplierId: string,
  rateCardId: string
) => {
  try {
    const response = await apiClient.get<RateCardResponse>(
      `${RATE_CARD_URL(supplierId)}/${rateCardId}`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
