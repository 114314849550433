import AlertService from "../service/alert/alert.service";

export const handleError = (error: unknown, defaultMessage?: string): void => {
  if (typeof error === "string") {
    AlertService.error(error);
  } else if (Array.isArray(error) && error.length) {
    const message = error
      .map((e: any) => e.messages.map((em: any) => em.message).join(","))
      .join(",");
    AlertService.error(message);
  } else {
    AlertService.error(defaultMessage || "Unknown error");
  }
};
