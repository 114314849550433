import apiClient from "../api.client";
import { AUTH_URL } from "../api.const";

export interface RegisterUserPayload {
  email: string;
  password: string;
  repeatedPassword: string;
}

export interface RegisterUserResponse {
  userId: string;
}

export const registerUser = async (
  registerUserPayload: RegisterUserPayload
) => {
  try {
    const response = await apiClient.post<RegisterUserResponse>(
      `${AUTH_URL}/register`,
      registerUserPayload
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
