import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { TextInput } from "../inputs/TextInput";

type CreateSupplierStatusDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (statusName: string) => void;
};
export function CreateSupplierStatusDialog({
  isOpen,
  onClose,
  onCreate,
}: CreateSupplierStatusDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ statusName: string }>();

  const onSubmit = async (data: { statusName: string }) => {
    await onCreate(data.statusName);
    reset();
  };

  const handleCancelCreate = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelCreate}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create supplier status</DialogTitle>
        <DialogContent>
          <TextInput
            control={control}
            errors={errors}
            fieldName="statusName"
            label="Status name"
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCreate} size="large">
            Abbrechen
          </Button>
          <Button type="submit" size="large">
            Bewerten
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
