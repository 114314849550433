import { Typography } from "@mui/material";
import React from "react";

type CardRowTitleTextBoxProps = {
  text: string;
};
function CardRowTitleTextBox({ text }: CardRowTitleTextBoxProps) {
  return (
    <Typography style={{ fontWeight: "bold" }} variant="body2">
      {text}
    </Typography>
  );
}

export default CardRowTitleTextBox;
