import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { TextInput } from "../inputs/TextInput";
import { UpdateSupplierPayload } from "../../shared/service/api/supplier/update-supplier";
import { SelectInput } from "../inputs/SelectInput";
import { ClassificationEnum } from "../../shared/enum/ClassificationEnum";
import { DeliveryModelEnum } from "../../shared/enum/DeliveryModelEnum";

type UpdateSupplierDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (payload: UpdateSupplierPayload) => void;
  initialData: UpdateSupplierPayload;
};

export function UpdateSupplierDialog({
  isOpen,
  onClose,
  onUpdate,
  initialData,
}: UpdateSupplierDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UpdateSupplierPayload>({ defaultValues: initialData });

  const onSubmit = async (data: UpdateSupplierPayload) => {
    await onUpdate(data);
  };

  const handleCancelCreate = () => {
    onClose();
    reset();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelCreate}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Aktualisiere Lieferant</DialogTitle>
        <Box>
          <DialogContent>
            <TextInput
              control={control}
              errors={errors}
              fieldName="companyName"
              label="Firmenname"
              required={true}
            />
            <TextInput
              control={control}
              errors={errors}
              fieldName="vatNumber"
              label="VAT Nummer"
            />
            <TextInput
              control={control}
              errors={errors}
              fieldName="taxNumber"
              label="Steuer Nummer"
            />
            <TextInput
              control={control}
              errors={errors}
              fieldName="sapNumber"
              label="SAP Nummer"
            />
            <TextInput
              control={control}
              errors={errors}
              fieldName="websiteUrl"
              label="Website URL"
            />
            <TextInput
              control={control}
              errors={errors}
              fieldName="note"
              label="Notiz"
              multiline
            />
            <SelectInput
              control={control}
              errors={errors}
              fieldName="classification"
              label="Klassifikation"
              items={Object.values(ClassificationEnum).map((v) => ({
                id: v,
                name: v.toLowerCase().split("_").join(" "),
              }))}
            />
            <SelectInput
              control={control}
              errors={errors}
              fieldName="deliveryModel"
              label="Erbringung"
              items={Object.values(DeliveryModelEnum).map((v) => ({
                id: v,
                name: v.toLowerCase().split("_").join(" "),
              }))}
            />
          </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleCancelCreate} size="large">
            Abbrechen
          </Button>
          <Button type="submit" size="large">
            Aktualisieren
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
