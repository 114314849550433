import apiClient from "../api.client";
import { SINGLE_RATING_URL } from "../api.const";

export const deleteRating = async (ratingId: string): Promise<void> => {
  try {
    await apiClient.delete<void>(SINGLE_RATING_URL(ratingId));
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
