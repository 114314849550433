import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import HomeContainer from "../../../components/container/HomeContainer";
import AlertService from "../../../shared/service/alert/alert.service";
import Papa from "papaparse";
import { CsvRateCard } from "../../../shared/type/CsvRateCard";
import { ImportPreviewTable } from "./ImportPreviewTable";
import { FileUpload } from "@mui/icons-material";
import { Button } from "@mui/material";
import ApiService from "../../../shared/service/api/api.service";
import { handleError } from "../../../shared/helper/handle-error";
import { useNavigate, useParams } from "react-router-dom";
import { supplierRateCardPath } from "../../../shared/const/path.const";

function RateCardImportPage() {
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState<CsvRateCard[]>([]);
  const navigate = useNavigate();
  const { supplierId } = useParams();

  const handleFileDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      AlertService.warn("File is required");
    } else {
      const selectedFile = acceptedFiles[0];
      if (selectedFile.type !== "text/csv") {
        AlertService.warn("Only CSV files are allowed");
      } else {
        setFile(selectedFile);
        readCsvData(selectedFile);
      }
    }
  };

  const readCsvData = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const csvText = e?.target?.result as string;
      if (csvText) {
        Papa.parse(csvText, {
          delimiter: ";",
          header: true,
          complete: (result: any) => {
            const parsedData = result.data;
            let mappedData: CsvRateCard[] = parsedData.map(
              (row: any): CsvRateCard => ({
                serviceName: row["Service"],
                startDate: row["Start Date"],
                dueDate: row["Due Date"],
                serviceTag: row["Service Tag"],
                location: row["Location"],
                locationCategory: row["Location Tag"],
                discount: Number(row["Discount"]),
                travelCost: row["Travel Cost"],
                currency: row["Currency"],
                skillLevel1: row["Skill Level 1"],
                skillLevel2: row["Skill Level 2"],
                skillLevel3: row["Skill Level 3"],
                skillLevel4: row["Skill Level 4"],
                skillLevel5: row["Skill Level 5"],
                skillLevel6: row["Skill Level 6"],
              })
            );
            mappedData = mappedData.filter((item) => !!item.serviceName);

            setCsvData(mappedData);
          },
          error: (error: any) => {
            console.error("Error parsing CSV:", error);
            AlertService.error("Error parsing CSV");
          },
        });
      }
    };
    reader.readAsText(file);
  };

  const acceptFormats = {
    csv: ["text/csv"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept: acceptFormats,
    onDrop: handleFileDrop,
  });

  const handleImportRateCard = async () => {
    try {
      if (file && supplierId) {
        await ApiService.rateCard.importRateCard(supplierId, file);
        await AlertService.delay(() =>
          AlertService.success("Successfully imported data!")
        );
        navigate(supplierRateCardPath(supplierId));
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <HomeContainer>
      <Box display="flex" alignItems="center" marginBottom={3}>
        <Typography component="h1" variant="h4" sx={{ flexGrow: 1 }}>
          Ratecard importieren
        </Typography>
      </Box>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box
          border="2px dashed #999999"
          borderRadius="8px"
          padding="32px"
          textAlign="center"
          marginBottom={3}
        >
          <Typography>
            Hier Drag and drop CSV Datei oder hier klicken um Datei auszuwählen
          </Typography>
        </Box>
      </div>
      <div>
        {file && (
          <div>
            {csvData ? (
              <ImportPreviewTable csvData={csvData} />
            ) : (
              <div>Loading CSV data...</div>
            )}
          </div>
        )}
      </div>
      {file && supplierId && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ marginTop: "20px" }}
            variant="outlined"
            onClick={handleImportRateCard}
            startIcon={<FileUpload />}
          >
            Ratecard importieren
          </Button>
        </Box>
      )}
    </HomeContainer>
  );
}

export default RateCardImportPage;
