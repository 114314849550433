import StarterKit from "@tiptap/starter-kit";
import { RichTextReadOnly } from "mui-tiptap";
import DetailsCardItem from "../../components/card/DetailsCardItem";
import { Note } from "../../shared/type/Note";

type SupplierNoteProps = {
  note: Note;
};

export function SupplierNote({ note }: SupplierNoteProps) {
  return (
    <div>
      <DetailsCardItem cardItemTitle="Datum">
        {new Date(note.date).toLocaleDateString()}
      </DetailsCardItem>
      <DetailsCardItem cardItemTitle="Ort">{note.place}</DetailsCardItem>
      <DetailsCardItem cardItemTitle="Teilnehmer">
        {note.participants}
      </DetailsCardItem>

      <DetailsCardItem cardItemTitle="Typ">{note.type}</DetailsCardItem>
      <DetailsCardItem cardItemTitle="Text" showDivider={false}>
        <RichTextReadOnly content={note.text} extensions={[StarterKit]} />
      </DetailsCardItem>
    </div>
  );
}
