import React, { useEffect, useState } from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import ApiService from "../../../shared/service/api/api.service";
import AlertService from "../../../shared/service/alert/alert.service";
import { CreateGroupDialog } from "../../../feature/eclass/CreateGroupDialog";
import { Eclass } from "../../../shared/type/Eclass";
import { EclassSubgroup } from "../../../shared/type/EclassSubgroup";
import { EclassSubSubgroup } from "../../../shared/type/EclassSubSubgroup";
import { handleError } from "../../../shared/helper/handle-error";

enum CreateType {
  MAIN_GROUP = "MAIN_GROUP",
  SUBGROUP = "SUBGROUP",
  SUB_SUBGROUP = "SUB_SUBGROUP",
  NEW_VALUE = "NEW_VALUE",
}

function EclassCreationPage() {
  const [mainGroupName, setMainGroupName] = useState("");
  const [subgroupName, setSubgroupName] = useState("");
  const [subSubgroupName, setSubSubgroupName] = useState("");
  const [eclassValue, setEclassValue] = useState("");
  const [mainGroups, setMainGroups] = useState<Eclass[]>([]);
  const [subGroups, setSubGroups] = useState<EclassSubgroup[]>([]);
  const [subSubGroups, setSubSubGroups] = useState<EclassSubSubgroup[]>([]);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<CreateType | null>(null);

  const handleMainGroupNameChange = (event: any, mainGroupN: any) => {
    setMainGroupName(mainGroupN);
    setSubgroupName("");
    setSubSubgroupName("");
  };

  const handleSubgroupNameChange = (event: any, subgroupN: any) => {
    setSubgroupName(subgroupN);
    setSubSubgroupName("");
  };

  const handleSubSubgroupNameChange = (event: any, values: any) => {
    setSubSubgroupName(values);
  };

  const handleEclassValueChange = (event: any) => {
    setEclassValue(event.target.value);
  };

  const fetchSubgroups = async () => {
    try {
      const subgroupList = await ApiService.eclass.getEclassSubgroups();
      if (subgroupList) {
        setSubGroups(subgroupList.data);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchSubgroups();
  }, []);

  const fetchSubSubgroups = async () => {
    try {
      const subSubgroupList = await ApiService.eclass.getEclassSubSubgroups();
      if (subSubgroupList) {
        setSubSubGroups(subSubgroupList.data);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchSubSubgroups();
  }, []);
  const fetchMainGroups = async () => {
    try {
      const mainGroupList = await ApiService.eclass.getEclassMainGroups();
      if (mainGroupList) {
        setMainGroups(mainGroupList.data);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchMainGroups();
  }, []);

  const handleCreateClick = (type: CreateType) => {
    setSelectedType(type);
    if (type === CreateType.NEW_VALUE) {
      createNewEclassItem(eclassValue);
    } else {
      setCreateDialogOpen(true);
    }
  };
  const createNewEclassItem = async (name: string) => {
    switch (selectedType) {
      case CreateType.MAIN_GROUP:
        try {
          await ApiService.eclass.createEclassMainGroup({ name });
          fetchMainGroups();
        } catch (error) {
          handleError(error);
        }
        break;
      case CreateType.SUBGROUP:
        try {
          await ApiService.eclass.createEclassSubgroup({ name, mainGroupName });
          fetchSubgroups();
        } catch (error) {
          handleError(error);
        }
        break;
      case CreateType.SUB_SUBGROUP:
        try {
          await ApiService.eclass.createEclassSubSubgroup({
            name,
            subgroupName,
          });
          fetchSubSubgroups();
        } catch (error) {
          handleError(error);
        }
        break;
      case CreateType.NEW_VALUE:
        try {
          await ApiService.eclass.createEclassValue({
            name,
            subSubgroupName,
          });
          AlertService.success("New value created");
          setEclassValue("");
        } catch (error) {
          handleError(error);
        }
        break;
    }
  };

  const handleConfirmCreate = async (name: string): Promise<void> => {
    await createNewEclassItem(name);
    setCreateDialogOpen(false);
    setSelectedType(null);
  };

  const handleCancelCreate = (): void => {
    setCreateDialogOpen(false);
    setSelectedType(null);
  };

  return (
    <HomeContainer>
      <Typography sx={{ flexGrow: 1 }} component="h1" variant="h4">
        Eclass Creation
      </Typography>
      <Card sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <CardContent>
          <Box>
            <Box display="flex" alignItems="center">
              <Autocomplete
                options={mainGroups.map((item) => item.name)}
                value={mainGroupName}
                onChange={handleMainGroupNameChange}
                sx={{ width: "60%", marginRight: "10%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Level 1"
                    variant="standard"
                    required
                  />
                )}
              />
              <Button
                variant="outlined"
                onClick={() => handleCreateClick(CreateType.MAIN_GROUP)}
                sx={{ width: "20%", marginRight: "10%" }}
              >
                Create Level 1
              </Button>
            </Box>
            <Box display="flex" alignItems="center">
              <Autocomplete
                options={subGroups
                  .filter((item) => {
                    if (mainGroupName && mainGroupName !== "") {
                      return (
                        item.mainGroupId ===
                        mainGroups.find((item) => item.name === mainGroupName)
                          ?.id
                      );
                    }
                    return true;
                  })
                  .map((item) => item.name)}
                value={subgroupName}
                onChange={handleSubgroupNameChange}
                sx={{ width: "60%", marginRight: "10%" }}
                disabled={!mainGroupName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Level 2"
                    variant="standard"
                    required
                    disabled={!mainGroupName}
                  />
                )}
              />
              <Button
                variant="outlined"
                onClick={() => handleCreateClick(CreateType.SUBGROUP)}
                sx={{ width: "20%", marginRight: "10%" }}
                disabled={!mainGroupName}
              >
                Create Level 2
              </Button>
            </Box>
            <Box display="flex" alignItems="center">
              <Autocomplete
                options={subSubGroups
                  .filter((item) => {
                    if (subgroupName && subgroupName !== "") {
                      return (
                        item.subgroupId ===
                        subGroups.find((item) => item.name === subgroupName)?.id
                      );
                    }
                    return true;
                  })
                  .map((item) => item.name)}
                value={subSubgroupName}
                onChange={handleSubSubgroupNameChange}
                sx={{ width: "60%", marginRight: "10%" }}
                disabled={!mainGroupName || !subgroupName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Level 3"
                    variant="standard"
                    required
                    disabled={!mainGroupName || !subgroupName}
                  />
                )}
              />{" "}
              <Button
                variant="outlined"
                onClick={() => handleCreateClick(CreateType.SUB_SUBGROUP)}
                sx={{ width: "20%", marginRight: "10%" }}
                disabled={!mainGroupName || !subgroupName}
              >
                Create Level 3
              </Button>
            </Box>
            <Box display="flex" alignItems="center">
              <TextField
                label="New Level 4"
                variant="standard"
                value={eclassValue}
                onChange={handleEclassValueChange}
                sx={{ width: "60%", marginRight: "10%" }}
                required
                disabled={!mainGroupName || !subgroupName || !subSubgroupName}
              />
              <br />
              <Button
                variant="outlined"
                onClick={() => handleCreateClick(CreateType.NEW_VALUE)}
                disabled={
                  !mainGroupName ||
                  !subgroupName ||
                  !subSubgroupName ||
                  !eclassValue
                }
                sx={{ width: "20%", marginRight: "10%" }}
              >
                Create Level 4
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <CreateGroupDialog
        isOpen={isCreateDialogOpen}
        onClose={handleCancelCreate}
        onCreate={handleConfirmCreate}
      />
    </HomeContainer>
  );
}

export default EclassCreationPage;
