import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";

export interface CreateEclassSubSubgroupPayload {
  name: string;
  subgroupName: string;
}

export const createEclassSubSubgroup = async (
  payload: CreateEclassSubSubgroupPayload
): Promise<void> => {
  try {
    await apiClient.post(`${ECLASS_URL}/sub-subgroups`, payload);
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
