import { SUPPLIER_BUSINESS_CONTACT_URL } from "../api.const";
import apiClient from "../api.client";

export const deleteSupplierBusinessContact = async (
  supplierId: string,
  businessContactId: string
): Promise<void> => {
  try {
    await apiClient.delete(
      SUPPLIER_BUSINESS_CONTACT_URL(supplierId, businessContactId)
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
