import { toast } from "react-toastify";
import { getTranslation } from "../translation/get-translation";

export const success = (message: string): void => {
  toast.success(getTranslation(message), {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
  });
};
