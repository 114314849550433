import { CardContent, Divider, Grid, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import CardRowTitleTextBox from "../text-box/CardRowTitleTextBox";
interface DetailsCardItemWithTwoColumnProps {
  firstCardItemTitle?: string;
  secondCardItemTitle?: string;
  showDivider?: boolean;
}
function DetailsCardItemWithTwoColumn({
  firstCardItemTitle,
  secondCardItemTitle,
  showDivider = true,
  children,
}: PropsWithChildren<DetailsCardItemWithTwoColumnProps>) {
  const firstChildren = React.Children.toArray(children)[0];
  const secondChildren = React.Children.toArray(children)[1];
  return (
    <>
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1.5}>
            {firstCardItemTitle && (
              <CardRowTitleTextBox
                text={firstCardItemTitle}
              ></CardRowTitleTextBox>
            )}
          </Grid>
          <Grid item xs={4.5}>
            {firstChildren && (
              <Typography variant="body2" component="div">
                {firstChildren}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1.5}>
            {secondCardItemTitle && (
              <CardRowTitleTextBox
                text={secondCardItemTitle}
              ></CardRowTitleTextBox>
            )}
          </Grid>
          <Grid item xs={4.5}>
            {secondChildren && (
              <Typography variant="body2" component="div">
                {secondChildren}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      {showDivider && <Divider variant="middle" />}
    </>
  );
}

export default DetailsCardItemWithTwoColumn;
