import { SINGLE_SUPPLIER_URL } from "../api.const";
import apiClient from "../api.client";
import { SupplierDetails } from "../../../type/SupplierDetails";

export const getSupplier = async (
  supplierId: string
): Promise<SupplierDetails> => {
  try {
    const response = await apiClient.get<SupplierDetails>(
      SINGLE_SUPPLIER_URL(supplierId)
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
