import apiClient from "../api.client";
import { TAG_URL } from "../api.const";
import { Tag } from "../../../type/Tag";

export const getTagById = async (tagId: string) => {
  try {
    const response = await apiClient.get<Tag>(`${TAG_URL}/${tagId}`);
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
