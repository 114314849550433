import { SUPPLIER_NOTE_URL } from "../api.const";
import apiClient from "../api.client";

export const deleteSupplierNote = async (
  supplierId: string,
  noteId: string
): Promise<void> => {
  try {
    await apiClient.delete(SUPPLIER_NOTE_URL(supplierId, noteId));
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
