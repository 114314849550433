export const translationList: Record<string, string> = {
  FILE_IS_REQUIRED: "File is required",
  ERROR_DELETING_LOCAL_FILE: "An error occurred while deleting a file",
  SUPPLIER_NOT_FOUND: "Supplier not found",
  SUPPLIER_LOGO_NOT_FOUND: "Supplier logo not found",
  SUPPLIER_ADDRESS_NOT_FOUND: "Supplier address not found",
  SUPPLIER_BUSINESS_CONTACT_NOT_FOUND: "Supplier business contact not found",
  RATE_CARD_ALREADY_EXIST:
    "You cannot create rate card, if supplier, location, location tag and service are the same",
  USER_NOT_FOUND: "User not found",
  AUTH_TOKEN_IS_INVALID: "Authorization token is expired",
  AUTH_TOKEN_EXPIRED: "Authorization token is expired",
  EMAIL_TAKEN:
    "Email address taken, log in or choose another email address. If you have forgotten your password then use the password recovery function",
  ECLASS_NAME_IS_TAKEN: "Name is taken, try another one",
  END_DATE_CANNOT_BE_EARLIER_THAN_START_DATE:
    "DueDate cannot be earlier date than startDate",
  MAILBOX_UNAVAILABLE: "Mailbox unavailable. Check email domain name.",
  CSV_READER_ERROR: "Something went wrong, CSV reader error",
  CSV_CANNOT_BE_EMPTY: "Csv must contains some elements",
  SKILL_LIST_IS_INVALID: "Skill list is invalid",
  SKILL_LEVEL_NOT_FOUND: "Skill level not found",
  RATE_LIST_IS_INVALID: "Rate list is not valid",
  ECLASS_VALUE_NOT_FOUND: "Eclass value not found",
  MAIN_GROUP_NOT_FOUND: "Main group not found",
  SUBGROUP_NOT_FOUND: "Subgroup not found",
  SUB_SUBGROUP_NOT_FOUND: "Sub-subgroup not found",
};
