import React, { useEffect, useState } from "react";
import HomeContainer from "../../components/container/HomeContainer";
import SupplierTable from "../../feature/supplier/SupplierTable";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ApiService from "../../shared/service/api/api.service";
import { Supplier } from "../../shared/type/Supplier";
import { SUPPLIER_CREATE_PATH } from "../../shared/const/path.const";
import { handleError } from "../../shared/helper/handle-error";
import { SupplierStatus } from "../../shared/type/SupplierStatus";

function SupplierPage() {
  const [supplierList, setSupplierList] = useState<Supplier[]>([]);
  const [supplierStatuses, setSupplierStatuses] = useState<SupplierStatus[]>(
    []
  );
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const [supplierList, supplierStatuses] = await Promise.all([
        await ApiService.supplier.getSupplierList(),
        await ApiService.supplierStatus.getSupplierStatuses(),
      ]);
      if (supplierList) {
        setSupplierList(supplierList.data);
      }
      if (supplierStatuses) {
        setSupplierStatuses(supplierStatuses.data);
      }
    } catch (error) {
      handleError(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HomeContainer functionHeaderType="search-bar">
      <Box display="flex" alignItems="center" marginBottom={3}>
        <Typography sx={{ flexGrow: 1 }} component="h1" variant="h4">
          Lieferant
        </Typography>
        <Button
          variant="outlined"
          onClick={() => navigate(SUPPLIER_CREATE_PATH)}
        >
          Erstelle Lieferant
        </Button>
      </Box>
      <SupplierTable
        supplierList={supplierList}
        supplierStatuses={supplierStatuses}
      ></SupplierTable>
    </HomeContainer>
  );
}

export default SupplierPage;
