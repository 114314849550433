import { SUPPLIER_ADDRESS_URL } from "../api.const";
import apiClient from "../api.client";
import { Address } from "../../../type/Address";

export const createSupplierAddress = async (
  supplierId: string,
  createSupplierAddressPayload: Omit<Address, "id">
): Promise<void> => {
  try {
    await apiClient.post(
      SUPPLIER_ADDRESS_URL(supplierId),
      createSupplierAddressPayload
    );
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
