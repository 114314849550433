import React from "react";
import { Box, Container } from "@mui/material";
import Copyright from "./Copyright";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
      }}
    >
      <Container maxWidth="xl">
        <Copyright align="right" />
      </Container>
    </Box>
  );
}

export default Footer;
