import { useEffect, useState } from "react";
import HomeContainer from "../../../components/container/HomeContainer";
import { Typography, Grid } from "@mui/material";
import { More, RateReview, Settings, Tag, Update } from "@mui/icons-material";
import {
  ADMIN_ECLASS_PATH,
  ADMIN_SUPPLIER_STATUS_PATH,
  ADMIN_TAG_PATH,
  ADMIN_USER_PATH,
  singleSupplierPath,
} from "../../../shared/const/path.const";
import { handleError } from "../../../shared/helper/handle-error";
import ApiService from "../../../shared/service/api/api.service";
import Tile from "../../../components/tile/Tile";

function AdminPage() {
  const [benchmarkSupplier, setBenchmarkSupplier] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const benchmark =
          await ApiService.supplier.benchmark.getBenchmarkSupplier();
        if (benchmark) {
          setBenchmarkSupplier(benchmark.supplierId);
        }
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [benchmarkSupplier]);

  const tiles = [
    {
      icon: Settings,
      title: "User Administration",
      action: "Administration User",
      redirectTo: ADMIN_USER_PATH,
    },
    {
      icon: RateReview,
      title: "Benchmark",
      action: "Administration Benchmark",
      redirectTo: benchmarkSupplier
        ? singleSupplierPath(benchmarkSupplier)
        : "#",
    },
    {
      icon: Tag,
      title: "Tags",
      action: "Administration Tags",
      redirectTo: ADMIN_TAG_PATH,
    },
    {
      icon: Update,
      title: "Eclass",
      action: "Administration Eclass",
      redirectTo: ADMIN_ECLASS_PATH,
    },
    {
      icon: More,
      title: "Supplier status",
      action: "Administration Supplier Status",
      redirectTo: ADMIN_SUPPLIER_STATUS_PATH,
    },
  ];

  return (
    <HomeContainer>
      <Typography marginBottom={3} variant="h4">
        Administration Panel
      </Typography>
      <Grid container spacing={2}>
        {tiles.map((tile, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Tile {...tile} />
          </Grid>
        ))}
      </Grid>
    </HomeContainer>
  );
}

export default AdminPage;
