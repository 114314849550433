import React, { ChangeEvent, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";

type UpdateFieldDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (value: string) => void;
};
export function CreateGroupDialog({
  isOpen,
  onClose,
  onCreate,
}: UpdateFieldDialogProps) {
  const [name, setName] = useState("");

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleConfirmCreate = () => {
    if (name && name !== "") {
      onCreate(name);
    }
    onClose();
    setName("");
  };

  const handleCancelCreate = () => {
    onClose();
    setName("");
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelCreate}>
      <DialogTitle>Gruppe erstellen</DialogTitle>
      <Box>
        <DialogContent>
          <TextField
            label="Group name"
            value={name}
            onChange={handleNameChange}
            fullWidth
          />
        </DialogContent>
      </Box>
      <DialogActions>
        <Button onClick={handleCancelCreate} size="large">
          Abbrechen
        </Button>
        <Button onClick={handleConfirmCreate} size="large">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
