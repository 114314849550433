import { Card, CardContent, CardHeader, Typography, Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Variant } from "@mui/material/styles/createTypography";

interface DetailsCardProps {
  cardTitle: string;
  cardIcon?: React.ReactNode;
  cardTitleUrl?: string;
  titleSize?: Variant;
}
function DetailsCard({
  cardTitle,
  cardIcon,
  cardTitleUrl,
  children,
  titleSize,
}: PropsWithChildren<DetailsCardProps>) {
  const titleValue = cardTitleUrl ? (
    <Link className="link-reset-styles" to={cardTitleUrl}>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5em" }}>
        {cardIcon}
        {cardTitle}
      </Box>
    </Link>
  ) : (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5em" }}>
      {cardIcon}
      {cardTitle}
    </Box>
  );

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant={titleSize ?? "h5"} component="div">
            {titleValue}
          </Typography>
        }
      ></CardHeader>

      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default DetailsCard;
