import apiClient from "../api.client";
import { ECLASS_URL } from "../api.const";
import { ListResponse } from "../../../type/ListResponse";
import { Eclass } from "../../../type/Eclass";

export const getEclassMainGroups = async (): Promise<ListResponse<Eclass>> => {
  try {
    const response = await apiClient.get<ListResponse<Eclass>>(
      `${ECLASS_URL}/main-groups`
    );
    return response?.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
