import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { EmailInput } from "../inputs/EmailInput";
import { TextInput } from "../inputs/TextInput";
import { CreateUserPayload } from "../../shared/service/api/user/create-user";

type CreateFieldDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (payload: CreateUserPayload) => void;
};
export function CreateUserDialog({
  isOpen,
  onClose,
  onCreate,
}: CreateFieldDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateUserPayload>();

  const onSubmit = async (data: CreateUserPayload) => {
    await onCreate(data);
    reset();
  };

  const handleCancelCreate = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancelCreate}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Benutzer bewerten</DialogTitle>
        <DialogContent>
          <EmailInput control={control} errors={errors} />
          <TextInput
            control={control}
            errors={errors}
            fieldName="firstName"
            label="Vorname"
            required={true}
          />
          <TextInput
            control={control}
            errors={errors}
            fieldName="lastName"
            label="Nachname"
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCreate} size="large">
            Abbrechen
          </Button>
          <Button type="submit" size="large">
            Bewerten
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
