import { FormControlLabel, Switch } from "@mui/material";
import React, { useContext } from "react";
import BenchmarkDataVisibleContext from "./BenchmarkDataVisibleContext";
import { BenchmarkDataVisibleProvider } from "./BenchmarkDataVisibleProvider";

function BenchmarkDataVisibleSwitch() {
  const { benchmarkDataVisible, setBenchmarkDataVisible } = useContext(
    BenchmarkDataVisibleContext
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBenchmarkDataVisible(event.target.checked);
  };

  return (
    <BenchmarkDataVisibleProvider>
      <FormControlLabel
        control={
          <Switch checked={benchmarkDataVisible} onChange={handleChange} />
        }
        label="Benchmark Data"
      />
    </BenchmarkDataVisibleProvider>
  );
}

export default BenchmarkDataVisibleSwitch;
